import { IButtonOptions } from "devextreme-react/button";
import DeleteIcon from "../icons/DeleteIcon";
import PromineoButtonWithIcon from "./PromineoButtonWithIcon";
import { ControlVariant } from "shared/enums/ControlVariantEnum";

interface Props {
  text?: string;
}

export default function DeleteButton(props: Props & IButtonOptions) {
  return (
    <PromineoButtonWithIcon
      {...props}
      variant={ControlVariant.secondary}
      text={props.text || "Delete"}
      Icon={<DeleteIcon />}
    />
  );
}

import { forwardRef, LegacyRef } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import PromineoModalActionFooter, {
  EditorActionButtonProp,
} from "./PromineoModalActionFooter";

const PromineoModalFooter = forwardRef(
  (
    props: {
      editorActionButtonProps: EditorActionButtonProp;
    },
    ref: LegacyRef<HTMLDivElement> | null
  ) => {
    const { editorActionButtonProps } = props;
    return (
      <div
        className={`${
          editorActionButtonProps?.mode !== PromineoModalMode.ConfirmationDialog
            ? `border-t-3 border-gray-100`
            : ``
        }`}
        ref={ref}
      >
        <div className="py-4 px-10">
          <PromineoModalActionFooter
            {...editorActionButtonProps}
            mode={editorActionButtonProps?.mode}
          />
        </div>
      </div>
    );
  }
);

export default PromineoModalFooter;

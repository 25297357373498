import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import { IlapTextBox, IlapSelectBox } from "ilap.common.webcomponents.test";
import LabelWithContent from "components/common/LabelWithContent";
import "./styles/ReportScheduleView.css";

interface Props {
  widthClass?: string;
  metadataFields: ReportScheduleMetadataFieldValue[];
  handleDropdownSelectionChange: (selectedItem: MetadataFieldValue) => void;
  handleMetadataFieldValueChange: (metadataFieldId: number, value: string, id?: number) => void;
  columns?: "one" | "two";
}

export default function ReportScheduleFieldEdit(props: Props) {
  const { widthClass, metadataFields, handleDropdownSelectionChange, handleMetadataFieldValueChange } = props;

  putRequiredFieldsFirst(metadataFields);

  const commonClass = `${widthClass ?? "w-[320px]"}`;
  const errorClass = `${commonClass} border-red-4`;

  const columnsCss = props.columns === "two" ? "grid-cols-2" : "grid-cols-1";

  return (
    <div className={`grid ${columnsCss} gap-2`}>
      {metadataFields?.map((metadata, index) => (
        <div key={index}>
          {metadata.isReadOnly ?
            <LabelWithContent
              label={metadata.name}
              rootClass="w-fit"
              content={
                <div className="ilap-rs-view-value-text truncate" title={metadata.value}>{metadata.value}</div>
              }
            />
            :
            <LabelWithContent
              label={metadata.name}
              rootClass="w-fit"
              isOptional={!metadata.isRequired}
              content={metadata.displayDropdown ? (

                <IlapSelectBox
                  placeholder="Select"
                  displayExpr={"stringValue"}
                  valueExpr={"id"}
                  value={metadata.id}
                  items={metadata.dropdownSource}
                  showValidationMessage={metadata.isMissingRequired}
                  className={metadata.isMissingRequired ? errorClass : commonClass}
                  onSelectionChanged={({ selectedItem }: { selectedItem: MetadataFieldValue }) => {
                    handleDropdownSelectionChange(selectedItem);
                  }}
                />
              ) : (
                <IlapTextBox
                  placeholder="Type..."
                  value={metadata.value}
                  showValidationMessage={metadata.isMissingRequired}
                  className={metadata.isMissingRequired ? errorClass : commonClass}
                  onChange={({ event }: any) => {
                    handleMetadataFieldValueChange(metadata.metadataFieldId, event?.currentTarget?.value, 0);
                  }}
                />
              )}
            />
          }
        </div>
      ))}
    </div>
  )
}


function putRequiredFieldsFirst(metadataFields: ReportScheduleMetadataFieldValue[]) {

  metadataFields.sort((a, b) => {
    if (a.isRequired && !b.isRequired) {
      return -1;
    } else if (!a.isRequired && b.isRequired) {
      return 1;
    } else {
      return 0;
    }
  });

}
import ValidatedContent from "components/common/ValidatedContent";
import FieldGeneralInfo from "features/metadata/common/interface/FieldGeneralInfo";
import { LabelWithContent, IlapTextBox, IlapSelectBox, IlapMultilineTextEdit } from "ilap.common.webcomponents.test";
import { useMemo } from "react";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { PropertyState } from "shared/enums/PropertyStateEnum";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { IlapUtility } from "shared/utilities/IlapUtility";

interface Props {
  data?: FieldGeneralInfo,
  isValidationEnabled?: boolean,
  onValueChange: (fieldName: string, value: string | number) => void;
}

interface PlanningObject {
  type: PlanningObjectTypes,
  name: string
}

export default function FieldGeneralInfoForm(props: Props) {
  const planningObjects: PlanningObject[] = [
    {
      type: PlanningObjectTypes.Schedule,
      name: PlanningObjectTypes[PlanningObjectTypes.Schedule]
    },
    {
      type: PlanningObjectTypes.Activity,
      name: PlanningObjectTypes[PlanningObjectTypes.Activity]
    }
  ];

  const dataTypes = FieldUtility.getDataTypes();

  const isTitleMissing = useMemo(() => {
    return props.isValidationEnabled && !props.data?.title.trimStart();
  }, [props.isValidationEnabled, props.data?.title]);

  const isUsedByMissing = useMemo(() => {
    return props.isValidationEnabled && props.data?.usedBy === PropertyState.Unset;
  }, [props.isValidationEnabled, props.data?.usedBy]);

  const isIlapIdMissing = useMemo(() => {
    return props.isValidationEnabled && !props.data?.ilapId.trim();
  }, [props.isValidationEnabled, props.data?.ilapId]);

  const isIlapIdInvalid = useMemo(() => {
    const ilapId = props.data?.ilapId.trim();
    return (props.isValidationEnabled && (ilapId && !IlapUtility.validateIlapId(ilapId))) === true;
  }, [props.isValidationEnabled, props.data?.ilapId]);

  return (
    <div className="h-full flex flex-col justify-between overflow-auto">
      <div className="font-inter text-14px text-gray-9">
        <div className="leading-[150%]">Fill out the field information and move on to the next step.</div>
        <hr className="mt-4 mb-4 border-light-gray" />
        <div className="grid grid-cols-1 space-y-2 w-[328px]">
          <LabelWithContent
            label="Title"
            content={
              <IlapTextBox
                placeholder="Type..."
                value={props.data?.title}
                showValidationMessage={isTitleMissing}
                onChange={({ event }: any) => props.onValueChange("title", event.currentTarget.value)
                }
              />
            }
          />
          <LabelWithContent
            label="Used by"
            content={
              <IlapSelectBox
                placeholder="Select"
                items={planningObjects}
                value={props.data?.usedBy}
                displayExpr={"name"}
                valueExpr={"type"}
                showValidationMessage={isUsedByMissing}
                onValueChange={(id: number) => props.onValueChange("usedBy", id)}
              />
            }
          />
          <LabelWithContent
            label="ILAP ID"
            content={
              <ValidatedContent validationMessage="Valid ILAP Id is required" showValidationMessage={isIlapIdInvalid}>
                <IlapTextBox
                  placeholder="Type..."
                  value={props.data?.ilapId}
                  validationStatus={(isIlapIdMissing || isIlapIdInvalid) ? "invalid" : "valid"}
                  showValidationMessage={isIlapIdMissing}
                  onChange={({ event }: any) => props.onValueChange("ilapId", event.currentTarget.value)
                  }
                />
              </ValidatedContent>
            }
          />
          <LabelWithContent
            label="Data type"
            content={
              <IlapSelectBox
                placeholder="Select"
                items={dataTypes}
                value={props.data?.type}
                displayExpr={"name"}
                valueExpr={"type"}
                onValueChange={(id: number) => props.onValueChange("type", id)}
              />
            }
          />
          <LabelWithContent
            label="Description"
            isOptional={true}
            content={
              <IlapMultilineTextEdit
                placeholder="Type..."
                value={props.data?.description ?? undefined}
                height={"22vh"}
                minHeight={"100px"}
                maxHeight={"296px"}
                onChange={({ event }: any) => props.onValueChange("description", event.currentTarget.value)} />
            }
          />
        </div>
      </div>
      <hr className="mt-4 mb-4 border-light-gray" />
    </div>
  )
}
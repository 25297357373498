import MetadataField from "interfaces/response/MetadataField";
import LabelWithContent from "components/common/LabelWithContent";
import ScrollView from "devextreme-react/scroll-view";
import "./styles/FieldViewDetails.css";
import CrossIconButton from "components/common/buttons/icon-buttons/CrossIconButton";
import { getFormattedDateForColumn } from "shared/utilities/DateTimeUtility";
import { IlapPopup } from "ilap.common.webcomponents.test";
import { FieldUtility } from "shared/utilities/FieldUtility";

interface Props {
  metadataField: MetadataField;
  onClose: () => void;
}

export default function FieldViewDetails(props: Props) {
  const onClose = props.onClose;
  const metadataField = props.metadataField;
  const itemRootClass = "h-[70px]";

    return (
    <IlapPopup
      width={"728px"}
      height={"528px"}
      className="bg-black-opacity-40"
    >
      <div className="ilap-field-view-header mb-4 flex justify-between">
        <div className="ilap-field-view-title truncate" title={metadataField.name}>{metadataField.name}</div>
        <CrossIconButton onClick={onClose} />
      </div>
      <div className="grid grid-cols-5 gap-8" >
        <div className="col-span-3">
          <LabelWithContent
            rootClass={itemRootClass}
            label="Field ID"
            content={
              <div className="ilap-field-view-value-text">{metadataField.id}</div>
            }
          />
          <LabelWithContent
            label="Used by"
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.usedBy}</div>
            }
          />
          <LabelWithContent
            label="ILAP ID"
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.ilapId}</div>
            }
          />
          <LabelWithContent
            label="Description"
            rootClass={itemRootClass}
            content={
              <ScrollView showScrollbar="always">
                <div className="ilap-field-view-value-text-desc">{metadataField.description}</div>
              </ScrollView>
            }
          />
        </div>
        <div className="col-span-2">
        <LabelWithContent
            label="Data type"
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{FieldUtility.getDataTypeName(metadataField.type)}</div>
            }
          />
          <LabelWithContent
            label={"# Times in use"}
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.timesInUse}</div>
            }
          />
          <LabelWithContent
            label={"# Values"}
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.numberOfValues}</div>
            }
          />
          <LabelWithContent
            label={"Date created"}
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.created ? getFormattedDateForColumn(metadataField.created) : "-"}</div>
            }
          />
          <LabelWithContent
            label={"Date updated"}
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.lastModified ? getFormattedDateForColumn(metadataField.lastModified) : "-"}</div>
            }
          />
          <LabelWithContent
            label={"Updated by"}
            rootClass={itemRootClass}
            content={
              <div className="ilap-field-view-value-text">{metadataField.lastModifierName}</div>
            }
          />
        </div>
      </div>
    </IlapPopup>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect, useState } from "react";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { ValidationRequirement } from "shared/enums/ValidationRequirementEnum";
import MetadataField from "interfaces/response/MetadataField";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadSingleReportScheduleTypeAsync } from "store/action/ReportScheduleTypeActions";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import { IlapModal } from "ilap.common.webcomponents.test";
import ScrollView from "devextreme-react/scroll-view";
import "./styles/ReportScheduleView.css";
import { loadSingleReportScheduleAsync } from "store/action/ReportScheduleActions";
import CrossIconButton from "components/common/buttons/icon-buttons/CrossIconButton";
import ReportScheduleItemView from "./ReportScheduleItemView";
import { createEmptyReportSchedule } from "./ReportScheduleUtility";

interface Props {
  reportScheduleId: string;
  onClose: () => void;
}

const emptyReportSchedule: ReportScheduleResponse = createEmptyReportSchedule();

export default function ReportScheduleViewModal(props: Props) {

  const dispatch = useDispatch<AppDispatch>();
  const reportScheduleId = props.reportScheduleId;
  const onClose = props.onClose;

  const [reportScheduleMetadataFieldValueSource, setReportScheduleMetadataFieldValueSource] = useState<ReportScheduleMetadataFieldValue[]>([]);
  const [metadataFields, setMetadataFields] = useState<MetadataField[]>([]);
  const [selectedReportSchedule, setSelectedReportSchedule] = useState<ReportScheduleResponse>(emptyReportSchedule);

  const selectedScheduleWithSchedules = useSelector(
    (state: RootState) => {
      return state.reportScheduleData.selectedReportScheduleWithSchedules;
    }
  );

  useEffect(() => {    
    dispatch(
      loadSingleReportScheduleAsync({ reportScheduleId: Number(reportScheduleId) })
    )
      .then(unwrapResult)
      .then((reportSchedule: ReportScheduleResponse) => {
        setSelectedReportSchedule(reportSchedule);        
      });
  }, []);

  useEffect(() => {
    // Avoid loading metadata for un-initialized reportScheduleTypeId
    if (selectedReportSchedule.reportScheduleTypeId === emptyReportSchedule.reportScheduleTypeId) {
      return;
    }
    displayLoadingPanel();
    dispatch(
      loadSingleReportScheduleTypeAsync({reportScheduleTypeId: selectedReportSchedule.reportScheduleTypeId})
    )
      .then(unwrapResult)
      .then((reportScheduleType: ReportScheduleType) => {
        setMetadataFields(reportScheduleType?.metadataFields);
      })
      .finally(hideLoadingPanel);
  }, [dispatch, selectedReportSchedule.reportScheduleTypeId]);

  useEffect(() => {
    const reportScheduleMetadataFieldValue: ReportScheduleMetadataFieldValue[] = [];

    for (let metadataFieldIndex = 0; metadataFieldIndex < metadataFields?.length; metadataFieldIndex++) {
      let metadataField = metadataFields[metadataFieldIndex];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
        reportScheduleMetadataFieldValue.push({
          metadataFieldId: metadataField.id,
          name: metadataField.name,
          displayDropdown: metadataField.validationRequirement === ValidationRequirement.ValueInList,
          dropdownSource: metadataField.metadataFieldValues,
          isRequired: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.NonBlankValue
        });
      }
    }
    selectedReportSchedule.metadataFieldValues.forEach((fieldValues) => {
      const metadataFieldValueIndex =
        reportScheduleMetadataFieldValue.findIndex(
          (metadata: ReportScheduleMetadataFieldValue) =>
            metadata.metadataFieldId === fieldValues.metadataFieldId
        );

      if (metadataFieldValueIndex > -1) {
        const reportScheduleMetadata = reportScheduleMetadataFieldValue[metadataFieldValueIndex];

        reportScheduleMetadata.value = fieldValues.stringValue;
        reportScheduleMetadata.id = fieldValues.id;
      }
    });
    setReportScheduleMetadataFieldValueSource(reportScheduleMetadataFieldValue);
  }, [selectedReportSchedule, metadataFields, selectedReportSchedule.metadataFieldValues]);  
  const descLength = (selectedScheduleWithSchedules?.description?.length || 0);
  
  return (
    <IlapModal
      displayDefaultActions={true}
      isVisible={true}
      width="728px"
      height="586px"
      className="bg-black-opacity-40"
    >
      <div className="mx-5">
        <div className="ilap-rs-view-header mb-4 flex justify-between">
          <div className="ilap-rs-view-title truncate" title={selectedScheduleWithSchedules?.title}>{selectedScheduleWithSchedules?.title}</div>
          <CrossIconButton onClick={onClose} />
        </div>
        <div className="ilap-rs-view-content grid grid-cols-2 gap-x-6" >
          <div className="grid-cols-1"  >            
            <ReportScheduleItemView label="Report schedule ID" value={String(selectedScheduleWithSchedules?.id)} descLength={descLength}/>
            <ReportScheduleItemView label="Internal ID" value={selectedScheduleWithSchedules?.internalId} descLength={descLength}/>
            <ReportScheduleItemView label="Schedule level" value={selectedScheduleWithSchedules?.reportScheduleType?.planningLevelText} descLength={descLength}/>
            <ReportScheduleItemView label="Schedule type" value={selectedScheduleWithSchedules?.reportScheduleType?.title} descLength={descLength}/>
            <ReportScheduleItemView label="Points in time type" value={selectedScheduleWithSchedules?.pointsInTimeTypeText} descLength={descLength}/>
            <ReportScheduleItemView label="Description" value={selectedScheduleWithSchedules?.description} useScrollView={true} descLength={descLength}/>
          </div>
          
          <ScrollView showScrollbar="always">
            <div className="grid-cols-1">
              {reportScheduleMetadataFieldValueSource?.map((metadata, index) => (
                <div key={index}>                  
                  <ReportScheduleItemView label={metadata.name} value={metadata.value} descLength={descLength}/>
                </div>
              ))}
            </div>
          </ScrollView>
        </div>
      </div>
    </IlapModal>
  );
}

import { toast, ToastOptions } from "react-toastify";

const toastOption: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  style: {
    whiteSpace: "pre-line",
  },
};

export const toastSuccess = (message: string) => {
  toast.success(message, toastOption);
};

export const toastError = (message: string) => {
  toast.error(message, toastOption);
};

export const toastWarning = (message: string) => {
  toast.warn(message, toastOption);
};

export const toastIlapInfo = (message: string, moveRight = "44px", moveUp = "68px") => {

  const infoToastOption: ToastOptions = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,    
    closeButton: false,
    icon: false,    
    rtl: false,    
    progressStyle:{
      background:"#D7D7D7",
      height: "1px",
      margin: "16px",      
      width: "232px" ,
      transform: "scaleX(1)",
      transformOrigin: "right"     
    },
    style: {
      whiteSpace: "normal",
      color: "white",
      background: "#5A5A5A",
      marginLeft: moveRight, 
      marginBottom: moveUp, 
      paddingBottom: "18px",     
      borderRadius: "8px",
      width: "264px",
      // height: "66px",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "18px"
    }
  };

  toast.info(message, infoToastOption);
};

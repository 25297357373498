import moment from "moment";

export const getFormattedDateForColumn = (date: string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const getDefaultLocaleFormattedDate = (date: string) => {
  return new Date(date).toLocaleDateString();
}

export const getFormattedDateTimeForColumn = (date: string) => {
  return moment(date).format("h:m MM/DD/YYYY");
};
import { ReactNode, useEffect, useRef, useState } from "react";
import { IlapSelectBox } from "ilap.common.webcomponents.test";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { TextUtility } from "shared/utilities/TextUtility";

interface Props {
  onReferenceValueChange: (newValue: number) => void;
  value?: number;
  title?: string;
  revisionTypes: DropdownOption[];
  allowedRevisionTypes: number[];
}

interface DropdownOption {
  name: string;
  value: number | null;
}

export default function ReportScheduleGridReferenceCellTemplate(props: Props) {
  const confirmationDialogContent = useRef<ReactNode>();
  const selectedValue = useRef<number | undefined>(props.value);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (props.revisionTypes && props.allowedRevisionTypes) {
      let options: DropdownOption[] = [
        {
          name: "-",
          value: null,
        },
      ];
      (props.revisionTypes || []).forEach((opt) => {
        if (
          opt.value !== null &&
          props.allowedRevisionTypes.includes(opt.value) &&
          opt.value > 0
        ) {
          options.push({
            name: opt.name,
            value: opt.value,
          });
        }
      });
      setDropdownOptions(options);
    }
  }, [props.revisionTypes, props.allowedRevisionTypes]);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);

  const handleOnChangeReference = (data: any) => {
    setIsConfirmationDialogVisible(true);
    selectedValue.current = data.value;
    if (dropdownOptions) {
      const previousValueOption = dropdownOptions.find(
        (opt) => opt.value === data.previousValue
      );
      const currentValueOption = dropdownOptions.find(
        (opt) => opt.value === data.value
      );
      if (previousValueOption && previousValueOption.value != null) {
        confirmationDialogContent.current = <div>You have set <b>{TextUtility.truncate(props.title ?? "", 35)}</b> as the <b>{currentValueOption?.name}</b>. This action will remove the reference from the currently set {previousValueOption.name}.</div>;
      } else if (currentValueOption) {
        confirmationDialogContent.current = <div>You have set <b>{TextUtility.truncate(props.title ?? "", 35)}</b> as the {currentValueOption?.name}.</div>;
      }
    } else {
      confirmationDialogContent.current =
        "The current change is going to be overwritten";
    }
  };

  const handleConfirmReferenceChange = () => {
    setIsConfirmationDialogVisible(false);
    if (selectedValue.current !== undefined) {
      props.onReferenceValueChange(selectedValue.current);
    }
  };

  return (
    <div>
      <IlapSelectBox
        key={selectedValue.current}
        displayExpr={"name"}
        valueExpr="value"
        className="w-50 h-8"
        items={dropdownOptions}
        defaultValue={props.value ?? null}
        onValueChanged={handleOnChangeReference}
      />
      {isConfirmationDialogVisible && (        
        <IlapConfirmationDialog
          height="181px"
          width="456px"          
          onConfirm={handleConfirmReferenceChange}
          onCancel={() => {
            setIsConfirmationDialogVisible(false);
            selectedValue.current = undefined;
          }}
          content="Reference change"
          subContent={confirmationDialogContent.current}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm change"
        />
      )}
    </div>
  );
}

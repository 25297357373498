// svg source: static/images/arrow_forward_large.svg;
// svg source: static/images/arrow_forward_small.svg;

export default function ForwardArrowIcon(props: { size?: "small" | "large" }) {
  return props.size === "large" ? (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02559 19.6501L0.600586 18.2251L8.82559 10.0001L0.600586 1.77513L2.02559 0.350128L11.6756 10.0001L2.02559 19.6501Z"
        fill="#1C1B1F"
      />
    </svg>
  ) : (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.678515 9.42274L0.0253906 8.76961L3.79518 4.99982L0.0253906 1.23003L0.678515 0.576904L5.10143 4.99982L0.678515 9.42274Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}

import { Button } from "devextreme-react";
import "./styles/PlusIconButton.css";

interface Props {
  onClick: () => void;
}

export default function PlusIconButton(props: Props){
  return (
    <Button icon="add" className="ilap-plus-icon border-none" onClick={props.onClick}/>
  )
}
import { IButtonOptions } from "devextreme-react/button";
import DownloadIcon from "../icons/DownloadIcon";
import { IlapButtonType, IlapButtonWithIcon } from "ilap.common.webcomponents.test";

interface Props {
  text?: string;
}

export default function DownloadButton(props: Props & IButtonOptions) {
  return (
    <IlapButtonWithIcon
      {...props}
      text={props.text || "Download"}
      Icon={<DownloadIcon />}
      variant={IlapButtonType.Secondary}
    />
  );
}
import { IButtonOptions } from "devextreme-react/button";
import { IlapButtonWithIcon } from "ilap.common.webcomponents.test";

export default function AddToListButton(props: IButtonOptions) {
  const svg = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4896_15628)">
      <path d="M10.9707 8.30334L6.9707 4.30334L6.0307 5.24334L9.08404 8.30334L6.0307 11.3633L6.9707 12.3033L10.9707 8.30334Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_4896_15628">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>;


  return <IlapButtonWithIcon text="Add" RightIcon={svg} {...props} />
}
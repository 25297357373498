import { v4 as uuidv4 } from "uuid";

export const getNewId = () => {
  return uuidv4();
};

export const isNullOrEmpty = (value : string | undefined) => {  
  return (value?.length ?? 0) === 0;  
}

export const isNullOrZero = (value : number | undefined) => {  
  return (value ?? 0) === 0;  
}


export const isNullOrFalse = (value : boolean | undefined) => {  
  return (value ?? false) === false;  
}
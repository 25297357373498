import { useState } from "react";
import AccountPopover from "./AccountPopover";
import AvatarIcon from "components/common/icons/AvatarIcon";
import AvatarBoxIcon from "components/common/icons/AvatarBoxIcon";

export default function Account() {
  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false);

  const handleAccountPopoverHiding = () => {
    setIsAccountPopoverVisible(false);
  };

  const handleAccountMenuClick = () => {
    setIsAccountPopoverVisible(!isAccountPopoverVisible);
  };

  return (
    <>
      <div className="flex justify-center w-full md:w-auto select-none">
        <div className="flex gap-4">
          {/* <AvatarBoxIcon /> */}
          <div>
            <div
              id="account-menu"
              className={`cursor-pointer flex justify-center items-center ${
                isAccountPopoverVisible
                  ? "rounded-full"
                  : ""
              }`}
              onClick={handleAccountMenuClick}
            >
              <AvatarIcon color="white" />
            </div>
          </div>
        </div>
        <AccountPopover
          visible={isAccountPopoverVisible}
          onHiding={handleAccountPopoverHiding}
          target="#account-menu"
        />
      </div>
    </>
  );
}
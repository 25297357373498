import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { reportScheduleSlice } from "store/slice/ReportScheduleSlice";
import { reportScheduleTypeSlice } from "store/slice/ReportScheduleTypeSlice";
import { dropdownValuesSlice } from "./slice/DropdownValueSlice";
import { metadataSlice } from "store/slice/MetadataSlice";
import { metadataFieldVaueUISlice } from "store/slice/MetaDataFieldValueUISlice";
import { loadingPanelSlice } from "store/slice/LoadingPanelSlice";

export const store = configureStore({
  reducer: {
    reportScheduleData: reportScheduleSlice.reducer,
    reportScheduleTypeData: reportScheduleTypeSlice.reducer,
    dropdownData: dropdownValuesSlice.reducer,
    metadataFieldData: metadataSlice.reducer,
    metadataFieldValueUIData: metadataFieldVaueUISlice.reducer,
    loadingPanelData: loadingPanelSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type Store = typeof store;

import React from "react";
import StepperProgressLabel from "./StepperProgressLabel";
import StepperProgressItem from "./StepperProgressItem";

interface Props {
  items: StepperProgressItem[],
  current: number;
  dividerWidth?: "small" | "normal";
}

export default function StepperProgressBar(props: Props) {
  const { current, items: labels } = props;

  const dividerWidth = props.dividerWidth === "small" ? "w-6" : "w-14";

  return (
    <div className="flex items-center gap-2">
      {labels.map((label, index) => (
        <React.Fragment key={index}>
          {index > 0 && <hr className={`${dividerWidth} border-lighter-gray`} />}
          {<StepperProgressLabel text={label.text} icon={label.icon} isActive={index + 1 == current} isComplete={index + 1 < current} />}
        </React.Fragment>
      ))}
    </div>
  );
}

import "./styles/IlapToggleButton.css";

export enum IlapToggleButtonState {
  Left = 1,
  Right = 2
}

interface Props {
  leftButtonText: string,
  rightButtonText: string,
  state: IlapToggleButtonState,
  onClickLeft: () => void;
  onClickRight: () => void;
}

export default function IlapToggleButton(props: Props){
  const leftButtonCss = `button left ${props.state === IlapToggleButtonState.Left ? "selected" : ""}`
  const rightButtonCss = `button right ${props.state === IlapToggleButtonState.Right ? "selected" : ""}`;

  return (
    <div className="ilap-toggle-button">
      <div className={leftButtonCss} onClick={props.onClickLeft}>{props.leftButtonText}</div>
      <div className={rightButtonCss} onClick={props.onClickRight}>{props.rightButtonText}</div>
    </div>
  )
}
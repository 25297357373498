import ModifyButton from "components/common/buttons/ModifyButton";
import ScheduleResponse from "interfaces/response/ScheduleResponse";

interface Props {
  schedule: ScheduleResponse;
  onModifyClick: () => void;
}

export default function ScheduleModalHeader(props: Props) {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="font-inter border-0 font-bold text-20px leading-20px text-gray-900 py-1 h-10 w-269px">
            {props.schedule.description}
          </div>
        </div>

        <div className="border-0 inline-flex">
          <ModifyButton onClick={props.onModifyClick} text="Modify" />
        </div>
      </div>
    </>
  );
}

import DataGrid, { Column, Selection,Editing } from "devextreme-react/data-grid";
import { IlapTextBox, IlapViewGrid } from "ilap.common.webcomponents.test";
import { Ref, forwardRef, useCallback } from "react";
import "./styles/FieldValueTableEditor.css";
import PlusIconButton from "components/common/buttons/icon-buttons/PlusIconButton";
import { FieldValueInput } from "interfaces/common/FieldValueInput";

interface Props {
  dataSource: FieldValueInput[],
  onAddNewItem: () => void;
  onRowSelectionChange: () => void;
  height?: string
}

const FieldValueTableEditor = forwardRef((props: Props, ref: Ref<DataGrid>) => {
  const valueCodeEditorComponent = useCallback((data: any) => {
    const fieldValue = data.data.data as FieldValueInput;
    const validationStatus = fieldValue.__ERROR__?.length ? "invalid" : "valid";
    return <IlapTextBox width={"100%"} defaultValue={fieldValue.code} validationStatus={validationStatus} />
  }, []);

  const descriptionEditorComponent = useCallback((data: any) => {
    const fieldValue = data.data.data as FieldValueInput;
    return <IlapTextBox width={"100%"} defaultValue={fieldValue.description} />
  }, []);

  return (
    <div className="field-value-editor-grid">
      <div className="absolute z-50 right-10 mt-2">
        <PlusIconButton onClick={props.onAddNewItem} />
      </div>
      <IlapViewGrid
        ref={ref}
        dataSource={props.dataSource}
        height={props.height}
        onSelectionChanged={props.onRowSelectionChange}
      >
        <Selection mode="multiple" showCheckBoxesMode="always" />
        <Editing mode={"cell"} allowUpdating={true} />
        <Column
          caption="Value code"
          dataField={"code"}
          cellComponent={valueCodeEditorComponent}
          width={"40%"}
        />
        <Column
          caption="Description (optional)"
          dataField="description"
          cellComponent={descriptionEditorComponent}
        />
        <Column width={44} />
      </IlapViewGrid>
    </div>
  )
});

export default FieldValueTableEditor;
// svg Source: "static/images/modify-icon.svg"

export default function ModifyIcon() {
  return(
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.0376 16.2247H3.2876L13.5126 5.64054L12.8876 4.96606L12.2376 4.34346L2.0376 14.9536V16.2247ZM0.537598 17.7812V14.2791L13.6876 0.659756C13.9709 0.365752 14.3253 0.21875 14.7506 0.21875C15.1753 0.21875 15.5293 0.365752 15.8126 0.659756L17.0376 1.95683C17.3209 2.25084 17.4626 2.61402 17.4626 3.04638C17.4626 3.47874 17.3209 3.84192 17.0376 4.13593L3.9126 17.7812H0.537598ZM13.5126 5.64054L12.8876 4.96606L12.2376 4.34346L13.5126 5.64054Z"
        fill="#2548EE"
      />
    </svg>
  );
}
import NavItemOption from "interfaces/NavItemOption";
import NavigationItem from "./NavigationItem";
import { useLocation } from "react-router-dom";
import { checkIfNavItemIsActive } from "shared/utilities/RouteUtility";

interface Props {
  navItems: NavItemOption[];
}

export const getNavItemId = (itemId: string) => {
  return `nav-item-id-${itemId}`;
};

export default function NavigationItems(props: Props) {
  const location = useLocation();
  return (
    <div className="h-full flex items-center gap-4">
      {props.navItems.map((navItem) => (
        <NavigationItem
          key={getNavItemId(navItem.itemId)}
          {...navItem}
          isActive={checkIfNavItemIsActive(location.pathname, navItem)}
        />
      ))}
    </div>
  );
}

export default function DeleteIconNew() {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27" height="27" rx="13.5" fill="#FFDCDC" />
        <mask id="mask0_4899_17797" maskUnits="userSpaceOnUse" x="3" y="3" width="21" height="21">
          <rect x="3.5" y="3.5" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4899_17797)">
          <path d="M9.59375 20.5313C9.16406 20.5313 8.79635 20.3784 8.49062 20.0727C8.18437 19.7664 8.03125 19.3984 8.03125 18.9688V8.8125H7.25V7.25H11.1562V6.46875H15.8438V7.25H19.75V8.8125H18.9688V18.9688C18.9688 19.3984 18.8159 19.7664 18.5102 20.0727C18.2039 20.3784 17.8359 20.5313 17.4062 20.5313H9.59375ZM17.4062 8.8125H9.59375V18.9688H17.4062V8.8125ZM11.1562 17.4063H12.7188V10.375H11.1562V17.4063ZM14.2812 17.4063H15.8438V10.375H14.2812V17.4063Z" fill="#D71010" />
        </g>
      </svg>
  
    );
  }
  
export default function RevertIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4607_11540)">
                <path d="M8.33325 5.33332C6.56659 5.33332 4.96659 5.99332 3.73325 7.06666L1.33325 4.66666V10.6667H7.33325L4.91992 8.25332C5.84659 7.47999 7.02659 6.99999 8.33325 6.99999C10.6933 6.99999 12.6999 8.53999 13.3999 10.6667L14.9799 10.1467C14.0533 7.35332 11.4333 5.33332 8.33325 5.33332Z" fill="#000D39" />
            </g>
            <defs>
                <clipPath id="clip0_4607_11540">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

import { unwrapResult } from "@reduxjs/toolkit";
import InfoWidget from "components/common/InfoWidget";
import { displayGridLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import PageTitle from "components/common/PageTitle";
import TextWithCount from "components/common/TextWithCount";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import { Column } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { IlapTabPanel, IlapViewGrid } from "ilap.common.webcomponents.test";
import BaseMetadataFieldResponse from "interfaces/response/BaseMetadataFieldResponse";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SCHEDULE_TYPES } from "shared/constants/RoutePathConstants";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { ScheduleTypeUtility } from "shared/utilities/ScheduleTypeUtility";
import { toastIlapInfo } from "shared/utilities/ToastUtility";
import { loadSingleReportScheduleTypeAsync } from "store/action/ReportScheduleTypeActions";
import { AppDispatch } from "store/store";
import ScheduleTypeViewDetails from "./ScheduleTypeViewDetails";
import IlapDropdownButton from "components/common/controls/IlapDropdownButton";

export default function ScheduleTypeView() {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  let navigate = useNavigate();

  const [reportScheduleType, setReportScheduleType] = useState<ReportScheduleType>(ScheduleTypeUtility.getEmptyScheduleType());

  // states to keep track of ScheduleType General Info and Fields.
  const [scheduleFields, setScheduleFields] = useState<BaseMetadataFieldResponse[]>([]);
  const [activityFields, setActivityFields] = useState<BaseMetadataFieldResponse[]>([]);

  const [isViewDetailsModalVisible, setIsViewDetailsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (params.id) {
      displayGridLoadingPanel();
      dispatch(loadSingleReportScheduleTypeAsync({ reportScheduleTypeId: Number(params.id) }))
        .then(unwrapResult)
        .then((reportScheduleType: ReportScheduleType) => {
          setReportScheduleType(reportScheduleType);
        })
        .catch(() => {
          toastIlapInfo("Failed to load schedule type.");
        })
        .finally(hideLoadingPanel);
    }
  }, [params, dispatch]);

  useEffect(() => {
    const activityFields: BaseMetadataFieldResponse[] = [];
    const scheduleFields: BaseMetadataFieldResponse[] = [];

    for (
      let metadataFieldIndex = 0;
      metadataFieldIndex < reportScheduleType.metadataFields?.length;
      metadataFieldIndex++
    ) {
      let metadataField = reportScheduleType.metadataFields[metadataFieldIndex];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Activity) {
        activityFields.push({
          id: metadataField.id,
          name: metadataField.name,
          validationRequirement: metadataField.validationRequirement,
          validationRequirementText: metadataField.validationRequirementText,
          receivedFromSchedule: metadataField.receivedFromSchedule
        });
      } else if (
        metadataField.planningObjectTypes === PlanningObjectTypes.Schedule
      ) {
        scheduleFields.push({
          id: metadataField.id,
          name: metadataField.name,
          validationRequirement: metadataField.validationRequirement,
          validationRequirementText: metadataField.validationRequirementText,
          receivedFromSchedule: metadataField.receivedFromSchedule
        });
      }
    }

    setActivityFields(activityFields);
    setScheduleFields(scheduleFields);
  }, [reportScheduleType])

  const navigateToEditPage = () => {
    navigate(`${SCHEDULE_TYPES}/${params.id}/edit`);
  }

  const showViewDetailsModal = () => setIsViewDetailsModalVisible(true);
  const hideViewDetailsModal = () => setIsViewDetailsModalVisible(false);

  const kebabMenuItems = [
    {
      key: "menu_item_1",
      text: "View details",
      onClick: showViewDetailsModal
    },
    {
      key: "menu_item_2",
      text: "Edit",
      onClick: navigateToEditPage
    }
  ]

  const kebabMenu = useMemo(() => {
    return <IlapDropdownButton items={kebabMenuItems} text="Options" keyExpr={"text"} displayExpr={"text"} />;
  }, []);

  const widgets = useMemo(() => {
    return [
      <InfoWidget label="Schedule level" data={reportScheduleType.planningLevelText} truncate={false} />,
      <InfoWidget label="Description" data={reportScheduleType.description} truncate={true} minWidth="200px" />
    ]
  }, [reportScheduleType]);

  const scheduleFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Schedule fields" count={scheduleFields.length} />
  }, [scheduleFields.length]);

  const activityFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Activity fields" count={activityFields.length} />
  }, [activityFields.length]);
  
  const receivedFromComponent = useCallback((data: any) => {
    const receivedFrom = data.data.data.receivedFromSchedule ? "ILAP schedule" : "Manual";   
    return <div>{receivedFrom}</div>;
  }, []);

  return (
    <div className="mx-14">
      <div className="mb-5 font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        <PageTitle id={reportScheduleType.id} title={reportScheduleType.title} menu={kebabMenu} />
      </div>
      <div>
        <WidgetsWithSeparator widgets={widgets} />
      </div>
      <hr className="mt-5 mb-4 border-light-gray" />
      <div>
        <IlapTabPanel>
          <Item tabRender={scheduleFieldsTitleComponent}>
            <IlapViewGrid dataSource={scheduleFields} className="h-[calc(100vh-264px)]">
              <Column caption={"Title"} dataField={"name"} width={368} />
              <Column caption={"Content control"} dataField={"validationRequirementText"} width={327} />
              <Column caption="Received from" dataField={"receivedFromSchedule"} alignment={"left"} cellComponent={receivedFromComponent} />
            </IlapViewGrid>
          </Item>
          <Item tabRender={activityFieldsTitleComponent}>
            <IlapViewGrid dataSource={activityFields} className="h-[calc(100vh-264px)]">
              <Column caption={"Title"} dataField={"name"} width={368} />
              <Column caption={"Content control"} dataField={"validationRequirementText"} />
            </IlapViewGrid>
          </Item>
        </IlapTabPanel>
      </div>
      {isViewDetailsModalVisible && <ScheduleTypeViewDetails scheduleType={reportScheduleType} onHideDialog={hideViewDetailsModal} />}
    </div>
  )
}
import PromineoLabel from "./PromineoLabel";

interface Props {
  id?: string | number,
  title?: string | React.ReactElement,
  menu?: React.ReactElement,
  titleClass?: string,
  label?: string
}

export default function PageTitle(props: Props) {
  const { id, title, menu, titleClass, label } = props;

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex">
        {id && <div className="mr-2">{id}</div>}
        {title && <div className={`flex-initial ${titleClass} truncate`}>{title}</div>}
        {label && <PromineoLabel label={label} className="ml-2 promineo-bordered-label" />}
      </div>
      {menu && <div>{menu}</div>}
    </div>
  )
}
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import { FieldValueValidationResult } from "../interface/FieldValueValidationResult";

export const FieldValidationUtility = {
  validateFieldValues: (fieldValues: FieldValueInput[]): FieldValueValidationResult => {

    const errors: string[] = [];
    const addError = (error: string) => errors.push(error);

    const validatedFieldValues: FieldValueInput[] = [];

    const seenInputs : Record<string, number> = {};

    fieldValues.forEach((input: FieldValueInput, index: number) => {
      let { __KEY__, __ERROR__, id, code, description } = input;

      code = code?.trim();
      description = description?.trim();
      __ERROR__ = ""; // reset error

      const lineNumber = index + 1;

      if (!code) {
        const error = `Invalid input at row ${lineNumber}: Value code must be provided.`;
        addError(error);
        __ERROR__ = error;
      }
      else if (seenInputs.hasOwnProperty(code)){
        const error = `Invalid input at row ${lineNumber}: Duplicate value code '${code}' provided. Previously occurred in row ${seenInputs[code]}.`;
        addError(error);
        __ERROR__ = error;
      }
      else { // input line is valid
        seenInputs[code] = lineNumber;
      }

      validatedFieldValues.push({
        __KEY__: __KEY__,
        __ERROR__: __ERROR__,
        id: id,
        code: code,
        description: description
      })
    });

    return {
      data: validatedFieldValues,
      isValidationSuccessful: !errors.length,
      errors: errors
    };
  }
}
import ForwardArrowIcon from "components/common/icons/ForwardArrowIcon";

export interface BreadcrumbItem {
  key: string;
  text: string;
}

interface Props {
  items: BreadcrumbItem[];
  onSelectionChange?: (index: number, selectedItem: BreadcrumbItem) => void;
  isReadonly?: boolean;
  size?: "small" | "large";
}

export default function Breadcrumb(props: Props) {
  const isLastItemToRender = (index: number) => {
    return index >= props.items.length - 1;
  };

  const handleChange = (index: number, item: BreadcrumbItem) => {
    !props.isReadonly &&
      !isLastItemToRender(index) &&
      props.onSelectionChange?.(index, item);
  };

  return (
    <div
      className={`flex ${props.size === "large" ? "space-x-3.5" : "space-x-2"}`}
    >
      {props.items.map((item: BreadcrumbItem, index: number) => {
        return (
          <div
            key={item.key}
            className={`flex ${
              props.size === "large" ? "space-x-3.5" : "space-x-2"
            }`}
          >
            <div
              className={`${
                isLastItemToRender(index) ? "text-gray-900" : "text-gray-700"
              } ${
                props.size === "large"
                  ? "leading-6 text-24px font-bold"
                  : "text-xs leading-4 font-medium"
              } font-inter ${
                !isLastItemToRender(index) && !props.isReadonly
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => handleChange(index, item)}
            >
              <span>{item.text}</span>
            </div>
            {isLastItemToRender(index) ? (
              ""
            ) : (
              <div className="m-auto">
                <ForwardArrowIcon size={props.size} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

import React from "react";
import PromineoButton from "components/common/buttons/PromineoButton";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { ControlVariant } from "shared/enums/ControlVariantEnum";
import DeleteButton from "components/common/buttons/DeleteButton";

export interface EditorActionButtonProp {
  footerComponent?: React.ReactElement;
  cancelButtonCaption?: string;
  createButtonCaption?: string;
  saveChangesButtonCaption?: string;
  doneButtonCaption?: string;
  deleteButtonCaption?: string;
  onSaveChanges?: Function;
  onCancel?: Function;
  onCreate?: Function;
  onDone?: Function;
  onDelete?: Function;
  useSubmitBehaviorOnApply?: boolean;
  mode?: PromineoModalMode;
  disableConfirmButton?: boolean;
}

export default function PromineoModalActionFooter(
  props: EditorActionButtonProp
) {
  const {
    onSaveChanges,
    onCancel,
    onCreate,
    onDone,
    onDelete,
    deleteButtonCaption,
    saveChangesButtonCaption,
    cancelButtonCaption,
    createButtonCaption,
    doneButtonCaption,
    useSubmitBehaviorOnApply,
    footerComponent,
    mode,
    disableConfirmButton,
  } = props;

  return footerComponent ? (
    <>{footerComponent}</>
  ) : mode === PromineoModalMode.Create ? (
    <div className="flex justify-end">
      <PromineoButton
        variant={ControlVariant.secondary}
        text={cancelButtonCaption}
        className="mr-9"
        onClick={() => onCancel?.()}
      />
      <PromineoButton
        disabled={disableConfirmButton}
        variant={ControlVariant.primary}
        text={createButtonCaption}
        onClick={() => onCreate?.()}
      />
    </div>
  ) : mode === PromineoModalMode.View ? (
    <div className="flex justify-end">
      <PromineoButton
        variant={ControlVariant.primary}
        text={doneButtonCaption}
        onClick={() => onDone?.()}
      />
    </div>
  ) : mode === PromineoModalMode.Modify ? (
    <div className="border-0 flex justify-between items-center">
      <div className="border-0">
        <DeleteButton text={deleteButtonCaption} onClick={() => onDelete?.()} />
      </div>

      <div className="border-0">
        <PromineoButton
          text={cancelButtonCaption}
          variant={ControlVariant.secondary}
          className="mr-9"
          onClick={() => onCancel?.()}
        />

        <PromineoButton
          disabled={disableConfirmButton}
          text={saveChangesButtonCaption}
          useSubmitBehavior={useSubmitBehaviorOnApply}
          onClick={() => onSaveChanges?.()}
        />
      </div>
    </div>
  ) : mode === PromineoModalMode.ConfirmationDialog ? (
    <div className="flex justify-center">
      <PromineoButton
        variant={ControlVariant.primary}
        text={cancelButtonCaption}
        className="mr-9"
        onClick={() => onCancel?.()}
      />
      <PromineoButton
        variant={ControlVariant.secondary}
        text={doneButtonCaption}
        onClick={() => onDone?.()}
      />
    </div>
  ) : (
    <></>
  );
}

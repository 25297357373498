const getPublicConfiguration = () => {
  const publicConfiguration = (window as any).publicConfigurations || {};
  return publicConfiguration;
};

const getEnvConfigurations = () => {
  const envConfiguration = process.env;
  return envConfiguration;
};

const getConfigurations = () => {
  const publicConfiguration = getPublicConfiguration();
  const envConfiguration = getEnvConfigurations();
  return [publicConfiguration, envConfiguration];
};

export const getAuthenticationAuthority = () => {
  const [publicConfiguration, envConfiguration] = getConfigurations();
  return (
    publicConfiguration.authenticationAuthority ||
    envConfiguration.REACT_APP_AUTH_AUTHORITY ||
    "https://login.microsoftonline.com/e8ddb777-121f-4abc-a113-6b91d4b5defc/"
  );
};

export const getAuthenticationClientId = () => {
  const [publicConfiguration, envConfiguration] = getConfigurations();
  return (
    publicConfiguration.authenticationClientId ||
    envConfiguration.REACT_APP_AUTH_CLIENTID ||
    "b680058e-860b-4acc-80cb-89f8f1fd5a99"
  );
};

export const getAuthenticationScopes = () => {
  const [publicConfiguration, envConfiguration] = getConfigurations();
  return (
    publicConfiguration.authenticationScopes ||
    envConfiguration.REACT_APP_AUTH_SCOPES ||
    "api://5f9d20ce-8ac7-4115-80a7-2f1b2acd7bda/user_impersonation,User.Read"
  ).split(",");
};

export const getAuthenticationRedirectUri = () => {
  const [publicConfiguration, envConfiguration] = getConfigurations();
  return (
    publicConfiguration.authenticationRedirectUri ||
    envConfiguration.REACT_APP_AUTH_REDIRECTURI ||
    "http://localhost:3100/"
  );
};

export const getAnalyticsApiUrl = () => {
  const [publicConfiguration, envConfiguration] = getConfigurations();
  return (
    publicConfiguration.analyticsApiUrl ||
    envConfiguration.REACT_APP_ANALYTICS_API_URL
  );
};

export const getAnalyticsGraphQLApiUrl = () => {
  const apiUrl = getAnalyticsApiUrl();
  const origin = getOrigin(apiUrl);
  const graphQlUrl = `${origin}/graphql`;
  return graphQlUrl;
};

const getOrigin = (url: string): string => {
  try {
      const parsedUrl = new URL(url);
      return parsedUrl.origin;
  } 
  catch {
      console.error(`Invalid URL: ${url}`);
      return "";
  }
}
interface Props {
  children?: React.ReactNode,
  showValidationMessage?: boolean,
  validationMessage?: string,
  wrapperWidth?: "100%" | "fit-content" 
}

const getWrapperWidth = (config?: string) => {
  if (!config){
    return "w-fit";
  }

  return config === "100%" ? "w-full" : "w-fit";
}

export default function ValidatedContent(props: Props) {
  const wrapperWidth = getWrapperWidth(props.wrapperWidth);
  const validationMessage = props.validationMessage ? props.validationMessage : "This field is required";

  return (
    <div className={`${wrapperWidth} flex flex-col items-end`}>
      {props.children}
      {props.showValidationMessage && <div className="font-poppins text-12px font-normal text-gray-9">{validationMessage}</div>}
    </div>
  )
}
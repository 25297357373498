import { IButtonOptions } from "devextreme-react/button";
import { ReactNode } from "react";
import { ControlVariant } from "shared/enums/ControlVariantEnum";
import PromineoButton from "./PromineoButton";

interface Props {
  Icon: ReactNode;
  variant?: ControlVariant;
}

type PropType = Omit<Props & IButtonOptions, "children">;

export default function PromineoButtonWithIcon(props: PropType) {
  const { Icon, text, variant, className, ...rest } = props;
  return (
    <PromineoButton variant={variant} {...rest} className={`promineo-button-with-icon ${className ?? ''}`}>
      <div className="flex justify-center items-center">
        {Icon}
        <div className="ml-7.5px">{text}</div>
      </div>
    </PromineoButton>
  );
}

import { ReactNode } from "react";
import { IPopupOptions, Popup } from "devextreme-react/popup";
import "../../modal/styles/PromineoModal.css";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import CloseIcon from "../icons/Close-icon";
import PromineoModalActionFooter, {
  EditorActionButtonProp,
} from "components/modal/PromineoModalActionFooter";

export interface Props extends IPopupOptions {
  className?: string;
  children?: ReactNode;
  title?: string;
  content?: string;
  width?: string;
  height?: string;
  onCancel?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onConfirm?: () => void;
}

export default function PromineoConfirmationDialog(props: Props) {
  const {
    title,
    className,
    children,
    content,
    width,
    height,
    onCancel,
    cancelButtonText,
    onConfirm,
    confirmButtonText,
    ...rest
  } = props;

  const defaultActionProps: EditorActionButtonProp = {
    doneButtonCaption: confirmButtonText ?? "Yes",
    cancelButtonCaption: cancelButtonText ?? "No",
    onDone: onConfirm,
    onCancel: onCancel,
    mode: PromineoModalMode.ConfirmationDialog,
  };

  const CrossButton = () => {
    return (
      <button onClick={onCancel}>
        <div className="">
          <CloseIcon />
        </div>
      </button>
    );
  };

  const Title = () => {
    return (
      <div className="text-18px leading-20px text-gray font-bold font-inter">
        {title}
      </div>
    );
  };

  return (
    <Popup
      wrapperAttr={{ class: "promineo-popup" }}
      visible={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={width || "300"}
      height={height || "200"}
      position={"center"}
      showTitle={false}
      className={`${className ?? ""}`}
      {...rest}
    >
      <div>
        <div className="flex justify-end mr-4 mt-3 h-6">
          <CrossButton />
        </div>
        <div className="text-center mt-6">
          <div>
            <Title />
          </div>
          <div className="mt-2 text-11px leading-12px text-gray-500 font-medium font-inter">
            {content}
          </div>
        </div>
        <div className="mt-6">
          <PromineoModalActionFooter
            {...defaultActionProps}
            mode={PromineoModalMode.ConfirmationDialog}
          />
        </div>
      </div>
    </Popup>
  );
}

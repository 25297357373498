import { useEffect, useState } from "react";
import PromineoOptionDropdown from "../controls/PromineoOptionDropdown";
import IlapDropdownButton from "../controls/IlapDropdownButton";
interface RowOperationOption {
  text: string;
  onClick: (data?: any) => void;
  textFn?: (data: any) => string;
  visible?: boolean;
  visibleFn?: (data: any) => boolean;
}

export interface RowOperationDefaultOption {
  onOpen?: () => void;
  onView?: () => void;
  onModify?: () => void;
  hideOpen?: boolean;
  hideView?: boolean;
  hideModify?: boolean;
  viewOptionText?: string;
  modifyOptionText?: string;
  useIlapOptionDropdown?: boolean;
}

export interface PromineoGridRowOptionConfig {
  items?: RowOperationOption[];
  displayDefault?: boolean;
  defaultOptionConfig?: RowOperationDefaultOption;
}

export default function PromineoGridRowOptionColumnTemplate(props: PromineoGridRowOptionConfig) {
  const { displayDefault, defaultOptionConfig } = props;
  const [options, setOptions] = useState<RowOperationOption[]>(props.items ?? []);

  useEffect(() => {
    setOptions(props.items ?? [])
  }, [props.items]);

  useEffect(() => {
    if (displayDefault === true) {
      var defaultOptions: RowOperationOption[] = [];

      if (defaultOptionConfig?.hideOpen !== true) {
        defaultOptions.push({
          text: "Open",
          onClick: defaultOptionConfig?.onOpen
            ? defaultOptionConfig?.onOpen
            : () => { },
        });
      }
      var viewOptionText = defaultOptionConfig?.viewOptionText ?? "View";

      if (defaultOptionConfig?.hideView !== true) {
        defaultOptions.push({
          text: viewOptionText,
          onClick: defaultOptionConfig?.onView
            ? defaultOptionConfig?.onView
            : () => { },
        });
      }
      var modifyOptionText = defaultOptionConfig?.modifyOptionText ?? "Modify";
      if (defaultOptionConfig?.hideModify !== true) {
        defaultOptions.push({
          text: modifyOptionText,
          onClick: defaultOptionConfig?.onModify
            ? defaultOptionConfig?.onModify
            : () => { },
        });
      }

      setOptions(prev => [...defaultOptions, ...prev]);
    }
  }, [props.items, displayDefault]);

  return (
    <div className="cursor-pointer inline-block px-2">
      {defaultOptionConfig?.useIlapOptionDropdown ?
        <IlapDropdownButton
          text="Options"
          items={options}
          keyExpr={"text"}
          displayExpr={"text"}
        />
        :
        <PromineoOptionDropdown
          items={options}
          keyExpr={"text"}
          displayExpr={"text"}
        />}

    </div>
  );
}

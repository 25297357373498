import { ReactNode } from "react";
import LabelWithAnnotation from "./LabelWithAnnotation";
import "./styles/LabelWithContent.css";

interface Props {
  label: ReactNode | string;
  content: ReactNode | string;
  addAsterisksymbol?: boolean;
  isInline?: boolean;
  isOptional?: boolean; 
  rootClass?: string; 
}

export default function LabelWithContent(props: Props) {
  const { label, content, addAsterisksymbol, isInline, isOptional, rootClass} = props;

  return (
    <div
      className={`${rootClass} ${
        isInline ? "flex justify-center items-center gap-2" : "space-y-1 label-with-content"
      }`}            
    >
      <LabelWithAnnotation
        label={label}
        addAsterisksymbol={addAsterisksymbol}
        isOptional={isOptional}
      />
      <div>{content}</div>
    </div>
  );
}

import { ReactNode } from "react";
import { IPopupOptions, Popup } from "devextreme-react/popup";
import { IlapButton, IlapButtonType, IlapTextBox } from "ilap.common.webcomponents.test";
import "./styles/IlapConfirmationDialog.css";
import DeleteIcon from "../icons/DeleteIconNew";
import { ValidationStatus } from "devextreme/common";

export interface Props extends IPopupOptions {
  className?: string;
  children?: ReactNode;
  title?: string;
  content?: string;
  subContent?: string | ReactNode;
  showInput?: boolean;
  onInputChanged?: (input: string) => void;
  inputValidationStatus?: ValidationStatus;
  width?: string;
  height?: string;
  onCancel?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  additionalButtonText?: string;
  onConfirm?: () => void;
  onAdditionalButtonClick?: () => void;
  isDeleteConfirm?: boolean;
}

export default function IlapConfirmationDialog(props: Props) {
  const {
    title,
    className,
    children,
    content,
    subContent,
    showInput,
    onInputChanged,
    inputValidationStatus,
    width,
    height,
    onCancel,
    cancelButtonText,
    onConfirm,
    confirmButtonText,
    additionalButtonText,
    onAdditionalButtonClick,
    isDeleteConfirm,
    ...rest
  } = props;

  return (
    <Popup
      wrapperAttr={{ class: "Ilap-confirmation-popup" }}
      visible={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={width ?? "456"}
      height={height ?? "181"}
      position={"center"}
      showTitle={false}
      className={`${className ?? ""}`}
      {...rest}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="h-full">
          <div className="content inline-flex items-center">
            {isDeleteConfirm ? <div className="mr-2"><DeleteIcon /></div> : <></>}
            <div className="line-clamp-2">{content}</div>
          </div>
          {subContent && (
            <div className="subcontent">{subContent}</div>
          )}
        </div>
        {showInput && onInputChanged && (
            <div className="my-3">
              <IlapTextBox 
                width={"100%"}
                onChange={({ event }: any) => onInputChanged(event.currentTarget.value)}
                validationStatus={inputValidationStatus ?? "valid"}/>
            </div>
          )}
        <div className="Ilap-confirmation-footer">
          <IlapButton
            text={cancelButtonText ?? "Cancel"}
            variant={IlapButtonType.Secondary}
            onClick={() => onCancel?.()}
          />
          {additionalButtonText && onAdditionalButtonClick && (
            <IlapButton
              className="ml-3"
              text={additionalButtonText}
              variant={IlapButtonType.Borderless}
              onClick={onAdditionalButtonClick}
            />
          )}
          {isDeleteConfirm ? (
            <IlapButton
              className="ml-3"
              text={confirmButtonText ?? "Ok"}
              variant={IlapButtonType.Danger}
              onClick={() => onConfirm?.()}
            />
          ) : (
            <IlapButton
              className="ml-3"
              text={confirmButtonText ?? "Ok"}
              variant={IlapButtonType.Primary}
              onClick={() => onConfirm?.()}
            />
          )}
        </div>
      </div>
    </Popup>
  );
}
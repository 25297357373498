import { ReactNode } from "react";

interface Props {
  label: ReactNode | string;
  addAsterisksymbol?: boolean;
  isOptional?: boolean;
}

export default function LabelWithAnnotation(props: Props) {
  const { label, addAsterisksymbol, isOptional } = props;

  return (
    <div className="flex  text-xs leading-[18px] space-x-6">
      <div className="font-inter text-textGray">
        <div className="font-semibold text-sm">{label}{isOptional && <span className="font-normal">{" "}(optional)</span>}</div>
        {addAsterisksymbol && (
        <div className="text-red inline">
          *
        </div>
      )}
      </div>
    </div>
  )
}
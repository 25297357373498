import { ScrollView } from "devextreme-react";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import { useMemo } from "react";
import "./styles/ScheduleTypeViewDetails.css";
import LabelWithContent from "components/common/LabelWithContent";
import { getFormattedDateForColumn } from "shared/utilities/DateTimeUtility";
import CrossIconButton from "components/common/buttons/icon-buttons/CrossIconButton";
import { IlapPopup } from "ilap.common.webcomponents.test";

interface Props {
  scheduleType: ReportScheduleType,
  onHideDialog: () => void;
}

export default function ScheduleTypeViewDetails(props: Props) {
  const { scheduleType, onHideDialog } = props;

  const modalWrapperAttr = useMemo(() => {
    return {
      class: "Ilap-schedule-type-view-details",
    };
  }, []);

  return (<IlapPopup
    wrapperAttr={modalWrapperAttr}
    width={"49vw"}
    height={"528px"}
    minWidth={"728px"}
    minHeight={"528px"}>
    <div>
      <header className="flex justify-between items-center text-gray-9">
        <div title={scheduleType.title} className="font-poppins text-18px font-semibold truncate">{scheduleType.title}</div>
        <CrossIconButton onClick={onHideDialog} />
      </header>
      <hr className="mt-[14px] mb-[18px] border-light-gray" />
      <div className="grid grid-cols-3 gap-y-4 gap-x-6">
        <LabelWithContent 
          label="Schedule type ID"
          content={<div className="info-content">{scheduleType.id}</div>}
        />
        <LabelWithContent
          label="Date created"
          content={<div className="info-content">{scheduleType.created ? getFormattedDateForColumn(scheduleType.created) : "-"}</div>}
        />
        <LabelWithContent
          label="# Times in use"
          content={<div className="info-content">{scheduleType.timesInUse}</div>}
        />
        <LabelWithContent
          label="Schedule level"
          content={<div className="info-content">{scheduleType.planningLevelText}</div>}
        />
        <LabelWithContent
          label="Date updated"
          content={<div className="info-content">{scheduleType.lastModified ? getFormattedDateForColumn(scheduleType.lastModified) : "-"}</div>}
        />
        <LabelWithContent
          label="# Activity fields"
          content={<div className="info-content">{scheduleType.numberOfActivityMetadataFields}</div>}
        />
        <LabelWithContent
          label="Description"
          content={
            <ScrollView showScrollbar="always">
              <div className="info-content h-[210px]">{scheduleType.description}</div>
            </ScrollView>
          }
        />
        <LabelWithContent
          label="Updated by"
          content={<div className="info-content">{scheduleType.lastModifierName}</div>}
        />
        <LabelWithContent
          label="# Schedule fields"
          content={<div className="info-content">{scheduleType.numberOfReportScheduleMetadataFields}</div>}
        />
      </div>
    </div>
  </IlapPopup>
  )
}
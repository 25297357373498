import ReportScheduleType from "interfaces/response/ReportScheduleType";

export const ScheduleTypeUtility = {
  getEmptyScheduleType: (): ReportScheduleType => {
    return {
      id: 0,
      title: "",
      description: "",
      timesInUse: 0,
      numberOfActivityMetadataFields: 0,
      numberOfReportScheduleMetadataFields: 0,
      planningLevel: 0,
      planningLevelText: "",
      metadataFields: [],
      reportSchedules: []
    };
  }
}
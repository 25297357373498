interface Props {
  fillColor?: "white" | "black";
}

export default function LandingIlapLogo(props: Props) {
  return (
    <svg width="156" height="57" viewBox="0 0 156 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8574 56.0005L29.337 46.4183V19.146L22.0692 23.2187V44.1674L11.8574 38.4485V56.0005Z" fill="#E07900"/>
      <path d="M20.6892 41.8115V19.146L0.449707 30.4787L20.6892 41.8115Z" fill="#E07900"/>
      <path d="M11.8578 19.5333V0.000488281L29.3374 9.76692L11.8578 19.5333Z" fill="#E07900"/>
      <path d="M47.8194 36.8173H38.5356V9.6084H47.8194V36.8173Z" fill="white"/>
      <path d="M60.211 9.58276V30.0722H79.5618L75.9951 36.8527H50.9038V9.58276H60.2087H60.211Z" fill="white"/>
      <path d="M119.419 37.1784H110.86L108.472 32.1927H90.2706L87.8834 37.1784H79.3242L92.8946 9.90845H105.846L119.416 37.1784H119.419ZM93.2018 26.0363H105.539L100.732 16.0227H98.009L93.2018 26.0363Z" fill="white"/>
      <path d="M131.131 27.7635V36.8527H121.826V9.58276H142.143C151.382 9.58276 156.001 12.539 156.001 18.4538V18.8198C156.001 24.7815 151.382 27.7635 142.143 27.7635H131.131ZM131.131 21.6423H141.981C144.856 21.6423 146.293 20.6897 146.293 18.7822V18.672C146.293 16.741 144.856 15.7767 141.981 15.7767H131.131V21.6423Z" fill="white"/>
      <path d="M44.5393 40.733L40.2772 52.4847H38.5352L43.4428 39.5906H44.5658L44.5393 40.733ZM48.1117 52.4847L43.8407 40.733L43.8142 39.5906H44.9372L49.8625 52.4847H48.1117ZM47.8906 47.7114V49.1106H40.6574V47.7114H47.8906Z" fill="white"/>
      <path d="M52.9751 44.9484V52.4847H51.3393V42.9027H52.8867L52.9751 44.9484ZM52.5861 47.3306L51.9052 47.304C51.9111 46.6487 52.0083 46.0436 52.197 45.4886C52.3856 44.9277 52.6509 44.4406 52.9928 44.0274C53.3347 43.6141 53.7415 43.2953 54.2131 43.0709C54.6906 42.8407 55.2182 42.7255 55.7959 42.7255C56.2675 42.7255 56.692 42.7905 57.0693 42.9204C57.4466 43.0444 57.7678 43.2451 58.0331 43.5226C58.3043 43.8001 58.5106 44.1602 58.6521 44.603C58.7936 45.0399 58.8643 45.5742 58.8643 46.2059V52.4847H57.2196V46.1882C57.2196 45.6864 57.1459 45.2849 56.9985 44.9838C56.8512 44.6768 56.636 44.4554 56.353 44.3196C56.0701 44.1779 55.7222 44.1071 55.3096 44.1071C54.9028 44.1071 54.5314 44.1927 54.1954 44.3639C53.8653 44.5351 53.5794 44.7713 53.3377 45.0724C53.1019 45.3735 52.9162 45.7188 52.7806 46.1085C52.6509 46.4923 52.5861 46.8996 52.5861 47.3306Z" fill="white"/>
      <path d="M66.9907 50.8464V45.9137C66.9907 45.5358 66.914 45.2081 66.7608 44.9307C66.6134 44.6473 66.3894 44.4288 66.0887 44.2753C65.7881 44.1218 65.4167 44.0451 64.9746 44.0451C64.5619 44.0451 64.1994 44.1159 63.8869 44.2576C63.5804 44.3993 63.3387 44.5853 63.1618 44.8155C62.9909 45.0458 62.9054 45.2938 62.9054 45.5594H61.2695C61.2695 45.217 61.3579 44.8775 61.5348 44.541C61.7116 44.2045 61.9651 43.9004 62.2953 43.6288C62.6313 43.3514 63.0321 43.1329 63.4978 42.9735C63.9695 42.8082 64.4941 42.7255 65.0718 42.7255C65.7675 42.7255 66.3805 42.8436 66.9111 43.0798C67.4475 43.3159 67.8661 43.6731 68.1667 44.1513C68.4733 44.6237 68.6266 45.217 68.6266 45.9314V50.3948C68.6266 50.7136 68.6531 51.053 68.7061 51.4132C68.7651 51.7733 68.8506 52.0833 68.9626 52.3431V52.4847H67.256C67.1734 52.2958 67.1086 52.0449 67.0614 51.732C67.0143 51.4132 66.9907 51.118 66.9907 50.8464ZM67.2736 46.6753L67.2913 47.8265H65.6378C65.172 47.8265 64.7564 47.8649 64.3909 47.9417C64.0255 48.0125 63.7189 48.1217 63.4713 48.2693C63.2237 48.4169 63.0351 48.6029 62.9054 48.8273C62.7757 49.0457 62.7109 49.3025 62.7109 49.5977C62.7109 49.8988 62.7786 50.1734 62.9142 50.4213C63.0498 50.6693 63.2532 50.8671 63.5244 51.0147C63.8014 51.1564 64.1404 51.2272 64.5413 51.2272C65.0424 51.2272 65.4845 51.1209 65.8677 50.9084C66.2508 50.6959 66.5544 50.4361 66.7785 50.1291C67.0084 49.8221 67.1322 49.5239 67.1498 49.2346L67.8484 50.0228C67.8071 50.2708 67.6951 50.5453 67.5124 50.8464C67.3296 51.1475 67.085 51.4368 66.7785 51.7143C66.4778 51.9859 66.1182 52.2132 65.6997 52.3962C65.287 52.5733 64.8213 52.6619 64.3025 52.6619C63.6541 52.6619 63.0852 52.5349 62.5959 52.2811C62.1125 52.0272 61.7352 51.6877 61.464 51.2626C61.1988 50.8316 61.0661 50.3505 61.0661 49.8191C61.0661 49.3055 61.1663 48.8538 61.3668 48.4642C61.5672 48.0686 61.8561 47.7409 62.2334 47.4812C62.6106 47.2155 63.0646 47.0148 63.5951 46.879C64.1257 46.7432 64.7181 46.6753 65.3725 46.6753H67.2736Z" fill="white"/>
      <path d="M72.9771 38.8821V52.4847H71.3324V38.8821H72.9771Z" fill="white"/>
      <path d="M78.2915 51.4929L80.9532 42.9027H82.704L78.8663 53.9637C78.7779 54.1998 78.66 54.4537 78.5126 54.7253C78.3711 55.0028 78.1884 55.2655 77.9644 55.5135C77.7403 55.7614 77.4692 55.9622 77.1508 56.1157C76.8384 56.2751 76.4641 56.3548 76.0278 56.3548C75.8981 56.3548 75.7331 56.3371 75.5326 56.3016C75.3322 56.2662 75.1907 56.2367 75.1082 56.2131L75.0993 54.8847C75.1465 54.8906 75.2202 54.8965 75.3204 54.9024C75.4265 54.9142 75.5002 54.9201 75.5415 54.9201C75.9129 54.9201 76.2283 54.8699 76.4876 54.7696C76.747 54.6751 76.9651 54.5127 77.142 54.2825C77.3247 54.0581 77.481 53.7482 77.6107 53.3526L78.2915 51.4929ZM76.3373 42.9027L78.8221 50.3416L79.2465 52.0685L78.0705 52.6707L74.5511 42.9027H76.3373Z" fill="white"/>
      <path d="M88.1864 42.9027V44.1602H83.0135V42.9027H88.1864ZM84.7643 40.5736H86.4002V50.1114C86.4002 50.4361 86.4503 50.6811 86.5505 50.8464C86.6507 51.0117 86.7804 51.1209 86.9396 51.1741C87.0988 51.2272 87.2697 51.2538 87.4525 51.2538C87.5881 51.2538 87.7295 51.242 87.8769 51.2184C88.0302 51.1888 88.1451 51.1652 88.2218 51.1475L88.2306 52.4847C88.1009 52.5261 87.93 52.5644 87.7178 52.5999C87.5114 52.6412 87.2609 52.6619 86.9661 52.6619C86.5653 52.6619 86.1968 52.5822 85.8608 52.4228C85.5248 52.2633 85.2566 51.9977 85.0561 51.6257C84.8616 51.2479 84.7643 50.7401 84.7643 50.1025V40.5736Z" fill="white"/>
      <path d="M91.8826 42.9027V52.4847H90.2379V42.9027H91.8826ZM90.1141 40.361C90.1141 40.0953 90.1937 39.871 90.3529 39.688C90.5179 39.5049 90.7596 39.4134 91.0779 39.4134C91.3904 39.4134 91.6291 39.5049 91.7942 39.688C91.9652 39.871 92.0506 40.0953 92.0506 40.361C92.0506 40.6149 91.9652 40.8333 91.7942 41.0164C91.6291 41.1935 91.3904 41.282 91.0779 41.282C90.7596 41.282 90.5179 41.1935 90.3529 41.0164C90.1937 40.8333 90.1141 40.6149 90.1141 40.361Z" fill="white"/>
      <path d="M98.3377 51.3158C98.7268 51.3158 99.0864 51.2361 99.4165 51.0767C99.7467 50.9173 100.018 50.6988 100.23 50.4213C100.442 50.1379 100.563 49.8162 100.593 49.456H102.149C102.119 50.0228 101.928 50.5512 101.574 51.0412C101.226 51.5254 100.769 51.918 100.204 52.2191C99.6376 52.5143 99.0157 52.6619 98.3377 52.6619C97.6185 52.6619 96.9907 52.5349 96.4543 52.2811C95.9237 52.0272 95.4816 51.6789 95.1279 51.2361C94.7801 50.7933 94.5177 50.2855 94.3409 49.7128C94.1699 49.1343 94.0844 48.5232 94.0844 47.8797V47.5077C94.0844 46.8642 94.1699 46.2561 94.3409 45.6834C94.5177 45.1048 94.7801 44.5941 95.1279 44.1513C95.4816 43.7085 95.9237 43.3602 96.4543 43.1063C96.9907 42.8525 97.6185 42.7255 98.3377 42.7255C99.0864 42.7255 99.7408 42.879 100.301 43.186C100.861 43.4871 101.3 43.9004 101.618 44.4259C101.943 44.9454 102.119 45.5358 102.149 46.1971H100.593C100.563 45.8015 100.451 45.4443 100.257 45.1255C100.068 44.8067 99.8086 44.5528 99.4784 44.3639C99.1542 44.1691 98.774 44.0716 98.3377 44.0716C97.8367 44.0716 97.4152 44.172 97.0732 44.3727C96.7372 44.5676 96.469 44.8332 96.2686 45.1698C96.074 45.5004 95.9325 45.8694 95.8441 46.2768C95.7616 46.6782 95.7203 47.0885 95.7203 47.5077V47.8797C95.7203 48.2989 95.7616 48.7121 95.8441 49.1195C95.9266 49.5269 96.0652 49.8959 96.2597 50.2265C96.4602 50.5571 96.7284 50.8228 97.0644 51.0235C97.4063 51.2184 97.8308 51.3158 98.3377 51.3158Z" fill="white"/>
      <path d="M109.559 49.9431C109.559 49.7069 109.506 49.4885 109.4 49.2878C109.3 49.0811 109.09 48.8952 108.772 48.7298C108.46 48.5586 107.988 48.411 107.357 48.287C106.827 48.1749 106.346 48.042 105.916 47.8885C105.491 47.735 105.129 47.5491 104.828 47.3306C104.533 47.1122 104.306 46.8553 104.147 46.5601C103.988 46.265 103.909 45.9196 103.909 45.524C103.909 45.1462 103.991 44.789 104.156 44.4524C104.327 44.1159 104.566 43.8178 104.872 43.558C105.185 43.2982 105.559 43.0945 105.995 42.9469C106.432 42.7993 106.918 42.7255 107.454 42.7255C108.221 42.7255 108.875 42.8613 109.418 43.1329C109.96 43.4045 110.375 43.7676 110.664 44.2222C110.953 44.6709 111.098 45.1698 111.098 45.7188H109.462C109.462 45.4532 109.382 45.1963 109.223 44.9484C109.07 44.6945 108.843 44.4849 108.542 44.3196C108.247 44.1543 107.885 44.0716 107.454 44.0716C107.001 44.0716 106.632 44.1425 106.349 44.2842C106.072 44.42 105.869 44.5941 105.739 44.8067C105.615 45.0192 105.553 45.2436 105.553 45.4797C105.553 45.6568 105.583 45.8163 105.642 45.9579C105.707 46.0937 105.819 46.2207 105.978 46.3388C106.137 46.4509 106.361 46.5572 106.65 46.6576C106.939 46.7579 107.307 46.8583 107.755 46.9587C108.539 47.1358 109.185 47.3483 109.692 47.5963C110.199 47.8443 110.576 48.1483 110.824 48.5084C111.071 48.8686 111.195 49.3055 111.195 49.8191C111.195 50.2383 111.106 50.6221 110.93 50.9704C110.759 51.3187 110.508 51.6198 110.178 51.8737C109.854 52.1217 109.465 52.3165 109.011 52.4582C108.563 52.594 108.059 52.6619 107.499 52.6619C106.656 52.6619 105.942 52.5113 105.359 52.2102C104.775 51.9091 104.333 51.5195 104.032 51.0412C103.732 50.563 103.581 50.0582 103.581 49.5269H105.226C105.25 49.9756 105.379 50.3328 105.615 50.5984C105.851 50.8582 106.14 51.0442 106.482 51.1564C106.824 51.2626 107.163 51.3158 107.499 51.3158C107.947 51.3158 108.321 51.2567 108.622 51.1386C108.928 51.0206 109.161 50.8582 109.32 50.6516C109.479 50.4449 109.559 50.2088 109.559 49.9431Z" fill="white"/>
    </svg>
  );
}

import { useEffect, useMemo, useState } from "react";
import { Column } from "devextreme-react/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { loadReportScheduleTypesAsync } from "store/action/ReportScheduleTypeActions";
import {
  displayGridLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { IlapViewGrid, Breadcrumb, BreadcrumbItem } from "ilap.common.webcomponents.test";
import { useNavigate } from "react-router";
import { SCHEDULE_TYPES } from "shared/constants/RoutePathConstants";
import ScheduleTypeCreateModal from "./create/ScheduleTypeCreateModal";
import { getCellValueOrDefault } from "shared/utilities/DataGridUtility";

enum BreadcrumbSelectionKeys {
  scheduleTypes = "report-schedule-types",
}

export default function ReportScheduleTypesGrid() {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [isUpsertDialogVisible, setIsUpsertDialogVisible] = useState(false);

  const reportScheduleTypes = useSelector((rootStore: RootState) => rootStore.reportScheduleTypeData.reportScheduleTypes);

  useEffect(() => {
    displayGridLoadingPanel();
    dispatch(loadReportScheduleTypesAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  const handleNewScheduleTypeClick = () => {
    setIsUpsertDialogVisible(true);
  };

  const handleOnViewScheduleType = (data: any) => {
    navigate(`${SCHEDULE_TYPES}/${data.data.id}`);
  };

  const handleHideDialog = () => {
    setIsUpsertDialogVisible(false);
  };

  const breadCrumbItems: BreadcrumbItem[] = [
    {
      key: BreadcrumbSelectionKeys.scheduleTypes,
      text: "Schedule types",
    },
  ];

  const toolbarConfig = useMemo(() => {
    return {
      dislplaySearchPanel: true,
      displayAddNewButton: true,
      addNewButtonText: "New",
      onClickAddNew: handleNewScheduleTypeClick,
    }
  }, []);

  return (
    <div className="mx-14">
      <div className="mb-[21px] font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal">
        <Breadcrumb items={breadCrumbItems}/>
      </div>
      <IlapViewGrid
        dataSource={reportScheduleTypes}
        className="max-h-[calc(100vh-166px)]"
        toolbarConfig={toolbarConfig}
        onRowClick={(data: any) => {
          handleOnViewScheduleType(data);
        }}
        columnMinWidth={50}
      >
        <Column
          caption={"Schedule type ID"}
          dataField="id"
          alignment="left"

        />
        <Column
          caption={"Title"}
          dataField="title"
          width={400}
        />
        <Column
          caption={"Schedule level"}
          dataField="planningLevelText"
        />
        <Column
          caption={"Date created"}
          dataField="created"
          alignment="left"
          dataType={"datetime"}
          customizeText={getCellValueOrDefault}
        />
        <Column
          caption={"Date updated"}
          dataField="lastModified"
          alignment="left"
          dataType={"datetime"}
          customizeText={getCellValueOrDefault}
        />
        <Column
          caption={"Updated by"}
          dataField="lastModifierName"
        />
      </IlapViewGrid>
      {isUpsertDialogVisible && <ScheduleTypeCreateModal onHideDialog={handleHideDialog} />}
    </div>
  );
}
import LabelWithContent from "components/common/LabelWithContent";
import ScrollView from "devextreme-react/scroll-view";
import "./styles/ReportScheduleView.css";

interface Props {
  label?: string;
  value?:string;
  useScrollView?: boolean;  
  descLength: number;  
}

export default function ReportScheduleItemView(props: Props) {
  const { label, value, useScrollView, descLength } = props;
  const itemRootClass =  descLength > 119 ? (descLength>160? "h-[60px]": "h-[70px]") : "h-[80px]";
  return (
    <LabelWithContent
      label={label}
      rootClass={itemRootClass}
      content={useScrollView ?
        <ScrollView showScrollbar="always">
          <div className="ilap-rs-view-value-text-desc">{value}</div>
        </ScrollView>
        :
        <div className="ilap-rs-view-value-text truncate" title={value}>{value}</div>
      }
    />
  )
}

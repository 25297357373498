import { useRef, useState, useEffect } from 'react';

interface Props {
  data: string | React.ReactElement;
}

export default function useTruncate(props: Props) {
  const { data } = props;

  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (contentRef.current) {
        const { scrollWidth, clientWidth } = contentRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(checkTruncation);
    const currentRef = contentRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
    
    checkTruncation();

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [data]);

  return { contentRef, isTruncated };
}
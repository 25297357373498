import { createSlice } from "@reduxjs/toolkit";
import MetadataField from "interfaces/response/MetadataField";
import MetadataFieldWithContentControlResponse from "interfaces/response/MetadataFieldWithContentControlResponse";
import { toastIlapInfo } from "shared/utilities/ToastUtility";
import {
  createMetadataFieldAsync,
  createMetadataFieldsAsync,
  deleteMetadataFieldAsync,
  loadMetadataFieldsAsync,
  loadMetadataFieldsFromFlowAsync,
  updateMetadataFieldAsync,
} from "store/action/MetadataActions";

export interface MetadataState {
  metadataFields: MetadataField[];
  metadataFieldsFromFlow: MetadataFieldWithContentControlResponse[];
}

const initialState: MetadataState = {
  metadataFields: [],
  metadataFieldsFromFlow: []
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadMetadataFieldsAsync.pending,
      (state) => {
        state.metadataFields = [];
      }
    );
    builder.addCase(
      loadMetadataFieldsAsync.fulfilled,
      (state, { payload }: { payload: MetadataField[] }) => {
        state.metadataFields = payload;
      }
    );
    builder.addCase(
      loadMetadataFieldsFromFlowAsync.pending,
      (state) => {
        state.metadataFieldsFromFlow = [];
      }
    );
    builder.addCase(
      loadMetadataFieldsFromFlowAsync.fulfilled,
      (state, { payload }: { payload: MetadataFieldWithContentControlResponse[] }) => {
        state.metadataFieldsFromFlow = payload;
      }
    );
    builder.addCase(
      updateMetadataFieldAsync.fulfilled,
      (state, { payload }: { payload: MetadataField }) => {
        const indexOfUpdatedEntity = state.metadataFields.findIndex(
          (m) => m.id === payload.id
        );
        if (indexOfUpdatedEntity >= 0) {
          state.metadataFields[indexOfUpdatedEntity] = payload;
        }
        toastIlapInfo("Updated Field successfully.");
      }
    );
    builder.addCase(
      createMetadataFieldAsync.fulfilled,
      (state, { payload }: { payload: MetadataField }) => {
        state.metadataFields.push(payload);
        toastIlapInfo("Created Field successfully.");
      }
    );
    builder.addCase(
      createMetadataFieldsAsync.fulfilled,
      (state, { payload }: { payload: MetadataField[] }) => {
        payload.forEach(field => state.metadataFields.push(field));
        toastIlapInfo(`Created ${payload?.length ?? 0} Field(s) successfully.`);
      }
    );
    builder.addCase(deleteMetadataFieldAsync.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const entitiesWithoutDeletedOne = state.metadataFields.filter(
        (mf) => mf.id !== id
      );
      state.metadataFields = entitiesWithoutDeletedOne;
      toastIlapInfo("Deleted Field successfully.");
    });
  }
});

export default metadataSlice.reducer;

import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { IlapMultilineTextEdit, IlapSelectBox, IlapTextBox, LabelWithContent } from "ilap.common.webcomponents.test";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPointsInTimeTypeValuesAsync } from "store/action/DropdownValueActions";
import { loadReportScheduleTypesAsync } from "store/action/ReportScheduleTypeActions";
import { AppDispatch, RootState } from "store/store";

interface Props {
  title?: string;
  internalId?: string;
  reportScheduleType?: number;
  pointInTimeType?: number;
  onValueChange: (field: string, value: string | number) => void;
  onScheduleTypeChange: (scheduleTypeId: number) => void;
  isValidationEnabled: boolean;
}

export default function ReportScheduleGeneralInfoForm(props: Props){
  const dispatch = useDispatch<AppDispatch>();

  const reportScheduleTypes = useSelector((rootStore: RootState) => rootStore.reportScheduleTypeData.reportScheduleTypes);
  const pointsInTimeTypes = useSelector((rootStore: RootState) => rootStore.dropdownData.pointsInTimeType);

  useEffect(() => {
    if (reportScheduleTypes.length === 0) {
      displayLoadingPanel();
      dispatch(loadReportScheduleTypesAsync())
        .finally(hideLoadingPanel);
    }

    if (pointsInTimeTypes.length === 0) {
      displayLoadingPanel();
      dispatch(getPointsInTimeTypeValuesAsync())
        .finally(hideLoadingPanel);
    }
  }, [dispatch, reportScheduleTypes.length, pointsInTimeTypes.length]);

  const isTitleMissing = useMemo(() => props.isValidationEnabled && !props.title?.trimStart(), [props.isValidationEnabled, props.title]);
  const isInternalIdMissing = useMemo(() => props.isValidationEnabled && !props.internalId?.trimStart(), [props.isValidationEnabled, props.internalId]);
  const isScheduleTypeMissing = useMemo(() => props.isValidationEnabled && !props.reportScheduleType, [props.isValidationEnabled, props.reportScheduleType]);
  const isPointsInTimeTypeMissing = useMemo(() => props.isValidationEnabled && !props.pointInTimeType, [props.isValidationEnabled, props.pointInTimeType]);

  return (
    <div className="report-schedule-create-modal-content grid grid-cols-2 gap-x-9" >
    <div className="grid-cols-1 space-y-2"  >
      <LabelWithContent
        label="Title"
        content={
          <IlapTextBox
            placeholder="Type..."
            showValidationMessage={isTitleMissing}
            value={props.title}
            onChange={({ event }: any) => props.onValueChange("title", event.currentTarget.value)}
          />
        }
      />
      <LabelWithContent
        label="Internal ID"
        content={
          <IlapTextBox
            placeholder="Type..."
            showValidationMessage={isInternalIdMissing}
            value={props.internalId}
            onChange={({ event }: any) => props.onValueChange("internalId", event.currentTarget.value)}
          />
        }
      />
      <LabelWithContent
        label="Schedule type"
        content={
          <IlapSelectBox
            placeholder="Select"
            items={reportScheduleTypes}
            value={props.reportScheduleType}
            displayExpr={"title"}
            valueExpr={"id"}
            showValidationMessage={isScheduleTypeMissing}
            onValueChange={(id: number) => { props.onScheduleTypeChange(id) }}
          />
        }
      />
      <LabelWithContent
        label="Points in time type"
        content={
          <IlapSelectBox
            placeholder="Select"
            showValidationMessage={isPointsInTimeTypeMissing}
            displayExpr={"name"}
            valueExpr={"value"}
            items={pointsInTimeTypes}
            value={props.pointInTimeType}
            onValueChange={(value: number) => props.onValueChange("pointsInTimeType", value) }
          />
        }
      />
      <LabelWithContent
        label="Description"
        isOptional={true}
        content={
          <IlapMultilineTextEdit
            placeholder="Type..."
            className="w-full h-30"
            onValueChanged={({ event }: any) => props.onValueChange("description", event.currentTarget.value)}
          />
        }
      />
    </div>
  </div>
  )
}
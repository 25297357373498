import { createSlice } from "@reduxjs/toolkit";
import {
  loadReportSchedulesAsync,
  loadReportScheduleWithSchedulesAsync,
  createReportSchedule,
  updateReportSchedule,
  deleteReportSchedule,
  changeReportScheduleRevisionTypeAsync,
  deleteScheduleAsync,
  updateReportScheduleStatus,
} from "store/action/ReportScheduleActions";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { toastIlapInfo, toastSuccess } from "shared/utilities/ToastUtility";

export interface ReportScheduleState {
  reportSchedules: ReportScheduleResponse[];
  reportSchedulesWithMetadataFieldValues: ReportScheduleResponse[];
  selectedReportScheduleWithSchedules: null | ReportScheduleResponse;
}

const initialState: ReportScheduleState = {
  reportSchedules: [],
  reportSchedulesWithMetadataFieldValues: [],
  selectedReportScheduleWithSchedules: null,
};

const updateReportScheduleInState = (
  reportScheduleToBeUpdatedId: number,
  updatedReportSchedule: ReportScheduleResponse,
  state: ReportScheduleState
) => {
  const existingReportScheduleIndex = state.reportSchedules.findIndex(
    (reportSchedule: ReportScheduleResponse) =>
      reportSchedule.id === reportScheduleToBeUpdatedId
  );
  if (existingReportScheduleIndex > -1) {
    state.reportSchedules[existingReportScheduleIndex] = updatedReportSchedule;
  }
};

const updateReportScheduleStatusInState = (
  reportScheduleToBeUpdatedId: number,
  updatedReportSchedule: ReportScheduleResponse,
  state: ReportScheduleState
) => {
  const existingReportScheduleIndex = state.reportSchedules.findIndex(
    (reportSchedule: ReportScheduleResponse) =>
      reportSchedule.id === reportScheduleToBeUpdatedId
  );
  if (existingReportScheduleIndex > -1) {
    state.reportSchedules[existingReportScheduleIndex].status = updatedReportSchedule.status;
  }
};

const deleteReportScheduleFromState = (
  reportScheduleId: number,
  state: ReportScheduleState
) => {
  const existingReportScheduleIndex = state.reportSchedules.findIndex(
    (reportSchedule: ReportScheduleResponse) =>
      reportSchedule.id === reportScheduleId
  );
  if (existingReportScheduleIndex > -1) {
    state.reportSchedules.splice(existingReportScheduleIndex, 1);
  }
};

export const reportScheduleSlice = createSlice({
  name: "reportSchedules",
  initialState,
  reducers: {
    resetSelectedReportSchduleWithSchedules: (state) => {
      state.selectedReportScheduleWithSchedules = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReportSchedulesAsync.fulfilled, (state, action) => {
      state.reportSchedules = action.payload;
    });
    builder.addCase(
      loadReportScheduleWithSchedulesAsync.fulfilled,
      (state, action) => {
        state.selectedReportScheduleWithSchedules = action.payload;
      }
    );
    builder.addCase(createReportSchedule.fulfilled, (state, action) => {
      state.reportSchedules.unshift(action.payload);
      toastSuccess("Created Report schedule successfully.");
    });
    builder.addCase(updateReportSchedule.fulfilled, (state, action) => {
      updateReportScheduleInState(action.payload.id, action.payload, state);
      toastSuccess("Updated Report schedule successfully.");
    });
    builder.addCase(updateReportScheduleStatus.fulfilled, (state, action) => {
        updateReportScheduleStatusInState(action.payload.id, action.payload, state);
      toastIlapInfo("Updated Report schedule status successfully.");
    });
    builder.addCase(deleteReportSchedule.fulfilled, (state, action) => {
      deleteReportScheduleFromState(action.meta.arg.reportScheduleId, state);
      toastSuccess("Deleted Report schedule successfully.");
    });

    builder.addCase(
      changeReportScheduleRevisionTypeAsync.fulfilled,
      (state, action) => {
        state.selectedReportScheduleWithSchedules = action.payload;
      }
    );
    builder.addCase(deleteScheduleAsync.fulfilled, (state, action) => {
      const schedulesWithoutDeletedOne =
        state.selectedReportScheduleWithSchedules?.schedules.filter(
          (sc) => sc.id !== action.meta.arg
        );
      if (
        schedulesWithoutDeletedOne &&
        state.selectedReportScheduleWithSchedules
      ) {
        state.selectedReportScheduleWithSchedules.schedules =
          schedulesWithoutDeletedOne;
      }
    });
  },
});

export default reportScheduleSlice.reducer;
export const { resetSelectedReportSchduleWithSchedules } =
  reportScheduleSlice.actions;

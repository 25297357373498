import { ProtectedApiConnector, ProtectedGraphQLApiConnector } from "shared/connector/ApiConnector";
import { toastIlapInfo } from "shared/utilities/ToastUtility";

const tryMakingRequestAsync = async <X>(
  fn: () => Promise<X>,
  displayError: boolean = true,
  errorPrefix: string = ""
) => {
  try {
    return await fn?.();
  } catch (error: any) {
    if (displayError) {
      const errorMessage = error.validationErrorDisplay
      ? error.validationErrorDisplay
      : error.message

      toastIlapInfo(`${errorPrefix}${errorMessage}`);
    }
    throw error;
  }
};

export const getDataAsync = async <T = any>(
  routeName: string,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.get(routeName);
    return response.data as T;
  }, displayError);
};

export const postDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.post(routeName, data);
    return response.data as T;
  }, displayError);
};

export const deleteDataAsync = async <T>(
  routeName: string,
  displayError: boolean = true,
  errorPrefix: string = ""
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.delete(routeName);
    return response.data as T;
  }, displayError, errorPrefix);
};

export const putDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true,
  errorPrefix: string = ""
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.put(routeName, data);
    return response.data as T;
  }, displayError, errorPrefix);
};

export const patchDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.patch(routeName, data);
    return response.data as T;
  }, displayError);
};

export const queryGraphQLAsync = async <P, T>(
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedGraphQLApiConnector.post("", data);
    return response.data as T;
  }, displayError);
};
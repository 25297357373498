import { Column } from "devextreme-react/data-grid";
import { IlapViewGrid } from "ilap.common.webcomponents.test";
import MetadataField from "interfaces/response/MetadataField";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getCellValueOrDefault } from "shared/utilities/DataGridUtility";

interface Props {
  data?: MetadataField[];
}

export default function FieldsGrid(props: Props) {
  let navigate = useNavigate();

  const navigateToViewPage = (id: number) => {
    navigate(`/fields/${id}`);
  }
  
  const handleRowClick = useCallback((data: any) => {
    return navigateToViewPage(data.data.id);
  }, []);

  return (
    <IlapViewGrid dataSource={props.data} className="h-[calc(100vh-208px)]" columnMinWidth={100}
      onRowClick={handleRowClick}
    >
      <Column caption={"Field ID"} dataField={"id"} width={160} alignment="left" />
      <Column caption={"Title"} dataField={"name"} />
      <Column caption={"Description"} dataField={"description"} width={"30%"} />
      <Column caption={"ILAP ID"} dataField={"ilapId"} width={320} />
      <Column caption={"Date created"} dataField={"created"} width={140} dataType={"datetime"} customizeText={getCellValueOrDefault} />
      <Column caption={"Date updated"} dataField={"lastModified"} width={140} dataType={"datetime"} customizeText={getCellValueOrDefault} />
      <Column caption={"Updated by"} dataField={"lastModifierName"} width={200} />
    </IlapViewGrid>
  );
}
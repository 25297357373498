import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import { Type } from "shared/enums/TypeEnum";
import { getNewId } from "shared/utilities/CommonUtility";
import { RootState } from "store/store";

interface MetadataFieldVaueUISliceState {
  userInputText: string;
  validationErrors: string[];
  validMetadataFieldValues: MetadataFieldValue[];
  bulkEditorMode: "edit" | "preview";
}

const initialState: MetadataFieldVaueUISliceState = {
  userInputText: "",
  validationErrors: [],
  validMetadataFieldValues: [],
  bulkEditorMode: "edit",
};

export const validateAndPrepareBulkMetadataFieldValues = createAsyncThunk(
  "metadataFieldVaueUISlice/validateAndPrepareBulkMetadataFieldValues",
  (metadataFieldId: number, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    let errors: string[] = [];
    const fieldValues: MetadataFieldValue[] = [];
    let inputWithLines =
      state.metadataFieldValueUIData.userInputText.split("\n");

    const addError = (error: string) => errors.push(error);

    inputWithLines.forEach((input: string, ind: number) => {
      let splitedInput = input.trim().split(";");
      splitedInput = splitedInput.map((i) => i.trim());

      let index = ind + 1;
      if (splitedInput.length > 0) {
        if (splitedInput.length === 1) {
          if (!splitedInput[0]) {
            addError(`Invalid input at line ${index}: ${input} 
            Value must be provided.`);
          } else {
            addError(`Invalid input at line ${index}: ${input} 
            Each line should contains a value and optionally a description separated by ;(semicolon)`);
          }
        } else if (splitedInput.length === 2) {
          if (!splitedInput[0]) {
            addError(
              `Invalid input at line ${index}: ${input} 
              Value must be provided.`
            );
          } else {
            fieldValues.push({
              __KEY__: getNewId(),
              stringValue: splitedInput[0],
              description: splitedInput[1],
              metadataFieldId: metadataFieldId,
              type: Type.String,
            });
          }
        } else {
          addError(
            `Invalid input at line ${index}: ${input} 
            Each line should contains a value and optionally a description separated by ;(semicolon)`
          );
        }
      }
    });

    return { errors, fieldValues };
  }
);

export const metadataFieldVaueUISlice = createSlice({
  name: "metadataFieldVaueUISlice",
  initialState,
  reducers: {
    resetMetadataFieldValueUiState: () => initialState,
    setUserInput: (state, { payload }: { payload: string }) => {
      state.userInputText = payload;
    },
    setBulkEditorMode: (
      state,
      { payload }: { payload: "edit" | "preview" }
    ) => {
      state.bulkEditorMode = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      validateAndPrepareBulkMetadataFieldValues.fulfilled,
      (state, action) => {
        state.validMetadataFieldValues = action.payload.fieldValues;
        state.validationErrors = action.payload.errors;
      }
    );
  },
});

export default metadataFieldVaueUISlice.reducer;
export const {
  resetMetadataFieldValueUiState,
  setUserInput,
  setBulkEditorMode,
} = metadataFieldVaueUISlice.actions;

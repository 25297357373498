import { FieldValueInput } from "interfaces/common/FieldValueInput"
import { getNewId } from "./CommonUtility"
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import { Type } from "shared/enums/TypeEnum";

export const FieldValueUtility = {
  createEmptyFieldValue: (fieldId?: number): FieldValueInput => {
    return {
      __KEY__: getNewId(),
      __ERROR__: "",
      id: fieldId ?? 0,
      code: "",
      description: ""
    }
  },
  convertFieldValuesToBulkInputString: (values: FieldValueInput[]): string => {
    return values.map(value => `${value.code ?? ""};${value.description ?? ""}\n`).join("").slice(0, -1);
  },
  convertStringToFieldValue: (value: string): FieldValueInput => {
    const [code, description] = value.split(";");
    return {
      __KEY__: getNewId(),
      __ERROR__: "",
      id: 0,
      code: code.trim(),
      description: description.trim()
    }
  },
  convertInputStringsToFieldValueInputs: (inputLines: string[]): FieldValueInput[] => {
    return inputLines.map(input => FieldValueUtility.convertStringToFieldValue(input));
  },
  convertMetadataFieldValueToFieldValueInput: (value: MetadataFieldValue): FieldValueInput => {
    return {
      __KEY__: getNewId(),
      __ERROR__: "",
      id: value.id ?? 0,
      code: value.stringValue,
      description: value.description
    }
  },
  convertMetadataFieldValuesToFieldValueInputs: (values: MetadataFieldValue[]): FieldValueInput[] => {
    return values.map(value => FieldValueUtility.convertMetadataFieldValueToFieldValueInput(value));
  },
  convertFieldValueInputToMetadataFieldValue: (fieldId: number, fieldValue: FieldValueInput): MetadataFieldValue => {
    return {
      metadataFieldId: fieldId,
      id: fieldValue.id,      
      stringValue: fieldValue.code,
      description: fieldValue.description,
      type: Type.String
    }
  },
  convertFieldValueInputsToMetadataFieldValues: (fieldId: number, fieldValues: FieldValueInput[]): MetadataFieldValue[] => {
    return fieldValues.map(fv => FieldValueUtility.convertFieldValueInputToMetadataFieldValue(fieldId, fv));
  },
  mergeFieldValueInputs: (existingItems: MetadataFieldValue[], newItems: FieldValueInput[]): FieldValueInput[] => {
    return newItems.map(item => {
      const existingItem = existingItems.find(x => x.id && x.stringValue === item.code); // if an item exist with the same code
      return existingItem ? {...item, id: existingItem.id ?? 0} : {...item, id: 0}; // set the id to 0 if doesn't match with existing (anymore)
    });
  }
}
import { IlapToggleButtonState } from "components/common/buttons/IlapToggleButton";
import { Popup } from "devextreme-react";
import ErrorDisplayModal from "features/common/ErrorDisplayModal";
import FieldValueEditorForm, { FieldValueEditorFormType } from "features/metadata/common/FieldValueEditorForm";
import { FieldValueValidationResult } from "features/metadata/common/interface/FieldValueValidationResult";
import { BulkInputUtility } from "features/metadata/common/utility/BulkInputUtility";
import { FieldValidationUtility } from "features/metadata/common/utility/FieldValidationUtility";
import { IlapButton, IlapButtonType, IlapPopup } from "ilap.common.webcomponents.test";
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import _ from "lodash";
import { useRef, useState } from "react";
import { FieldValueUtility } from "shared/utilities/FieldValueUtility";

interface Props {
  items: FieldValueInput[],
  onSubmit: (items: FieldValueInput[]) => void;
  onHideModal: () => void;
}

const EditorDescription = {
  TableEditor: "Define a list of valid values to enable selection from a list for this field. Add values with the + button and populate the value code and description text boxes or switch to textfield mode. Click submit to keep the changes.",
  TextFieldEditor: "Type or paste your valid values in the textfield. Each line contains a value and a description, separated by a semicolon. Values and descriptions must not contain internal semicolons."
}

export default function FieldValueEditorModal(props: Props) {
  const [editorToggleState, setEditorToggleState] = useState<IlapToggleButtonState>(IlapToggleButtonState.Left);
  const [editorDescription, setEditorDescription] = useState<string>(EditorDescription.TableEditor);

  const [fieldValues, setFieldValues] = useState<FieldValueInput[]>(_.cloneDeep(props.items));
  const [bulkInput, setBulkInput] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [isEditorFormVisible, setIsEditorFormVisible] = useState<boolean>(false); // show the editor only when popup is ready (to get correct grid height)
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

  const popupRef = useRef<Popup>(null);
  const fieldValueEditorRef = useRef<FieldValueEditorFormType>(null);

  const validateAndSaveSanitizedFieldValues = (): FieldValueValidationResult => {
    const result = FieldValidationUtility.validateFieldValues(fieldValues);

    setFieldValues([...result.data]); // we save sanitized field values here with or without errors

    if (!result.isValidationSuccessful) {
      setValidationErrors(result.errors);
      setIsErrorModalVisible(true);
    }

    return result;
  }

  const getValidationResult = (): FieldValueValidationResult => {
    if (editorToggleState === IlapToggleButtonState.Right) { // Bulk Editor
      return BulkInputUtility.validateBulkInput(bulkInput);
    }
    else { // Table Editor
      fieldValueEditorRef.current?.saveTableData(); // save unsaved input before validation
      return validateAndSaveSanitizedFieldValues();
    }
  }

  const handleSubmit = () => {
    const result = getValidationResult();

    if (!result.isValidationSuccessful){
      setValidationErrors(result.errors);
      setIsErrorModalVisible(true);
      return;
    }

    props.onSubmit(result.data);
    props.onHideModal();
  }

  const handlePopupReady = () => {
    setIsEditorFormVisible(true);
  }

  const savedTableInputAsBulkInput = () => {
    const bulkInput = FieldValueUtility.convertFieldValuesToBulkInputString(fieldValues);
    setBulkInput(bulkInput);
  }

  const handleToggleChange = (newToggleState: IlapToggleButtonState) => {
    if (editorToggleState === newToggleState) {
      return;
    }

    if (editorToggleState === IlapToggleButtonState.Right) { // user trying to switch from Bulk Editor to Table Editor
      const validationStatus = validateAndSaveBulkInput();

      if (!validationStatus) {
        return; // stay in Bulk Editor if errors are found
      }
    }

    else if (editorToggleState === IlapToggleButtonState.Left) { // user trying to switch from Table Editor to Bulk Editor
      savedTableInputAsBulkInput();
    }

    setEditorDescription(newToggleState === IlapToggleButtonState.Left ? EditorDescription.TableEditor : EditorDescription.TextFieldEditor);
    setEditorToggleState(newToggleState);
  }

  const handleAddNewFieldValue = () => {
    setFieldValues(prev => [FieldValueUtility.createEmptyFieldValue(), ...prev]);
  }

  const handleRemoveFieldValues = (values: FieldValueInput[]) => {
    setFieldValues(prev => prev.filter(fieldValue => !values.some(v => v.__KEY__ === fieldValue.__KEY__)));
  }

  const handleBulkValueUpdate = (bulkInput: string) => {
    setBulkInput(bulkInput);
    setValidationErrors([]); // reset errors
  }

  const validateAndSaveBulkInput = (): boolean => {
    const result = BulkInputUtility.validateBulkInput(bulkInput);

    if (result.isValidationSuccessful) {
      setFieldValues([...result.data]);
      return true;
    }

    setValidationErrors(result.errors);
    setIsErrorModalVisible(true);
    return false;
  }

  return (
    <IlapPopup
      ref={popupRef}
      width={"728px"}
      height={"92vh"}
      minHeight={"720px"}
      maxHeight={"1000px"}
      container="html"
      onShown={handlePopupReady}>
      <div className="flex flex-col justify-between h-full">
        <header>
          <div className="mb-5 flex items-center justify-between">
            <div className="flex items-center">
              <div className="font-poppins text-dark-blue-1 text-[18px] font-semibold leading-normal">Add values</div>
            </div>
          </div>
          <hr className="mt-4 mb-4 border-light-gray" />
        </header>

        {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={() => setIsErrorModalVisible(false)} />}

        {isEditorFormVisible &&
          <FieldValueEditorForm
            ref={fieldValueEditorRef}

            description={editorDescription}

            parentRef={popupRef}
            toggleState={editorToggleState}
            onToggleChange={handleToggleChange}

            // Table Editor Props
            dataSource={fieldValues}
            onAddNewItem={handleAddNewFieldValue}
            onRemoveItems={handleRemoveFieldValues}

            // Bulk Editor Props
            bulkInput={bulkInput}
            onBulkInputChange={handleBulkValueUpdate}
            onValidateBulkInput={validateAndSaveBulkInput}
            highlightBulkInputEditor={validationErrors.length > 0}
          />}

        <footer className="flex flex-col justify-end">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={props.onHideModal}>Cancel</IlapButton>
            <IlapButton className="px-4 font-medium" onClick={handleSubmit}>Submit</IlapButton>
          </div>
        </footer>
      </div>
    </IlapPopup>
  )
}
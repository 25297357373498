import InfoWidget from "components/common/InfoWidget";
import PageTitle from "components/common/PageTitle";
import TextWithCount from "components/common/TextWithCount";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { AddNewButton, IlapButton, IlapButtonType, IlapSelectBox, IlapTabPanel, IlapTextBox, IlapViewGrid } from "ilap.common.webcomponents.test";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useBlocker } from "react-router-dom";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import "./styles/ScheduleTypeEdit.css";
import { displayLoadingPanel, displayGridLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { useDispatch, useSelector } from "react-redux";
import { getPlanningLevelValuesAsync, getValidationRequirementValuesAsync } from "store/action/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import { SCHEDULE_TYPES } from "shared/constants/RoutePathConstants";
import { unwrapResult } from "@reduxjs/toolkit";
import { toastIlapInfo } from "shared/utilities/ToastUtility";
import { deleteReportScheduleType, loadSingleReportScheduleTypeAsync, updateReportScheduleType } from "store/action/ReportScheduleTypeActions";
import { ScheduleTypeUtility } from "shared/utilities/ScheduleTypeUtility";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import TrashCanButton from "components/common/buttons/TrashCanButton";
import { FieldState, FieldUtility } from "shared/utilities/FieldUtility";
import FieldSelectorModal from "./modal/FieldSelectorModal";
import { loadMetadataFieldsAsync } from "store/action/MetadataActions";
import ValidatedContent from "components/common/ValidatedContent";
import ReportScheduleTypeRequest from "interfaces/request/ReportScheduleTypeRequest";
import { TextUtility } from "shared/utilities/TextUtility";
import NavigationBlocker from "features/common/NavigationBlocker";
import { FieldTypeEnum } from "shared/enums/FieldTypeEnum";
import { ReceivedFrom } from "shared/enums/ReceivedFromEnum";

enum Tab {
  schedule = 0,
  activity = 1
}

interface ReceivedFromOption {
  value: ReceivedFrom,
  name: string
}

export default function ScheduleTypeEdit() {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  let navigate = useNavigate();

  const [scheduleType, setScheduleType] = useState<ReportScheduleType>(ScheduleTypeUtility.getEmptyScheduleType());   // Initial Schedule Type
  const [initialTitle, setInitialTitle] = useState<string>(""); // title before modification, used for displaying in the Final confirmation

  const [scheduleFields, setScheduleFields] = useState<MetadataFieldInfo[]>([]);
  const [activityFields, setActivityFields] = useState<MetadataFieldInfo[]>([]);

  const [availableScheduleFields, setAvailableScheduleFields] = useState<MetadataFieldInfo[]>([]);
  const [availableActivityFields, setAvailableActivityFields] = useState<MetadataFieldInfo[]>([]);

  const [isModified, setIsModified] = useState(false); // indicates if Schedule Type is modified
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();

  const [isFieldSelectorModalVisible, setIsFieldSelectorModalVisible] = useState<boolean>(false);

  const [currentTabId, setCurrentTabId] = useState<number>(Tab.schedule);
  const [currentTab, setCurrentTab] = useState<string>(Tab[Tab.schedule]);
  const [isFieldsDeleteEnabled, setIsFieldsDeleteEnabled] = useState<boolean>(false);

  const [isTitleMissing, setIsTitleMissing] = useState<boolean>(false);
  const [isNavBlockerActive, setIsNavBlockerActive] = useState<boolean>(true);

  const scheduleFieldsRef = useRef<DataGrid>(null);
  const activityFieldsRef = useRef<DataGrid>(null);

  //#region Load Schedule Type by id
  useEffect(() => {
    if (params.id) {
      displayGridLoadingPanel();
      dispatch(loadSingleReportScheduleTypeAsync({ reportScheduleTypeId: Number(params.id) }))
        .then(unwrapResult)
        .then((scheduleType: ReportScheduleType) => {
          setScheduleType(scheduleType);
          setInitialTitle(scheduleType.title);
        })
        .catch(() => {
          toastIlapInfo("Failed to load schedule type.");
        })
        .finally(hideLoadingPanel);
    }
  }, [params?.id, dispatch]);

  //#endregion

  //#region Extract schedule and activity fields from the loaded Schedule Type
  useEffect(() => {
    if (!scheduleType.metadataFields.length) {
      return;
    }

    const activityFields: MetadataFieldInfo[] = [];
    const scheduleFields: MetadataFieldInfo[] = [];

    for (let index = 0; index < scheduleType.metadataFields?.length; index++) {
      const metadataField = scheduleType.metadataFields[index];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Activity) {
        activityFields.push(FieldUtility.convertToMetadataFieldInfo(metadataField, FieldState.Saved));
      }
      else if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
        scheduleFields.push(FieldUtility.convertToMetadataFieldInfo(metadataField, FieldState.Saved));
      }
    }

    setActivityFields(activityFields);
    setScheduleFields(scheduleFields);
  }, [scheduleType.metadataFields.length]);
  //#endregion

  //#region Populate Schedule Levels
  const planningLevels = useSelector((rootStore: RootState) => rootStore.dropdownData.planningLevel);

  useEffect(() => {
    if (planningLevels.length === 0) {
      displayGridLoadingPanel();
      dispatch(getPlanningLevelValuesAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, planningLevels.length]);
  //#endregion

  //#region Populate Content Control Values
  const contentControlValues = useSelector(
    (rootStore: RootState) => rootStore.dropdownData.validationRequirement
  );

  useEffect(() => {
    if (contentControlValues.length === 0) {
      displayGridLoadingPanel();
      dispatch(getValidationRequirementValuesAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, contentControlValues.length]);
  //#endregion

  //#region Populate all fields
  // Load all metadata fields
  const allFields = useSelector(
    (rootStore: RootState) => rootStore.metadataFieldData.metadataFields
  );

  // If store does not contain metadata fields, call API to get all metadata fields
  useEffect(() => {
    if (allFields.length === 0) {
      displayGridLoadingPanel();
      dispatch(loadMetadataFieldsAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, allFields.length]);
  //#endregion

  //#region Get all available fields (All_Fields ∩ Fields_Added_in_ScheduleType)
  useEffect(() => {
    if (!allFields.length) { // return if fields are not loaded
      return;
    }

    const availableScheduleFields: MetadataFieldInfo[] = [];
    const availableActivityFields: MetadataFieldInfo[] = [];

    // Put fields into 2 separate buckets based on type
    for (let index = 0; index < allFields.length; index++) {
      const field = allFields[index];

      // Fields already part of the schedule type should be excluded
      if (scheduleType.metadataFields.some(f => f.id === field.id)) {
        continue;
      }

      if (field.planningObjectTypes === PlanningObjectTypes.Schedule) {
        availableScheduleFields.push(FieldUtility.convertToMetadataFieldInfo(field));
      }
      else if (field.planningObjectTypes === PlanningObjectTypes.Activity) {
        availableActivityFields.push(FieldUtility.convertToMetadataFieldInfo(field))
      }
    }

    setAvailableScheduleFields(availableScheduleFields);
    setAvailableActivityFields(availableActivityFields);

  }, [scheduleType.metadataFields.length, allFields.length]);
  //#endregion

  //#region Navigation
  const deactivateNavigationBlocker = () => setIsNavBlockerActive(false);

  const navigateToViewPage = () => {
    deactivateNavigationBlocker();
    navigate(`${SCHEDULE_TYPES}/${params.id}`);
  }
  const navigateToGridPage = () => {
    deactivateNavigationBlocker();
    navigate(`${SCHEDULE_TYPES}`);
  }
  //#endregion

  //#region Show/Hide Dialogs
  const showConfirmationDialog = () => setIsConfirmationDialogVisible(true);
  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const showViewFieldSelectorModal = () => setIsFieldSelectorModalVisible(true);
  const hideFieldSelectorModal = () => setIsFieldSelectorModalVisible(false);
  //#endregion

  //#region Modification Operations (All Operations that sets IsModified = True)

  const handleValueChange = (fieldName: string, value: string | number) => {
    setScheduleType(prev => {
      return { ...prev, [fieldName]: value }
    });

    if (fieldName === "title" && value) {
      setIsTitleMissing(false);
    }

    setIsModified(true);
  }

  const handleContentControlChange = (modifiedField: MetadataFieldInfo, value: number) => {
    modifiedField.validationRequirement = value;

    if (currentTabId === Tab.schedule) {
      setScheduleFields(prev => {
        const index = prev.findIndex(field => field.id == modifiedField.id);
        const modifiedFields = [...prev];
        modifiedFields[index] = modifiedField;
        return modifiedFields;
      });
    }

    else if (currentTabId === Tab.activity) {
      setActivityFields(prev => {
        const index = prev.findIndex(field => field.id == modifiedField.id);
        const modifiedFields = [...prev];
        modifiedFields[index] = modifiedField;
        return modifiedFields;
      });
    }

    setIsModified(true);
  }

  const handleReceivedFromIlapScheduleChange = (modifiedField: MetadataFieldInfo, value: ReceivedFrom) => {
    modifiedField.receivedFromSchedule = value === ReceivedFrom.IlapSchedule ? true : false;

    setScheduleFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index] = modifiedField;
      return modifiedFields;
    });

    setIsModified(true);
  }

  const handleDeleteFields = () => {
    deleteSelectedScheduleFields();
    deleteSelectedActivityFields();
    setIsModified(true);
  }

  const handleScheduleFieldsSubmit = (selectedFields: MetadataFieldInfo[], availebleFields: MetadataFieldInfo[]) => {
    setScheduleFields([...selectedFields]);
    setAvailableScheduleFields([...availebleFields]);
    hideFieldSelectorModal();
    setIsModified(true);
  }

  const handleActivityFieldsSubmit = (selectedFields: MetadataFieldInfo[], availebleFields: MetadataFieldInfo[]) => {
    setActivityFields([...selectedFields]);
    setAvailableActivityFields([...availebleFields]);
    hideFieldSelectorModal();
    setIsModified(true);
  }

  //#region Delete Fields Operations
  const deleteSelectedScheduleFields = () => {
    const selectedFieldsToRemove = scheduleFieldsRef.current?.instance.getSelectedRowsData() as MetadataFieldInfo[];

    if (!selectedFieldsToRemove) {
      return;
    }

    // Remove from 'selected fields'
    setScheduleFields(prev => prev.filter(field => !selectedFieldsToRemove.some(f => f.id == field.id)));

    // Put them back to 'available fields'
    setAvailableScheduleFields(prev => [...prev, ...selectedFieldsToRemove]);
  }

  const deleteSelectedActivityFields = () => {
    const selectedFieldsToRemove = activityFieldsRef.current?.instance.getSelectedRowsData() as MetadataFieldInfo[];

    if (!selectedFieldsToRemove) {
      return;
    }

    // Remove from 'selected fields'
    setActivityFields(prev => prev.filter(field => !selectedFieldsToRemove.some(f => f.id == field.id)));

    // Put them back to 'available fields'
    setAvailableActivityFields(prev => [...prev, ...selectedFieldsToRemove]);
  }
  //#endregion

  //#endregion

  //#region API Calls for Schedule Type Modifications
  const handleUpdateScheduleType = () => {
    const reportScheduleTypeRequest = getScheduleTypeRequestModel();

    displayLoadingPanel();
    dispatch(
      updateReportScheduleType({
        reportScheduleTypeId: reportScheduleTypeRequest.id,
        reportScheduleTypeRequest: reportScheduleTypeRequest,
      })
    )
      .then(unwrapResult)
      .then(navigateToViewPage)
      .finally(hideLoadingPanel);
  }

  const handleDeleteScheduleType = () => {
    displayLoadingPanel();
    dispatch(
      deleteReportScheduleType({ reportScheduleTypeId: scheduleType.id })
    )
      .then(response => {
        if (response.meta.requestStatus === "fulfilled") {
          navigateToGridPage();
        }
      })
      .finally(hideLoadingPanel)
      .finally(hideConfirmationDialog);
  }

  //#region Utility
  const getScheduleTypeRequestModel = (): ReportScheduleTypeRequest => {
    const scheduleFieldRequestModels = FieldUtility.convertFieldsToScheduleTypeFieldRequestModels(scheduleFields, scheduleType.id);
    const activityFieldRequestModels = FieldUtility.convertFieldsToScheduleTypeFieldRequestModels(activityFields, scheduleType.id);

    return {
      id: scheduleType.id,
      title: scheduleType.title,
      description: scheduleType.description,
      planningLevel: scheduleType.planningLevel,
      reportScheduleTypeMetadataFields: [...scheduleFieldRequestModels, ...activityFieldRequestModels],
    };
  };
  //#endregion

  //#endregion

  //#region UI Interaction Handlers
  const handleTabChange = (tabId: number) => {
    setCurrentTabId(tabId);

    switch (tabId) {
      case Tab.schedule:
        setCurrentTab(Tab[Tab.schedule]);
        break;
      case Tab.activity:
        setCurrentTab(Tab[Tab.activity]);
        break;
    }
  }

  const handleRowSelectionChange = () => {
    // Enable Delete Fields Button (Trash Can) if any row is selected in the Grids 
    if (scheduleFieldsRef.current?.instance.getSelectedRowKeys().length || activityFieldsRef.current?.instance.getSelectedRowKeys().length) {
      setIsFieldsDeleteEnabled(true);
    }
    else {
      setIsFieldsDeleteEnabled(false);
    }
  }

  const handleCancelClick = () => {
    if (isModified) {
      setConfirmationDialogInfo({
        content: "Cancel changes",
        subContent: "The changes you’ve made to the schedule type will not be saved.",
        confirmButtonText: "Confirm",
        cancelButtonText: "Continue editing",
        onConfirm: navigateToViewPage,
        onCancel: hideConfirmationDialog
      });

      showConfirmationDialog();

      return;
    }

    navigateToViewPage();
  }

  const handleDeleteClick = () => {
    setConfirmationDialogInfo({
      content: "Delete schedule type?",
      subContent: "This action cannot be undone. You will permanently loose the schedule type and its content.",
      confirmButtonText: "Delete permanently",
      cancelButtonText: "Cancel",
      isDeleteConfirmation: true,
      onConfirm: handleDeleteScheduleType,
      onCancel: hideConfirmationDialog
    });

    showConfirmationDialog();
  }

  const handleSaveChanges = () => {
    if (isModified) {
      // validate required fields
      if (!scheduleType.title.length) {
        setIsTitleMissing(true);
        return;
      }

      setConfirmationDialogInfo({
        content: "Save changes",
        subContent: (
          <div title={initialTitle}>
            <span>{"You have made changes to "}</span>
            <span className="break-words">{`${TextUtility.truncate(initialTitle, 50)}.`}</span>
            <span>{" Confirm to save changes."}</span>
          </div>
        ),
        confirmButtonText: "Confirm change",
        cancelButtonText: "Continue editing",
        onConfirm: handleUpdateScheduleType,
        onCancel: hideConfirmationDialog
      });

      showConfirmationDialog();
    }
    else {
      toastIlapInfo("No changes made to be saved");
    }
  }
  //#endregion

  //#region Schedule Type Property Editors
  const titleEditor = useMemo(() => {
    return (
      <IlapTextBox
        placeholder="Type..."
        value={scheduleType.title}
        className="title"
        width={"100%"}
        validationStatus={isTitleMissing ? "invalid" : "valid"}
        onChange={({ event }: any) => handleValueChange("title", event.currentTarget.value)}
      />
    )
  }, [scheduleType.title, isTitleMissing]);

  const levelSelector = useMemo(() => {
    return (
      <IlapSelectBox
        placeholder="Select"
        items={planningLevels}
        value={scheduleType.planningLevel}
        displayExpr={"name"}
        valueExpr={"value"}
        className="editor"
        width={"160px"}
        onValueChange={(id: number) => handleValueChange("planningLevel", id)}
      />
    )
  }, [scheduleType.planningLevel, planningLevels.length]);

  const descriptionEditor = useMemo(() => {
    return (
      <IlapTextBox
        placeholder="Type..."
        value={scheduleType.description}
        className="editor"
        width={"100%"}
        onChange={({ event }: any) => handleValueChange("description", event.currentTarget.value)}
      />
    )
  }, [scheduleType.description]);

  const widgets = useMemo(() => {
    return [
      <InfoWidget label="Schedule level" data={levelSelector} />,
      <InfoWidget label="Description" data={descriptionEditor} width="100%" />,
    ]
  }, [scheduleType.planningLevel, scheduleType.description, planningLevels.length]);
  //#endregion

  //#region Tab Title components
  const scheduleFieldsTabTitleComponent = useCallback(() => {
    return <TextWithCount text="Schedule fields" count={scheduleFields.length} />
  }, [scheduleFields.length]);

  const activityFieldsTabTitleComponent = useCallback(() => {
    return <TextWithCount text="Activity fields" count={activityFields.length} />
  }, [activityFields.length]);
  //#endregion

  //#region Toolbar components 
  const addFieldsButtonComponent = useMemo(() => {
    return <AddNewButton text={`Add ${currentTab} fields`} onClick={showViewFieldSelectorModal} />;
  }, [currentTab]);

  const deleteFieldsButtonComponent = useMemo(() => {
    return <TrashCanButton disabled={!isFieldsDeleteEnabled} onClick={handleDeleteFields} />
  }, [isFieldsDeleteEnabled]);
  //#endregion

  //#region Grid Components
  const contentControlComponent = useCallback((data: any) => {
    const field = data.data.data as MetadataFieldInfo;

    return <div>
      <IlapSelectBox
        fieldName="Select"
        placeholder="Select"
        items={contentControlValues}
        value={field.validationRequirement}
        displayExpr={"name"}
        valueExpr={"value"}
        onValueChange={(value: number) => handleContentControlChange(field, value)}
      />
    </div>
  }, [currentTabId, contentControlValues.length]);

  //#endregion

  const receivedFromOptions : ReceivedFromOption[] = [
    {
      value: ReceivedFrom.Manual,
      name: "Manual"
    },
    {
      value: ReceivedFrom.IlapSchedule,
      name: "ILAP schedule"
    }
  ];

  const receivedFromComponent = useCallback((data: any) => {
    const field = data.data.data as MetadataFieldInfo;

    return <div>
      <IlapSelectBox
        defaultValue={ReceivedFrom.Manual}
        items={receivedFromOptions}
        value={field.receivedFromSchedule ? ReceivedFrom.IlapSchedule : ReceivedFrom.Manual}
        displayExpr={"name"}
        valueExpr={"value"}
        onValueChange={(value: ReceivedFrom) => handleReceivedFromIlapScheduleChange(field, value)} />
    </div>
  }, [receivedFromOptions.length]);

  const hasNavigationBlockerPreCondition = useCallback(() => {
    let shouldBlockNavigation = false;
    // We are calling the setState method to get the latest state of 'isNavBlockerActive', 
    // this can be stale due to React update cycle behavior
    setIsNavBlockerActive(prev => {
      shouldBlockNavigation = prev; // getting latest state
      return prev;
    });

    if (!shouldBlockNavigation){
      return false;
    }

    return isModified;

  }, [isNavBlockerActive, isModified]);

  return (
    <div className="schedule-type-edit mx-14">
      <NavigationBlocker preCondition={hasNavigationBlockerPreCondition} warningMessage="The changes you’ve made to the schedule type will not be saved." /> 
      <div className="mb-[13px] -mt-[3px] title">
        <ValidatedContent wrapperWidth="100%" showValidationMessage={isTitleMissing}>
          <PageTitle id={scheduleType.id} title={titleEditor} titleClass="w-full" />
        </ValidatedContent>
      </div>
      <div>
        <WidgetsWithSeparator widgets={widgets} />
      </div>
      <hr className="mt-[15px] mb-4 border-light-gray" />
      <div>
        {/* Toolbar */}
        <div className="float-right">
          <div className="flex items-center gap-2">
            {deleteFieldsButtonComponent}
            {addFieldsButtonComponent}
          </div>
        </div>
        <IlapTabPanel onSelectedIndexChange={handleTabChange}>
          <Item tabRender={scheduleFieldsTabTitleComponent}>
            <IlapViewGrid
              className="h-[calc(100vh-312px)]"
              dataSource={scheduleFields}
              ref={scheduleFieldsRef}
              onSelectionChanged={handleRowSelectionChange}
            >
              <Selection mode="multiple" showCheckBoxesMode="always" />
              <Column caption="Title" dataField="name" width={240} />
              <Column caption="Content control" cellComponent={contentControlComponent} width={327} />
              <Column caption="Received from" cellComponent={receivedFromComponent} width={327} />
              <Column /> {/* Empty column to show whitespace on rest of the grid */}
            </IlapViewGrid>
          </Item>
          <Item tabRender={activityFieldsTabTitleComponent}>
            <IlapViewGrid
              className="h-[calc(100vh-312px)]"
              dataSource={activityFields}
              ref={activityFieldsRef}
              onSelectionChanged={handleRowSelectionChange}
            >
              <Selection mode="multiple" showCheckBoxesMode="always" />
              <Column caption="Title" dataField="name" width={240} />
              <Column caption="Content control" cellComponent={contentControlComponent} width={327} />
              <Column /> {/* Empty column to show whitespace on rest of the grid */}
            </IlapViewGrid>
          </Item>
        </IlapTabPanel>
      </div>
      <div className="mb-4 mt-4 flex justify-between">
        <div>
          <IlapButton
            text="Delete schedule type"
            variant={IlapButtonType.Tertiary}
            onClick={handleDeleteClick}
          />
        </div>
        <div className="flex gap-3">
          <IlapButton
            text="Cancel"
            variant={IlapButtonType.Secondary}
            onClick={handleCancelClick}
          />
          <IlapButton
            text="Save changes"
            variant={IlapButtonType.Primary}
            onClick={handleSaveChanges}
          />
        </div>
      </div>

      {isFieldSelectorModalVisible && currentTabId === Tab.schedule &&
        <FieldSelectorModal
          fieldsName={FieldTypeEnum.schedule}
          availableFields={availableScheduleFields}
          selectedFields={scheduleFields}
          onSubmit={handleScheduleFieldsSubmit}
          onHideModal={hideFieldSelectorModal}
        />
      }

      {isFieldSelectorModalVisible && currentTabId === Tab.activity &&
        <FieldSelectorModal
          fieldsName={FieldTypeEnum.activity}
          availableFields={availableActivityFields}
          selectedFields={activityFields}
          onSubmit={handleActivityFieldsSubmit}
          onHideModal={hideFieldSelectorModal}
        />
      }

      {isConfirmationDialogVisible &&
        <IlapConfirmationDialog
          height="181px"
          width="456px"

          content={confirmationDialogInfo?.content}
          subContent={confirmationDialogInfo?.subContent}
          cancelButtonText={confirmationDialogInfo?.cancelButtonText}
          confirmButtonText={confirmationDialogInfo?.confirmButtonText}

          onConfirm={confirmationDialogInfo?.onConfirm}
          onCancel={confirmationDialogInfo?.onCancel}

          isDeleteConfirm={confirmationDialogInfo?.isDeleteConfirmation}
        />
      }
    </div>
  )
} 
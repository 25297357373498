import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popup";
import PromineoButton from "components/common/buttons/PromineoButton";
import { ControlVariant } from "shared/enums/ControlVariantEnum";
import { getCurrentAccountInfo, signOut } from "authentication/AuthenticationManager";
import { AvatarIcon } from "ilap.common.webcomponents.test";

interface Props {
  target: string;
  visible: boolean;
  onHiding: () => void;
}

export default function AccountPopover(props: Props) {
  const currentAccountInfo = getCurrentAccountInfo();

  const Content = () => {
    return (
      <div>
        <div className="flex ml-2 mt-2">
          <div className="flex-shrink-0 mr-3">
            <AvatarIcon color="black" />
          </div>
          <div className="truncate">
            <div className="text-sm font-medium">{currentAccountInfo?.name ?? ""}</div>
            <div className="text-xs font-normal">{currentAccountInfo?.username ?? ""}</div>
          </div>
        </div>
        <div className="mt-3 ml-2">
          <PromineoButton
            className="border-2"
            onClick={signOut}
            variant={ControlVariant.primary}
            height={30}
          >
            Sign out
          </PromineoButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <PromineoPopover width={264} height={130} {...props}>
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={100} y={10} />
        </Position>
        <Content />
      </PromineoPopover>
    </>
  );
}

import { IButtonOptions } from "devextreme-react/button";
import { ControlVariant } from "shared/enums/ControlVariantEnum";
import PromineoBasicButton from "./PromineoBasicButton";
import "./styles/PromineoButton.css";

interface Props {
  variant?: ControlVariant;
}

export default function PromineoButton(props: Props & IButtonOptions) {
  const { className, variant, ...rest } = props;
  let classNames = `promineo-button${className ? ` ${className}` : ""}`;

  if (!variant || variant === ControlVariant.primary) {
    classNames = `${classNames} primary-button`;
  }else if(variant === ControlVariant.secondary){
    classNames = `${classNames} secondary-button`;
  }

  return <PromineoBasicButton className={classNames} {...rest} />;
}

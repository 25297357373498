import { AccountInfo, BrowserCacheLocation, Configuration, SilentRequest } from "@azure/msal-browser";

import * as ConfigUtility from "../shared/utilities/ConfigUtility";

const authority = ConfigUtility.getAuthenticationAuthority();
const clientId = ConfigUtility.getAuthenticationClientId();
const scopes = ConfigUtility.getAuthenticationScopes();
const redirectUri = ConfigUtility.getAuthenticationRedirectUri();

const getKnownAuthorities = () => {
    const knownAuthorities: string[] = [];
    var authorityParts = authority.split("/");
    knownAuthorities.push(authorityParts[2]);

    return knownAuthorities;
}

export const msalConfig : Configuration = {
    auth: {
        clientId: clientId,
        authority: authority,
        knownAuthorities: getKnownAuthorities(),
        redirectUri: redirectUri,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true,
        secureCookies: true
    }
}

export const loginRequest = {
    scopes: scopes
}

export const getTokenRequest = (account: AccountInfo | null) => {
    return {
        account: account ?? undefined,
        scopes: scopes
    }
}
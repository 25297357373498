import { Button } from "devextreme-react";
import "./styles/CrossIconButton.css";

interface Props {
  onClick: () => void;
}

export default function CrossIconButton(props: Props){
  return (
    <Button icon="close" className="ilap-cross-icon border-none" onClick={props.onClick}/>
  )
}
import StepperProgressBar from "components/common/stepper/StepperProgressBar";
import StepperProgressItem from "components/common/stepper/StepperProgressItem";
import { IlapButton, IlapButtonType, IlapPopup } from "ilap.common.webcomponents.test";
import { useMemo, useRef, useState } from "react";
import FieldGeneralInfo from "../common/interface/FieldGeneralInfo";
import FieldGeneralInfoForm from "./forms/FieldGeneralInfoForm";
import { PropertyState } from "shared/enums/PropertyStateEnum";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import FieldValueEditorForm, { FieldValueEditorFormType } from "../common/FieldValueEditorForm";
import { Popup } from "devextreme-react";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import { FieldValueUtility } from "shared/utilities/FieldValueUtility";
import ErrorDisplayModal from "features/common/ErrorDisplayModal";
import { IlapToggleButtonState } from "components/common/buttons/IlapToggleButton";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import MetadataField from "interfaces/response/MetadataField";
import { createMetadataFieldAsync } from "store/action/MetadataActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import { Type } from "shared/enums/TypeEnum";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { useNavigate } from "react-router";
import { FIELDS } from "shared/constants/RoutePathConstants";
import { FieldValidationUtility } from "../common/utility/FieldValidationUtility";
import { BulkInputUtility } from "../common/utility/BulkInputUtility";
import { FieldValueValidationResult } from "../common/interface/FieldValueValidationResult";
import { IlapUtility } from "shared/utilities/IlapUtility";

interface Props {
  onHideDialog: () => void;
}

const totalSteps = 2; // [1, 2] -> total number of steps in the Field Creation Process

enum Steps {
  GENERAL_INFO = 1,
  ADD_VALUES
}

const emptyGeneralInfo: FieldGeneralInfo = {
  title: "",
  usedBy: PropertyState.Unset,
  ilapId: "",
  type: Type.String,
  description: null
}

const EditorDescription = {
  TableEditor: "Define a list of valid values to enable selection from a list for this field. Add values with the + button and populate the value code and description text boxes or switch to textfield mode. If this field is a simple text field, and doesn't require a list of value codes, simply click Create to complete the registration of the new field.",
  TextFieldEditor: "Type or paste your valid values in the textfield. Each line contains a value and a description, separated by a semicolon. Values and descriptions must not contain internal semicolons."
}

export default function FieldCreateModal(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(Steps.GENERAL_INFO);

  const [generalInfo, setGeneralInfo] = useState<FieldGeneralInfo>(emptyGeneralInfo);
  const [isInfoValidationEnabled, setIsInfoValidationEnabled] = useState<boolean>(false);

  const fieldValueEditorRef = useRef<FieldValueEditorFormType>(null);
  const [fieldValues, setFieldValues] = useState<FieldValueInput[]>([]);
  const [bulkInput, setBulkInput] = useState<string>("");
  const [editorToggleState, setEditorToggleState] = useState<IlapToggleButtonState>(IlapToggleButtonState.Left);
  const [editorDescription, setEditorDescription] = useState<string>(EditorDescription.TableEditor);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();

  const popupRef = useRef<Popup>(null);

  const handleGeneralInfoChange = (fieldName: string, value: string | number) => {
    setGeneralInfo(prev => {
      return { ...prev, [fieldName]: value };
    });
  }

  const validateGeneralInfo = () => {
    const { title, usedBy, ilapId } = generalInfo;

    if (!title.trim()) {
      return false;
    }

    if (usedBy === PropertyState.Unset) {
      return false;
    }

    const sanitizedIlapId = ilapId.trim();

    if (!sanitizedIlapId) {
      return false;
    }

    if (!IlapUtility.validateIlapId(sanitizedIlapId)){
      return false;
    }

    return true;
  }

  const handleAddNewFieldValue = () => {
    setFieldValues(prev => [FieldValueUtility.createEmptyFieldValue(), ...prev]);
  }

  const handleRemoveFieldValues = (values: FieldValueInput[]) => {
    setFieldValues(prev => prev.filter(fieldValue => !values.some(v => v.__KEY__ === fieldValue.__KEY__)));
  }

  const handleBulkValueUpdate = (bulkInput: string) => {
    setBulkInput(bulkInput);
    setValidationErrors([]); // reset errors
  }

  const validateBulkInput = (bulkInput: string): FieldValueValidationResult => {
    const inputLines = BulkInputUtility.getInputLines(bulkInput);

    const errors = BulkInputUtility.getInputErrors(inputLines);

    // we don't convert bulk input to FieldValue if errors are found
    const data = errors.length ? [] : FieldValueUtility.convertInputStringsToFieldValueInputs(inputLines);

    return {
      data: data,
      isValidationSuccessful: !errors.length,
      errors: errors
    }
  }

  const validateAndSaveBulkInput = (): boolean => {
    const result = validateBulkInput(bulkInput);

    if (result.isValidationSuccessful) {
      setFieldValues([...result.data]);
      return true;
    }

    setValidationErrors(result.errors);
    setIsErrorModalVisible(true);
    return false;
  }

  const savedTableInputAsBulkInput = () => {
    const bulkInput = FieldValueUtility.convertFieldValuesToBulkInputString(fieldValues);
    setBulkInput(bulkInput);
  }

  const handleToggleChange = (newToggleState: IlapToggleButtonState) => {
    if (editorToggleState === newToggleState) {
      return;
    }

    if (editorToggleState === IlapToggleButtonState.Right) { // user trying to switch from Bulk Editor to Table Editor
      const validationStatus = validateAndSaveBulkInput();

      if (!validationStatus) {
        return; // stay in Bulk Editor if errors are found
      }
    }

    else if (editorToggleState === IlapToggleButtonState.Left) { // user trying to switch from Table Editor to Bulk Editor
      savedTableInputAsBulkInput();
    }

    setEditorDescription(newToggleState === IlapToggleButtonState.Left ? EditorDescription.TableEditor : EditorDescription.TextFieldEditor);
    setEditorToggleState(newToggleState);
  }

  const progressItems: StepperProgressItem[] = [
    {
      text: "General information",
      icon: "1"
    },
    {
      text: "Adding values (String)",
      icon: "2"
    }
  ];

  const previousButtonText = useMemo(() => {
    return currentStep == Steps.GENERAL_INFO ? "Cancel" : "Back";
  }, [currentStep]);

  const nextButtonText = useMemo(() => {
    return currentStep == Steps.ADD_VALUES || generalInfo.type !== Type.String ? "Create" : "Next step";
  }, [currentStep, generalInfo.type]);

  const showConfirmationDialog = () => setIsConfirmationDialogVisible(true);
  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const handleCreateNewMetadataField = (metadataField: MetadataField) => {
    displayLoadingPanel();
    dispatch(createMetadataFieldAsync(metadataField))
      .then(unwrapResult)
      .then((savedField: MetadataField) => {
        navigate(`${FIELDS}/${savedField.id}`, { state: { data: savedField } })
      })
      .catch((error: any) => {
        let validationErrors = error?.validationErrorDisplay
          ? error?.validationErrorDisplay
          : error?.message;

        setValidationErrors(validationErrors.split("\n"));
        setIsErrorModalVisible(true);
      })
      .finally(hideLoadingPanel);
  };

  const convertFieldValueInputToMetadataFieldValue = (fieldValue: FieldValueInput): MetadataFieldValue => {
    return {
      metadataFieldId: 0,
      stringValue: fieldValue.code,
      description: fieldValue.description,
      type: Type.String
    }
  }

  const convertFieldValueInputsToMetadataFieldValues = (fieldValues: FieldValueInput[]): MetadataFieldValue[] => {
    return fieldValues.map(fv => convertFieldValueInputToMetadataFieldValue(fv));
  }

  const createMetadataFieldRequestModel = (fieldValues: FieldValueInput[]): MetadataField => {
    const fieldRequest = FieldUtility.createEmptyMetadataField();

    fieldRequest.name = generalInfo.title.trim();
    fieldRequest.description = generalInfo.description ? generalInfo.description.trim() : "";
    fieldRequest.planningObjectTypes = generalInfo.usedBy !== PropertyState.Unset ? generalInfo.usedBy : PlanningObjectTypes.None;
    fieldRequest.ilapId = generalInfo.ilapId.trim();
    fieldRequest.type = generalInfo.type;
    fieldRequest.metadataFieldValues = convertFieldValueInputsToMetadataFieldValues(fieldValues);

    return fieldRequest;
  }

  const getValidationResult = (): FieldValueValidationResult => {
    if (editorToggleState === IlapToggleButtonState.Right) { // Bulk Editor
      return validateBulkInput(bulkInput);
    }
    else { // Table Editor
      fieldValueEditorRef.current?.saveTableData(); // save unsaved input before validation
      return FieldValidationUtility.validateFieldValues(fieldValues);
    }
  }

  const isGeneralInfoModified = () => {
    return generalInfo.title.trim() || generalInfo.usedBy != PropertyState.Unset || generalInfo.ilapId.trim() || generalInfo.description?.trim();
  }

  const handlePreviousClick = () => {
    if (currentStep === Steps.GENERAL_INFO) {
      if (isGeneralInfoModified()){
        setConfirmationDialogInfo({
          content: "Cancel new field",
          subContent: "The values you have entered for the new field will not be saved.",
          confirmButtonText: "Confirm",
          cancelButtonText: "Continue editing",
          onConfirm: props.onHideDialog,
          onCancel: hideConfirmationDialog
        });
        showConfirmationDialog();
      }
      else{
        props.onHideDialog();
      }
    }
    else if (currentStep === Steps.ADD_VALUES) {
      setCurrentStep(Steps.GENERAL_INFO);
    }
  }

  const handleNextClick = () => {
    // step 1: General Information
    if (currentStep == Steps.GENERAL_INFO) {
      // validation check
      if (validateGeneralInfo()) {
        setIsInfoValidationEnabled(false);

        // If data type is "String", we go to next step to optionally set values
        if (generalInfo.type === Type.String){
          setCurrentStep(Steps.ADD_VALUES);
        }
        // If the data type selected is not "String", we create the field without going to the next step
        else{
          const fieldRequest = createMetadataFieldRequestModel([]);
          handleCreateNewMetadataField(fieldRequest);
        }
      }
      else {
        setIsInfoValidationEnabled(true);
      }
    }
    else if (currentStep === Steps.ADD_VALUES) {
      // validation check
      const result = getValidationResult();

      if (!result.isValidationSuccessful){
        setValidationErrors(result.errors);
        setIsErrorModalVisible(true);
        return;
      }

      const fieldRequest = createMetadataFieldRequestModel(result.data);
      handleCreateNewMetadataField(fieldRequest);
    }
  }

  return (
    <IlapPopup
      ref={popupRef}
      width={"728px"}
      height={"92vh"}
      minHeight={"720px"}
      maxHeight={"1000px"}
      container="html">
      <div className="flex flex-col justify-between h-full">
        <header>
          <div className="mb-5 flex items-center justify-between">
            <div className="flex items-center">
              <div className="font-poppins text-dark-blue-1 text-[18px] font-semibold leading-normal">New field</div>
              <div className="ml-2 font-inter text-14px font-normal opacity-50">Step {currentStep} of {totalSteps}</div>
            </div>
            <div className="flex items-center">
              <StepperProgressBar items={progressItems} current={currentStep} />
            </div>
          </div>
          <hr className="mt-4 mb-4 border-light-gray" />
        </header>

        {currentStep === Steps.GENERAL_INFO &&
          <FieldGeneralInfoForm
            data={generalInfo}
            isValidationEnabled={isInfoValidationEnabled}
            onValueChange={handleGeneralInfoChange}
          />
        }

        {currentStep === Steps.ADD_VALUES &&
          <FieldValueEditorForm
            ref={fieldValueEditorRef}

            description={editorDescription}

            parentRef={popupRef}
            toggleState={editorToggleState}
            onToggleChange={handleToggleChange}

            // Table Editor Props
            dataSource={fieldValues}
            onAddNewItem={handleAddNewFieldValue}
            onRemoveItems={handleRemoveFieldValues}

            // Bulk Editor Props
            bulkInput={bulkInput}
            onBulkInputChange={handleBulkValueUpdate}
            onValidateBulkInput={validateAndSaveBulkInput}
            highlightBulkInputEditor={validationErrors.length > 0}
          />
        }

        <footer className="flex flex-col justify-end">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={handlePreviousClick}>{previousButtonText}</IlapButton>
            <IlapButton className="px-4 font-medium" onClick={handleNextClick}>{nextButtonText}</IlapButton>
          </div>
        </footer>

        {isConfirmationDialogVisible &&
          <IlapConfirmationDialog
            height="181px"
            width="456px"

            content={confirmationDialogInfo?.content}
            subContent={confirmationDialogInfo?.subContent}
            cancelButtonText={confirmationDialogInfo?.cancelButtonText}
            confirmButtonText={confirmationDialogInfo?.confirmButtonText}

            onConfirm={confirmationDialogInfo?.onConfirm}
            onCancel={confirmationDialogInfo?.onCancel}
          />
        }
        {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={() => setIsErrorModalVisible(false)} />}
      </div>
    </IlapPopup>
  );
}
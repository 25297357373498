import { IButtonOptions } from "devextreme-react/button";
import ModifyIcon from "../icons/ModifyIcon";
import PromineoButtonWithIcon from "./PromineoButtonWithIcon";
import { ControlVariant } from "shared/enums/ControlVariantEnum";

interface Props {
  text?: string;
}

export default function ModifyButton(props: Props & IButtonOptions) {
  return (
    <PromineoButtonWithIcon
      {...props}
      variant={ControlVariant.secondary}
      text={props.text || "Modify"}
      Icon={<ModifyIcon />}
    />
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReportSchedulesAsync,
  getReportScheduleAsync,
  createReportScheduleAsync,
  updateReportScheduleAsync,
  deleteReportScheduleAsync,
  getReportScheduleWithSchedulesAsync,
  changeReportScheduleRevisionAsync,
  deleteScheduleAsync as deleteSchedule,
  revertScheduleAsync,
  getReportActivitiesWithMetadataAsync,
  updateReportScheduleStatusAsync,
} from "apis/ReportScheduleApi";
import ReportScheduleRequest from "interfaces/request/ReportScheduleRequest";
import ReportScheduleRevertRequest from "interfaces/request/ReportScheduleRevertRequest";
import { ReportScheduleRevisionChangeRequest } from "interfaces/request/ReportScheduleRevisionChangeRequest";
import ReportScheduleStatusUpdateRequest from "interfaces/request/ReportScheduleUpdateRequest";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { RootState } from "store/store";

export const loadReportSchedulesAsync = createAsyncThunk(
  "reportSchedules/loadReportSchedules",
  async () => {
    return await getReportSchedulesAsync();
  }
);

export const loadSingleReportScheduleAsync = createAsyncThunk<
  any,
  { reportScheduleId: number },
  { state: RootState }
>("reportSchedules/loadSingleReportSchedule", async (request, thunkAPI) => {
  const state = thunkAPI.getState();

  const existingReportScheduleWithMetadataIndex =
    state.reportScheduleData.reportSchedulesWithMetadataFieldValues.findIndex(
      (reportSchedule: ReportScheduleResponse) =>
        reportSchedule.id === request.reportScheduleId
    );

  if (existingReportScheduleWithMetadataIndex > -1) {
    return state.reportScheduleData.reportSchedulesWithMetadataFieldValues[
      existingReportScheduleWithMetadataIndex
    ];
  } else {
    return await getReportScheduleAsync(request.reportScheduleId);
  }
});

export const loadReportScheduleWithSchedulesAsync = createAsyncThunk(
  "reportSchedules/loadReportScheduleWithRevisions",
  async (reportScheduleId: number) => {
    return await getReportScheduleWithSchedulesAsync(reportScheduleId);
  }
);

export const loadReportActivitiesWithMetadataAsync = createAsyncThunk(
  "reportSchedules/loadReportActivities",
  async (reportScheduleId: number) => {
    return await getReportActivitiesWithMetadataAsync(reportScheduleId);
  }
);

export const createReportSchedule = createAsyncThunk(
  "reportSchedules/createReportSchedule",
  async (reportSchedule: ReportScheduleRequest) => {
    return await createReportScheduleAsync(reportSchedule);
  }
);

export const revertToPreviousScheduleAsync = createAsyncThunk(
  "reportSchedules/revertReportSchedule",
  async (reportSchedule: ReportScheduleRevertRequest) => {
    return await revertScheduleAsync(reportSchedule);
  }
);

export const updateReportSchedule = createAsyncThunk<
  ReportScheduleResponse,
  { reportScheduleId: number; reportScheduleRequest: ReportScheduleRequest }
>("reportSchedules/updateReportSchedule", async (request) => {
  return await updateReportScheduleAsync(
    request.reportScheduleId,
    request.reportScheduleRequest
  );
});

export const updateReportScheduleStatus = createAsyncThunk<
  ReportScheduleResponse,
  { reportScheduleId: number; reportScheduleRequest: ReportScheduleStatusUpdateRequest }
>("reportSchedules/updateReportScheduleStatus", async (request) => {
  return await updateReportScheduleStatusAsync(
    request.reportScheduleId,
    request.reportScheduleRequest
  );
});

export const deleteReportSchedule = createAsyncThunk<
  any,
  { reportScheduleId: number },
  { state: RootState }
>("reportSchedules/deleteReportSchedule", async (request) => {
  await deleteReportScheduleAsync(request.reportScheduleId);
});

export const changeReportScheduleRevisionTypeAsync = createAsyncThunk<
  any,
  ReportScheduleRevisionChangeRequest
>("reportSchedules/changeReportScheduleRevisionTypeAsync", async (request) => {
  var response = await changeReportScheduleRevisionAsync(request);
  return response;
});

export const deleteScheduleAsync = createAsyncThunk(
  "reportSchedules/deleteScheduleAsync",
  async (scheduleId: number) => {
    return await deleteSchedule(scheduleId);
  }
);

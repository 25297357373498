import React from "react";
import NavigationBar from "components/header/navigation-bar/NavigationBar";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

interface Props {
  children: React.ReactElement;

}

export default function PrivateRouteGuard(props: Props) {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <div>
      <NavigationBar />
      <div className="pt-[38px]">{props.children}</div>
    </div>
  ) : (
    <Navigate to={"/"} />
  );
}

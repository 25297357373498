import PromineoButton from "./PromineoButton";
import RevertIcon from "../icons/RevertIcon";
import "./styles/RevertButton.css";
import { IButtonOptions } from "devextreme-react/button";

interface Props {
  text?: string;
}

export default function RevertButton(props: Props & IButtonOptions) {
  
  return (
    <PromineoButton className="revert-button" {...props}>
      <div className="flex justify-center items-center">        
        <div className="mr-7.5px">{props.text ?? "Revert to last"}</div>
        <RevertIcon/>
      </div>
    </PromineoButton>
  );
}

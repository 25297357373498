import { ScrollView } from "devextreme-react";
import ReportScheduleFieldEdit from "features/report-schedules/ReportScheduleFieldEdit";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";

interface Props {
  fieldSource: ReportScheduleMetadataFieldValue[],
  onDropdownSelectionChange: (selectedItem: MetadataFieldValue) => void;
  onFieldValueChange: (metadataFieldId: number, value: string, id?: number) => void;
}

export default function ReportScheduleFieldValueForm(props: Props) {
  return <>
    {props.fieldSource.length > 0 &&
      <ScrollView showScrollbar="always" height={472}>
        <ReportScheduleFieldEdit
          metadataFields={props.fieldSource}
          handleDropdownSelectionChange={props.onDropdownSelectionChange}
          handleMetadataFieldValueChange={props.onFieldValueChange}
          columns="two"
        />
      </ScrollView>
    }
    {
      !props.fieldSource.length &&
      <div className="text-center text-[18px] text-gray-7">
        No fields available for the selected schedule type.
      </div>
    }
  </>
}
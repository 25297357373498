import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { useCallback } from "react";
import { useBlocker, useBeforeUnload } from "react-router-dom";

interface Props {
  preCondition?: () => boolean; // if no pre-condition callback is provided, any navigation will be blocked by default
  warningMessage?: string; // the warning message to show in the confirmation dialog
}

export default function NavigationBlocker(props: Props) {
  const validatePreCondition = useCallback(() => {
    // if no pre-condition callback is provided, we consider the pre-condition to be passed, hence 'true' if undefined
    return props.preCondition?.() ?? true;
  }, [props.preCondition]);

  const validatePreConditionBeforeUnloadCallback = useCallback((event: BeforeUnloadEvent) => {
    if (validatePreCondition()){
      event.preventDefault();
    }
  }, [validatePreCondition]);

  const blockerCallback = useCallback(({ currentLocation, nextLocation } : any) => {
    return currentLocation.pathname !== nextLocation.pathname && validatePreCondition();
  }, [validatePreCondition]);

  // --- Block External Navigation ---
  useBeforeUnload(validatePreConditionBeforeUnloadCallback);

  // --- Block Internal Navigation ---
  let blocker = useBlocker(blockerCallback);

  return <>
    {/*
      An error shows if we try to call 'blocker.proceed()'/'blocker.reset()' directly in code: 
      "Can not invoke object which is possibly undefined". 
      That is why we have to call them with a null/undefined check like this: blocker.proceed?.().

      To make sure the functions are indeed defined and user will not get stuck with a blocker modal, 
      we added extra condition to check that they are defined, before rendering the component.
    */}
    {blocker.state === "blocked" && blocker.proceed && blocker.reset &&
      <IlapConfirmationDialog
        height="181px"
        width="456px"

        content={"Cancel changes"}
        subContent={props.warningMessage ?? "The changes you’ve made will not be saved."}
        cancelButtonText={"Continue editing"}
        confirmButtonText={"Confirm"}

        onConfirm={() => blocker.proceed?.()}
        onCancel={() => blocker.reset?.()}
      />
    }
  </>
}
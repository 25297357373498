export const DownloadFile = (data: any, type: string, filename: string) => {
  const blob = new Blob([data], { type: type });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.style.display = 'none';

  // Append the <a> element to the document body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up resources
  URL.revokeObjectURL(url);
  link.parentNode?.removeChild(link);
}
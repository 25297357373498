import { useEffect, useState } from "react";

interface Props {
  ref: React.RefObject<any>,

}

export default function useElementHeight(props: Props){
  const [elementHeight, setElementHeight] = useState<number>(0);

  useEffect(() => {
    const setFieldsGridHeight = () => {
      const height = props.ref?.current?.instance.content().clientHeight;
      setElementHeight(height);
    }

    // call once initially
    setFieldsGridHeight(); 

    // then add to listener
    window.addEventListener("resize", setFieldsGridHeight);

    // clean up
    return () => {
      window.removeEventListener('resize', setFieldsGridHeight);
    };
  }, []);

  return elementHeight;
}
import "./styles/TextWithCount.css";

interface Props {
  text?: string,
  count?: number,
  isActive?: boolean
}

export default function TextWithCount(props: Props) {
  return (
    <div className="Ilap-text-with-count">
      <div className="text">{props.text} <span className="count">{props.count}</span></div>
    </div>
  );
}
import "./styles/PromineoLabel.css";

interface Props {
  label: string;
  className?: string;
}

export default function PromineoLabel (props: Props) {
  const {label, className} = props;
  const defaultClass = "flex rounded-l-lg h-8 w-64 pl-3 pt-2 bg-gray-100 font-inter font-medium text-xs text-gray";

  return (
    <div className={className ?? defaultClass}>
      {label}
    </div>
  );
}
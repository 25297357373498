import { Button } from "devextreme-react";
import TrashCanIcon from "../icons/TrashCanIcon";

interface Props {
  disabled?: boolean;
  onClick?: () => void;
}

export default function TrashCanButton(props: Props) {
  return (
    <Button className="p-0.5 rounded-lg" disabled={props.disabled} onClick={props.onClick}>
      <TrashCanIcon />
    </Button >
  )
}
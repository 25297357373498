import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import IlapToggleButton, { IlapToggleButtonState } from "components/common/buttons/IlapToggleButton";
import FieldValueTableEditor from "./FieldValueTableEditor";
import FieldValueBulkEditor from "./FieldValueBulkEditor";
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import TrashCanButton from "components/common/buttons/TrashCanButton";
import { DataGrid } from "devextreme-react";

interface Props {
  dataSource: FieldValueInput[];
  toggleState: IlapToggleButtonState;
  onToggleChange: (state: IlapToggleButtonState) => void;

  // Table Editor Props
  onAddNewItem: () => void;
  onRemoveItems: (items: FieldValueInput[]) => void,

  // Bulk Editor Props
  bulkInput: string,
  onBulkInputChange: (bulkInput: string) => void;
  onValidateBulkInput: () => boolean;
  highlightBulkInputEditor: boolean;
  editorHeight?: string;
}

export type FieldValueEditorType = {
  saveTableData: () => void;
}

const FieldValueEditor = forwardRef<FieldValueEditorType, Props>((props, ref) => {
  const [isDeleteItemsEnabled, setIsDeleteItemsEnabled] = useState<boolean>(false);
  const tableEditorRef = useRef<DataGrid>(null);

  const setToggleStateLeft = () => props.onToggleChange(IlapToggleButtonState.Left);

  const setToggleStateRight = () => {
    saveTableData(); // save table input when moved to bulk input while modifying a row
    clearTableRowSelections();
    props.onToggleChange(IlapToggleButtonState.Right);
  }

  const handleDeleteFieldValues = () => {
    const selectedRows = tableEditorRef.current?.instance.getSelectedRowKeys() as FieldValueInput[];
    props.onRemoveItems(selectedRows);
  }

  useImperativeHandle(ref, () => ({
    saveTableData
  }));

  const handleRowSelectionChange = () => {
    // Enable Delete Fields Button (Trash Can) if any row is selected in the Grids 
    tableEditorRef.current?.instance.getSelectedRowKeys().length
      ? setIsDeleteItemsEnabled(true)
      : setIsDeleteItemsEnabled(false);
  }

  const clearTableRowSelections = () => {
    tableEditorRef.current?.instance.clearSelection();
  }

  const saveTableData = () => {
    if (!tableEditorRef.current?.instance.hasEditData()){
      return;
    }
    
    tableEditorRef.current?.instance.saveEditData();
  }

  const deleteFieldsButtonComponent = useMemo(() => {
    return <TrashCanButton disabled={!isDeleteItemsEnabled} onClick={handleDeleteFieldValues} />
  }, [isDeleteItemsEnabled]);

  return (
    <div>
      <div className="flex justify-end mb-2">
        {props.toggleState === IlapToggleButtonState.Left &&
          <div className="mr-2">
            {deleteFieldsButtonComponent}
          </div>
        }
        <IlapToggleButton
          leftButtonText="Table"
          rightButtonText="Textfield"
          state={props.toggleState}
          onClickLeft={setToggleStateLeft}
          onClickRight={setToggleStateRight} />
      </div>

      {props.toggleState === IlapToggleButtonState.Left &&
        <FieldValueTableEditor
          ref={tableEditorRef}
          dataSource={props.dataSource}
          onAddNewItem={props.onAddNewItem}
          onRowSelectionChange={handleRowSelectionChange}
          height={props.editorHeight}
        />
      }

      {props.toggleState === IlapToggleButtonState.Right &&
        <FieldValueBulkEditor
          height={props.editorHeight}
          dataSource={props.dataSource}
          bulkInput={props.bulkInput}
          onBulkInputChange={props.onBulkInputChange}
          highlightBulkInputEditor={props.highlightBulkInputEditor}
        />
      }

    </div>
  )
});

export default FieldValueEditor;
import "./styles/InfoWidget.css";

interface Props {
  label: string,
  data?: string | React.ReactElement,
  truncate?: boolean,
  width?: string,
  minWidth?: string,
}

export default function InfoWidget(props: Props) {
  const configCss = props.truncate && props.data ? `truncate` : "whitespace-nowrap";

  return (
    <div className={`InfoWidget flex flex-initial items-center ${configCss}`} style={{ width: props.width, minWidth: props.minWidth}}>
      {props.label && <div className="label">{props.label}:</div>}
      {props.data && <div title={typeof props.data === "string" ? props.data : ""} className="data truncate w-full">{props.data}</div>}
    </div>
  );
}
import { useState } from "react";
import NavItemOption from "interfaces/NavItemOption";
import * as routePath from "shared/constants/RoutePathConstants";
import { useLocation } from "react-router-dom";
import Account from "features/account";
import MobileNavigation from "./MobileNavigation";
import NavigationItems from "./NavigationItems";
import AnalyticsIcon from "components/common/icons/AnalyticsIcon";

export default function NavigationBar() {
  const navItems: NavItemOption[] = [
    {
      text: "Report schedules",
      route: routePath.REPORT_SCHEDULES,
      itemId: "report-schedules",
    },
    {
      text: "Schedule types",
      route: routePath.SCHEDULE_TYPES,
      itemId: "schedule-types",
    },
    {
      text: "Fields",
      route: routePath.FIELDS,
      itemId: "fields",
    },
  ];

  const location = useLocation();
  const [
    isMobileNavigationMenuIconVisible,
    setIsMobileNavigationMenuIconVisible,
  ] = useState(false);

  const handleMobileNavigationMenuClick = () => {
    setIsMobileNavigationMenuIconVisible(!isMobileNavigationMenuIconVisible);
  };

  const handleMobileNavigationPopOverHiding = () => {
    setIsMobileNavigationMenuIconVisible(false);
  };

  return (
    <div>
      <nav className="bg-dark-blue-1 text-white px-6 h-48px flex">
        <div className="flex-1 flex items-center justify-between">
          <div className=" inline md:hidden flex-shrink-0 items-center">
            <div
              onClick={handleMobileNavigationMenuClick}
              id="mobile-navigation"
              className={`${
                isMobileNavigationMenuIconVisible ? `bg-white` : `bg-grey-300`
              } p-2 rounded-md text-grey-700 hover:text-gray-2`}
            >
              <div
                className={`${
                  isMobileNavigationMenuIconVisible ? `text-blue-900` : ``
                }`}
              >
                <i className="dx-icon-menu"></i>
              </div>
              <MobileNavigation
                target="#mobile-navigation"
                visible={isMobileNavigationMenuIconVisible}
                onHiding={handleMobileNavigationPopOverHiding}
                navigationItems={navItems}
              />
            </div>
          </div>
          <div className="flex-shrink-0 w-auto">
            <AnalyticsIcon />
          </div>
          <div className="hidden md:block flex items-start w-full mx-4 h-full">
            <div className="float-left h-full">
              <NavigationItems navItems={navItems} />
            </div>
          </div>
          <div className="w-auto">
            <Account />
          </div>
        </div>
      </nav>
    </div>
  );
}

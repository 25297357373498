import ScheduleResponse from "interfaces/response/ScheduleResponse";
import { useEffect, useState } from "react";
import { getDefaultLocaleFormattedDate } from "shared/utilities/DateTimeUtility";

interface Props {
  schedule: ScheduleResponse;
}

export default function ScheduleDetails(props: Props) {
  const [revisionType, setRevisionType] = useState("-");
  useEffect(() => {
    const type = (props.schedule.allowedRevisionTypes || []).find(
      (r) => r.value == props.schedule.revisionType
    );
    if (type) {
      setRevisionType(type.name);
    }
  }, []);

  return (
    <>
      <span className="font-bold text-sm px-2.5">Value</span>
      <div className="grid gap-x-8 gap-y-6 mt-4">
        <div className="flex gap-x-1">
          <div className="w-256px bg-gray-100 px-2.5 py-2 rounded-l-lg">
            Reference
          </div>
          <div className="bg-gray-100 px-2.5 py-2 rounded-r-lg w-40">
            {revisionType}
          </div>
        </div>
        <div className="flex">
          <div className="w-256px px-2.5">Date submitted</div>
          <div className="px-2.5 w-40">
            {(props.schedule.submittedDate &&
              getDefaultLocaleFormattedDate(props.schedule.submittedDate)) ||
              "-"}
          </div>
        </div>
        <div className="flex">
          <div className="px-2.5 w-256px">Cut-off date</div>
          <div className="px-2.5 w-40">
            {(props.schedule.cutoffDate &&
              getDefaultLocaleFormattedDate(props.schedule.cutoffDate)) ||
              "-"}
          </div>
        </div>
      </div>
    </>
  );
}

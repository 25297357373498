import { createSlice } from "@reduxjs/toolkit";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import {
  getPointsInTimeTypeValuesAsync,
  getValidationRequirementValuesAsync,
  getPlanningLevelValuesAsync,
  getPlanningObjectTypeValues,
  getRevisionTypeValuesAsync,
} from "store/action/DropdownValueActions";

interface DropdownValueState {
  pointsInTimeType: DropdownValuesResponse[];
  validationRequirement: DropdownValuesResponse[];
  planningLevel: DropdownValuesResponse[];
  planningObjectTypes: DropdownValuesResponse[];
  revisionTypes: DropdownValuesResponse[];
}

const initialState: DropdownValueState = {
  pointsInTimeType: [],
  validationRequirement: [],
  planningLevel: [],
  planningObjectTypes: [],
  revisionTypes: [],
};

export const dropdownValuesSlice = createSlice({
  name: "dropdownValues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPointsInTimeTypeValuesAsync.fulfilled,
      (state, action) => {
        state.pointsInTimeType = action.payload;
      }
    );

    builder.addCase(
      getValidationRequirementValuesAsync.fulfilled,
      (state, action) => {
        state.validationRequirement = action.payload;
      }
    );

    builder.addCase(getPlanningLevelValuesAsync.fulfilled, (state, action) => {
      state.planningLevel = action.payload;
    });
    builder.addCase(getPlanningObjectTypeValues.fulfilled, (state, action) => {
      state.planningObjectTypes = action.payload;
    });

    builder.addCase(getRevisionTypeValuesAsync.fulfilled, (state, action) => {
      state.revisionTypes = action.payload;
    });
  },
});

export default dropdownValuesSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  loadReportScheduleTypesAsync,
  loadSingleReportScheduleTypeAsync,
  createReportScheduleType,
  updateReportScheduleType,
  deleteReportScheduleType,
} from "store/action/ReportScheduleTypeActions";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import { toastIlapInfo } from "shared/utilities/ToastUtility";

export interface ReportScheduleTypeState {
  reportScheduleTypes: ReportScheduleType[];
  reportScheduleTypesWithMetadataFields: ReportScheduleType[];
}

const initialState: ReportScheduleTypeState = {
  reportScheduleTypes: [],
  reportScheduleTypesWithMetadataFields: [],
};

// As per current api implementation, reportScheduleTypes state contains all metadata fields with values and
// reportScheduleTypesWithMetadataFields contains only Activity metadata and Report Schedule metadata with values.

// TODO : We need to check if we need all metadata field values when getting all reportscheduleTypes from api.

const updateReportScheduleTypeInState = (
  reportScheduleTypeToBeUpdatedId: number,
  updatedReportScheduleType: ReportScheduleType,
  state: ReportScheduleTypeState
) => {
  const existingReportScheduleTypeIndex = state.reportScheduleTypes.findIndex(
    (reportScheduleType: ReportScheduleType) => reportScheduleType.id === reportScheduleTypeToBeUpdatedId
  );
  if (existingReportScheduleTypeIndex > -1) {
    state.reportScheduleTypes[existingReportScheduleTypeIndex] = updatedReportScheduleType;
  }
};

const updateReportScheduleTypeWithMetadataInState = (
  reportScheduleTypeToBeUpdatedId: number,
  updatedReportScheduleType: ReportScheduleType,
  state: ReportScheduleTypeState
) => {
  const existingReportScheduleTypeIndex = state.reportScheduleTypesWithMetadataFields.findIndex(
    (reportScheduleType: ReportScheduleType) => reportScheduleType.id === reportScheduleTypeToBeUpdatedId
  );
  if (existingReportScheduleTypeIndex > -1) {
    state.reportScheduleTypesWithMetadataFields[existingReportScheduleTypeIndex] = updatedReportScheduleType;
  }
};

const deleteReportScheduleTypeFromState = (
  reportScheduleTypeId: number,
  state: ReportScheduleTypeState
) => {
  const existingReportScheduleIndex = state.reportScheduleTypes.findIndex(
    (reportScheduleType: ReportScheduleType) =>
    reportScheduleType.id === reportScheduleTypeId
  );
  if (existingReportScheduleIndex > -1) {
    state.reportScheduleTypes.splice(existingReportScheduleIndex, 1);
  }
};

export const reportScheduleTypeSlice = createSlice({
  name: "reportScheduleTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadReportScheduleTypesAsync.fulfilled, (state, action) => {
      state.reportScheduleTypes = action.payload;
    });
    builder.addCase(loadSingleReportScheduleTypeAsync.fulfilled, (state, action) => {
      state.reportScheduleTypesWithMetadataFields.push(action.payload);
    });
    builder.addCase(createReportScheduleType.fulfilled, (state, action) => {
      state.reportScheduleTypes.unshift(action.payload);
      toastIlapInfo("Created Report schedule type successfully.");
    });
    builder.addCase(updateReportScheduleType.fulfilled, (state, action) => {
      updateReportScheduleTypeInState(action.payload.id, action.payload, state);
      updateReportScheduleTypeWithMetadataInState(action.payload.id, action.payload, state);
      toastIlapInfo("Updated Report schedule type successfully.");
    });
    builder.addCase(deleteReportScheduleType.fulfilled, (state, action) => {
      deleteReportScheduleTypeFromState(action.meta.arg.reportScheduleTypeId, state);
      toastIlapInfo("Deleted Report schedule type successfully.");
    });
  },
});

export default reportScheduleTypeSlice.reducer;

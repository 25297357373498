import DropDownButton, { IDropDownButtonOptions } from "devextreme-react/drop-down-button";
import "./styles/IlapDropdownButton.css";
import { useRef, useState } from "react";
import { PositionConfig } from "devextreme/animation/position";
import _uniqueId from "lodash/uniqueId";

export default function IlapDropdownButton(props: IDropDownButtonOptions) {
  const { className, ...rest } = props;

  const [dropdownControlId] = useState(`ilap-dropdown-button-${_uniqueId()}`);

  const options = useRef({
    minWidth: "120px",
    width: "fit-content",
    wrapperAttr: { class: "ilap-dropdown-button-popup" },
    position: {
      my: 'right top',
      at: 'right bottom',         
      of: `#${dropdownControlId}`
    } as PositionConfig,
  });

  return (
    <>
      <DropDownButton
        {...rest}
        id={dropdownControlId}
        dropDownOptions={options.current}
        className={`ilap-dropdown-button ${className ?? ""}`}
      />
    </>
  );
}
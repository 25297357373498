import { IlapMultilineTextEdit } from "ilap.common.webcomponents.test";
import { FieldValueInput } from "interfaces/common/FieldValueInput";

interface Props {
  dataSource: FieldValueInput[];
  bulkInput: string;
  onBulkInputChange: (bulkInput: string) => void;
  highlightBulkInputEditor: boolean;
  height?: string;
}

export default function FieldValueBulkEditor(props: Props){
  const handleValueChange = ({ event }: any) => {
    props.onBulkInputChange(event.currentTarget.value);
  }

  return (
    <div>
      <IlapMultilineTextEdit
        height={props.height}
        defaultValue={props.bulkInput}
        onChange={handleValueChange}
        validationStatus={props.highlightBulkInputEditor ? "invalid" : "valid"}
      />
    </div>
  )
}
interface Props {
  text: string,
  icon: string,
  isComplete?: boolean,
  isActive?: boolean,
}

export default function StepperProgressLabel(props: Props) {
  const { text, icon, isComplete, isActive } = props;

  const iconColor = isComplete ? "bg-green-5" : isActive ? "bg-blue-6" : "bg-gray-50";

  const textColor = isComplete || isActive ? "text-gray-9" : "text-gray-50";

  const completedSvg = <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="21" height="21" rx="10.5" fill="#009C7C" />
    <g clipPath="url(#clip0_4776_14317)">
      <g clipPath="url(#clip1_4776_14317)">
        <g clipPath="url(#clip2_4776_14317)">
          <g clipPath="url(#clip3_4776_14317)">
            <path d="M8.50091 13.7999L5.70091 10.9999L4.76758 11.9333L8.50091 15.6666L16.5009 7.66661L15.5676 6.73328L8.50091 13.7999Z" fill="white" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4776_14317">
        <rect width="16" height="16" fill="white" transform="translate(2.5 3)" />
      </clipPath>
      <clipPath id="clip1_4776_14317">
        <rect width="16" height="16" fill="white" transform="translate(2.5 3)" />
      </clipPath>
      <clipPath id="clip2_4776_14317">
        <rect width="16" height="16" fill="white" transform="translate(2.5 3)" />
      </clipPath>
      <clipPath id="clip3_4776_14317">
        <rect width="16" height="16" fill="white" transform="translate(2.5 3)" />
      </clipPath>
    </defs>
  </svg>;

  const iconElement = isComplete ? completedSvg : icon;

  return <div className="flex items-center">
    <div className={`${textColor} font-inter text-14px font-normal`}>{text}</div>
    <div className={`${iconColor} text-white ml-2 h-[21px] w-[21px] rounded-full flex justify-center items-center`}>{iconElement}</div>
  </div>
}
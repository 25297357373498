import { FieldValueUtility } from "shared/utilities/FieldValueUtility";
import { FieldValueValidationResult } from "../interface/FieldValueValidationResult";

export const BulkInputUtility = {
  getInputLines: (bulkInput: string): string[] => {
    const inputLines = bulkInput.trim().split("\n");

    if (inputLines.length === 1 && inputLines[0] === "") { // to discard initial empty line as a valid input
      return [];
    }

    return inputLines;
  },
  getInputErrors: (inputLines: string[]): string[] => {
    const errors: string[] = [];
    const addError = (error: string) => errors.push(error);

    const seenInputs: Record<string, number> = {};

    inputLines.forEach((input: string, index: number) => {
      let splittedInput = input.trim().split(";").map(input => input.trim());

      let lineNumber = index + 1;

      if (splittedInput.length !== 2 || !splittedInput[0]) {
        addError(`Invalid input at line ${lineNumber}: '${input}' | Each line should contain a value code and optionally a description separated by ';' (semicolon)`);
      }
      else if (!splittedInput[0]) {
        addError(`Invalid input at line ${lineNumber}: '${input}' | Value code must be provided.`);
      }
      else if (seenInputs.hasOwnProperty(splittedInput[0])) {
        addError(`Invalid input at line ${lineNumber}: '${input}' | Duplicate value code provided. Previously occurred in line ${seenInputs[splittedInput[0]]}.`);
      }
      else { // input line is valid
        seenInputs[splittedInput[0]] = lineNumber;
      }
    });

    return errors;
  },
  validateBulkInput: (bulkInput: string): FieldValueValidationResult => {
    const inputLines = BulkInputUtility.getInputLines(bulkInput);

    const errors = BulkInputUtility.getInputErrors(inputLines);

    // we don't convert bulk input to FieldValue if errors are found
    const data = errors.length ? [] : FieldValueUtility.convertInputStringsToFieldValueInputs(inputLines);

    return {
      data: data,
      isValidationSuccessful: !errors.length,
      errors: errors
    }

  }
};
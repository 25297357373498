import NavItemOption from "interfaces/NavItemOption";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNavItemId } from "./NavigationItems";
import SubNavMenu from "./SubNavMenu";

interface Props {
  onHidingSubmenu?: () => void;
}

export default function NavigationItem(props: Props & NavItemOption) {
  let navigate = useNavigate();
  const { isActive, text, route, itemId, children } = props;
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const handleNavItemClick = (e: any) => {
    if (children && children.length) {
      setIsSubMenuVisible(true);
    } else {
      navigateToRoute(route);
    }
    e.stopPropagation();
  };

  const navigateToRoute = (routeToNavigate: string | undefined) => {
    if (routeToNavigate) {
      setIsSubMenuVisible(false);
      navigate(routeToNavigate);
      props.onHidingSubmenu?.();
    }
  };

  const getActiveStateClass = () => {
    return isActive ? "text-orange-200 md:text-white md:border-orange-200" : "border-transparent";
  };

  return (
    <>
      <div
        id={getNavItemId(itemId)}
        className={`mx-auto py-1.5 font-poppins w-40 md:border-b-4 font-semibold md:font-normal hover:text-orange-200 hover:border-orange-200 ${
          isSubMenuVisible ? "text-white" : ""
        } text-15px cursor-pointer leading-normal text-center ${getActiveStateClass()} h-full flex items-center justify-center`}
        onClick={handleNavItemClick}
      >
        <div className="h-full flex items-center">{text}</div>
      </div>
      {isSubMenuVisible && (
        <SubNavMenu
          navItems={props.children || []}
          visible={isSubMenuVisible}
          target={`#${getNavItemId(itemId)}`}
          onHiding={() => setIsSubMenuVisible(false)}
          onNavigation={navigateToRoute}
        />
      )}
    </>
  );
}

import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoModal from "components/modal/PromineoModal";
import ScheduleResponse from "interfaces/response/ScheduleResponse";
import { useEffect, useState } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ScheduleDetails from "./ScheduleDetails";
import ScheduleModalHeader from "./ScheduleModalHeader";

interface Props {
  schedule: ScheduleResponse;
  onClose: () => void;
  onDelete: (schedule: ScheduleResponse) => void;
}

export default function ScheduleModal(props: Props) {
  const [editorMode, setEditorMode] = useState<PromineoModalMode>(
    PromineoModalMode.View
  );

  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogVisible,
  ] = useState(false);

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  useEffect(() => {
    const items = [
      {
        key: "Report schedules",
        text: "Report schedules",
      },
      {
        key: `${props.schedule.description}`,
        text: `${props.schedule.description}`,
      },
      {
        key: "view-or-edit-schedule",
        text: `${
          editorMode === PromineoModalMode.Modify ? "Modify" : "View"
        } schedule`,
      },
    ];
    setBreadcrumbItems(items);
  }, [editorMode, props.schedule.description]);

  const handleModifyButtonClick = () => {
    setEditorMode(PromineoModalMode.Modify);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteConfirmationDialogVisible(false);
    props.onDelete(props.schedule);
  };

  return (
    <div>
      <PromineoModal
        isVisible={true}
        editorActionButtonProps={{
          mode: editorMode,
          onDone: () => props.onClose(),
          onSaveChanges: () => props.onClose(),
          onDelete: () => setIsDeleteConfirmationDialogVisible(true),
          doneButtonCaption: "Done",
          saveChangesButtonCaption: "Done",
        }}
      >
        <div className="h-470px">
          <Breadcrumb items={breadcrumbItems} isReadonly={true} />
          <div className="mt-3.5">
            <ScheduleModalHeader
              schedule={props.schedule}
              onModifyClick={handleModifyButtonClick}
            />
          </div>
          <div className="border-2 border-gray-100 my-4"></div>
          <div>
            <ScheduleDetails schedule={props.schedule} />
          </div>
        </div>
      </PromineoModal>
      {isDeleteConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          title="Are you sure?"
          onConfirm={handleDeleteConfirm}
          onCancel={() => setIsDeleteConfirmationDialogVisible(false)}
          content={`${props.schedule.description} will be deleted.`}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}
    </div>
  );
}

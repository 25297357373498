import { Popup } from "devextreme-react"
import { useMemo, useRef, useState } from "react";
import "./styles/FieldSelectorModal.css";
import ScheduleTypeFieldsEditorForm from "features/schedule-types/common/ScheduleTypeFieldsEditorForm";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import { IlapButton, IlapButtonType, IlapPopup } from "ilap.common.webcomponents.test";
import _ from "lodash";
import { FieldSelectorPanelType } from "features/common/FieldSelectorPanel";
import { ReceivedFrom } from "shared/enums/ReceivedFromEnum";
import { FieldTypeEnum } from "shared/enums/FieldTypeEnum";

interface Props {
  fieldsName: FieldTypeEnum,
  availableFields: MetadataFieldInfo[],
  selectedFields: MetadataFieldInfo[],
  onSubmit: (selectedFields: MetadataFieldInfo[], availableFields: MetadataFieldInfo[]) => void;
  onHideModal: () => void;
}

export default function FieldSelectorModal(props: Props) {
  const modalRef = useRef<Popup>(null);
  const [isModalReady, setIsModalReady] = useState<boolean>(false);

  // Deep copy fields, so that, changes made on the modal is not immediately affecting the parent
  const [availableFields, setAvailableFields] = useState<MetadataFieldInfo[]>(_.cloneDeep(props.availableFields));
  const [selectedFields, setSelectedFields] = useState<MetadataFieldInfo[]>(_.cloneDeep(props.selectedFields));

  const description = props.fieldsName === FieldTypeEnum.schedule
    ? `Add schedule fields to your schedule type by selecting them in the left table and pressing the Add button. You can remove fields by selecting them in the right table and pressing to the Remove button. Select the desired content control for your added fields and define whether values for this field are received from an ILAP schedule or entered manually. Click Submit to update your list of fields.`
    : `Add activity fields to your schedule type by selecting them in the left table and pressing the Add button. You can remove fields by selecting them in the right table and pressing to the Remove button. Select the desired content control for your added fields and click Submit to update your list of fields.`;
  
  const handleAddFields = (fields: MetadataFieldInfo[]) => {
    // Remove fields from "Available Fields" list
    // Complexity: O(n^2)
    setAvailableFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));

    // Put the fields into "Selected Fields" list
    setSelectedFields(prev => [...fields, ...prev]);
  }

  const handleRemoveFields = (fields: MetadataFieldInfo[]) => {
    setAvailableFields(prev => [...fields, ...prev]);

    // Complexity: O(n^2)
    setSelectedFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));
  }

  const handleContentControlChange = (modifiedField: MetadataFieldInfo, value: number) => {
    setSelectedFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index].validationRequirement = value;
      return modifiedFields;
    });
  }
  const handleReceivedFromIlapScheduleChange = (modifiedField: MetadataFieldInfo, value: ReceivedFrom) => {
    setSelectedFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index].receivedFromSchedule = value === ReceivedFrom.IlapSchedule ? true : false;
      return modifiedFields;
    });
  }

  const selectedFieldsRef = useRef<FieldSelectorPanelType>(null);

  const handleSubmit = () => {
    if (!selectedFields.length){
      props.onHideModal();
    }

    const validationStatus = selectedFieldsRef.current?.validateContentControl();

    if (!validationStatus) {
      return;
    }

    props.onSubmit(selectedFields, availableFields);
  }

  const modalWrapperAttr = useMemo(() => {
    return {
      class: "field-selector-modal",
    };
  }, []);

  return (
    <IlapPopup
      ref={modalRef}
      wrapperAttr={modalWrapperAttr}
      height={"92vh"}
      width={"92vw"}
      maxHeight={"1000px"}
      maxWidth={"1760px"}
      minHeight={"720px"}
      minWidth={"1280px"}
      onShown={() => setIsModalReady(true)}
    >
      <div className="flex flex-col justify-between h-full">
        <header>
          <div className="mb-5 flex items-center justify-between">
            <div className="flex items-center">
              <div className="font-poppins text-dark-blue-1 text-[18px] font-semibold leading-normal">{`Add ${props.fieldsName} fields`}</div>

            </div>
          </div>
          <hr className="mt-4 mb-4 border-light-gray" />
        </header>
        <ScheduleTypeFieldsEditorForm
          ref={selectedFieldsRef}
          parentRef={modalRef}
          isParentReady={isModalReady}

          fieldsName={props.fieldsName}
          description={description}

          availableFields={availableFields}
          selectedFields={selectedFields}

          onAddFields={handleAddFields}
          onRemoveFields={handleRemoveFields}
          onContentControlChange={handleContentControlChange}
          onReceivedFromIlapScheduleChange={handleReceivedFromIlapScheduleChange}
        />
        <footer className="flex flex-col justify-end">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 py-2 rounded-lg" onClick={props.onHideModal}>Cancel</IlapButton>
            <IlapButton className="px-4 py-2" onClick={handleSubmit}>Submit</IlapButton>
          </div>
        </footer>
      </div>
    </IlapPopup>
  )
}
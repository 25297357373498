import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { getDropdownValuesAsync } from "apis/DropdownValuesApi";

export const getPointsInTimeTypeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/pointsInTimeTypeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const pointsInTimeTypeValues = state.dropdownData.pointsInTimeType;

  if (pointsInTimeTypeValues.length === 0) {
    return await getDropdownValuesAsync("PointsInTimeType");
  } else {
    return pointsInTimeTypeValues;
  }
});

export const getPlanningObjectTypeValues = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/planningObjectTypeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const planningObjectTypes = state.dropdownData.planningObjectTypes;

  if (planningObjectTypes.length === 0) {
    return await getDropdownValuesAsync("PlanningObjectTypes");
  } else {
    return planningObjectTypes;
  }
});

export const getValidationRequirementValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/validationRequirementValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const validationRequirementValues = state.dropdownData.validationRequirement;

  if (validationRequirementValues.length === 0) {
    return await getDropdownValuesAsync("ValidationRequirement");
  } else {
    return validationRequirementValues;
  }
});

export const getPlanningLevelValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/planningLevelValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const planningLevelValues = state.dropdownData.planningLevel;

  if (planningLevelValues.length === 0) {
    return await getDropdownValuesAsync("PlanningLevel");
  } else {
    return planningLevelValues;
  }
});

export const getRevisionTypeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/revisionTypeValue", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const revisionTypeValues = state.dropdownData.revisionTypes;

  if (revisionTypeValues.length === 0) {
    return await getDropdownValuesAsync("ScheduleRevisionType");
  } else {
    return revisionTypeValues;
  }
});

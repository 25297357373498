import { IlapViewGrid } from "ilap.common.webcomponents.test";
import MetadataField from "interfaces/response/MetadataField";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "devextreme-react/data-grid";
import { useEffect, useMemo, useState } from "react";
import InfoWidget from "components/common/InfoWidget";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import PageTitle from "components/common/PageTitle";
import FieldViewDetails from "./FieldViewDetails";
import { FIELDS } from "shared/constants/RoutePathConstants";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { useDispatch } from "react-redux";
import { toastIlapInfo } from "shared/utilities/ToastUtility";
import { loadSingleMetadataFieldAsync } from "store/action/MetadataActions";
import { AppDispatch } from "store/store";
import { Type } from "shared/enums/TypeEnum";
import IlapDropdownButton from "components/common/controls/IlapDropdownButton";

export default function MetadataFieldView() {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const params = useParams();

  const [metadataField, setMetadataField] = useState<MetadataField>(FieldUtility.createEmptyMetadataField());
  const [isFieldViewModalVisible, setIsFieldViewModalVisible] = useState(false);

  useEffect(() => {
    if (params.id) {
      displayLoadingPanel();
      dispatch(loadSingleMetadataFieldAsync({ metadataFieldId: Number(params.id) }))
        .then(unwrapResult)
        .then((field: MetadataField) => {
          setMetadataField(field);
        })
        .catch(() => {
          toastIlapInfo("Failed to load metadata field.");
        })
        .finally(hideLoadingPanel);
    }
  }, [params, dispatch]);

  const initialWidgets = [
    <InfoWidget label="Used by" data={metadataField.usedBy} />,
    <InfoWidget label="ILAP ID" data={metadataField.ilapId} />,
    <InfoWidget label="Data type" data={FieldUtility.getDataTypeName(metadataField.type)} />,
    <InfoWidget label="Description"
      data={metadataField.description}
      truncate={true}
      minWidth="200px"
    />
  ];

  const navigateToEditPage = () => {
    navigate(`${FIELDS}/${params.id}/edit`);
  }

  const kebabMenuItems = [
    {
      key: "menu_item_1",
      text: "View details",
      onClick: () => setIsFieldViewModalVisible(true)
    },
    {
      key: "menu_item_2",
      text: "Edit",
      onClick: navigateToEditPage
    }
  ]

  const kebabMenu = useMemo(() => {
    return <IlapDropdownButton items={kebabMenuItems} text="Options" keyExpr={"text"} displayExpr={"text"} />;
  }, []);

  const toolbarConfig = useMemo(() => {
    return { dislplaySearchPanel: true }
  }, []);

  return (
    <div className="mx-14">
      <div className="mb-5 mt-px font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        <PageTitle title={metadataField.name} menu={kebabMenu} />
      </div>
      <div>
        <WidgetsWithSeparator widgets={initialWidgets} />
      </div>
      <hr className="mt-5 mb-[16px] border-light-gray" />
      {metadataField.type === Type.String &&
        <IlapViewGrid
          dataSource={metadataField.metadataFieldValues}
          className="h-[calc(100vh-224px)]"
          toolbarConfig={toolbarConfig}
        >
          <Column caption={"Value code"} dataField={"stringValue"} minWidth={100} width={280} alignment="left" />
          <Column caption={"Description"} dataField={"description"} minWidth={100} />
        </IlapViewGrid>
      }

      {isFieldViewModalVisible && (
        <FieldViewDetails
          metadataField={metadataField}
          onClose={() => setIsFieldViewModalVisible(false)}
        />
      )}
    </div>
  );
}
import ReportSchedulesGrid from "./ReportSchedulesGrid";

export default function ReportSchedules() {
  return (
    <>
      <div className="justify-center flex">
        <ReportSchedulesGrid />
      </div>
    </>
  );
}

import { parseErrorResponse } from "./ErrorParser";
import { getAnalyticsApiUrl, getAnalyticsGraphQLApiUrl } from "../utilities/ConfigUtility";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getTokenAsync } from "authentication/AuthenticationManager";

export function WithInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

export function WithAuthenticationInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (
      config.baseURL === getAnalyticsApiUrl() &&
      !(config && config.headers && config.headers.Authorization)
    ) {
      const token = await getTokenAsync();
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

export function WithAuthenticationInterceptorsForGraphQL(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (config.baseURL === getAnalyticsGraphQLApiUrl()) {
      config.headers = config.headers || {};
  
      if (!config.headers.Authorization) {
          const token = await getTokenAsync();
          if (token) {
              config.headers.Authorization = `Bearer ${token}`;
          }
      }
      config.headers["Content-Type"] = "application/json";
    }
    
    return config;
  });

  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

function handleSuccessfulRequest(response: AxiosResponse<any>) {
  return response;
}

function handleFailedRequest(error: AxiosResponse<any>) {
  const parsedError = parseErrorResponse(error);
  return Promise.reject(parsedError);
}

export enum FlowPlanningObjectTypes {
  None = 0x00,
  Schedule = 0x01,
  Activity = 0x02,
  Successor = 0x04,
  ResourceUsage = 0x08,
  Resource = 0x10,
  Profile = 0x20,
  Calendar = 0x40,
  Structure = 0x80,
  All = 0xFFF
}
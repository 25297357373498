// svg Source: "static/images/delete-icon.svg"
// svg Source: "static/images/delete-icon-danger.svg"

interface Props {
  variant?: "red" | "blue";
}

export default function DeleteIcon(props: Props) {
  return props.variant !== "red" ? (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.98667 18C2.45333 18 2 17.8133 1.62667 17.44C1.25333 17.0667 1.06667 16.6133 1.06667 16.08V2.53333H0V0.933333H4.8V0H11.2V0.933333H16V2.53333H14.9333V16.08C14.9333 16.6133 14.7467 17.0667 14.3733 17.44C14 17.8133 13.5467 18 13.0133 18H2.98667ZM13.3333 2.53333H2.66667V16.08C2.66667 16.1511 2.70222 16.2222 2.77333 16.2933C2.84444 16.3644 2.91556 16.4 2.98667 16.4H13.0133C13.0844 16.4 13.1556 16.3644 13.2267 16.2933C13.2978 16.2222 13.3333 16.1511 13.3333 16.08V2.53333ZM5.22667 14.2667H6.82667V4.66667H5.22667V14.2667ZM9.17333 14.2667H10.7733V4.66667H9.17333V14.2667Z"
        fill="#2548EE"
      />
    </svg>
  ) : (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11C0 5.20101 4.70101 0.5 10.5 0.5C16.299 0.5 21 5.20101 21 11C21 16.799 16.299 21.5 10.5 21.5C4.70101 21.5 0 16.799 0 11Z"
        fill="#F5C7BD"
      />
      <path
        d="M7.0625 17.5C6.68438 17.5 6.36079 17.3587 6.09175 17.0761C5.82225 16.7929 5.6875 16.4528 5.6875 16.0556V6.66667H5V5.22222H8.4375V4.5H12.5625V5.22222H16V6.66667H15.3125V16.0556C15.3125 16.4528 15.178 16.7929 14.9089 17.0761C14.6394 17.3587 14.3156 17.5 13.9375 17.5H7.0625ZM13.9375 6.66667H7.0625V16.0556H13.9375V6.66667ZM8.4375 14.6111H9.8125V8.11111H8.4375V14.6111ZM11.1875 14.6111H12.5625V8.11111H11.1875V14.6111Z"
        fill="#B35641"
      />
    </svg>
  );
}

export const getReportActivitiesByReportScheduleIdQuery = async (reportScheduleId : number, fields: string[]): Promise<string> => {
  
  const commaSeparatedFields = fields.join(",");
  return `{"query": "{reportActivitiesByReportScheduleId ( reportScheduleId:${reportScheduleId} ) { ${commaSeparatedFields} }}"}`;
}

export const getIntrospectionQuery = (type: string): string => {
  return JSON.stringify({
    query: `{ __type(name: "${type}") { fields { name type { kind name ofType { kind name } } } } }`
  });
};
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { LabelWithContent, IlapTextBox, IlapSelectBox, IlapMultilineTextEdit } from "ilap.common.webcomponents.test";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanningLevelValuesAsync } from "store/action/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";

interface Props {
  title: string,
  scheduleLevel: number,
  description: string | null,
  isValidationEnabled?: boolean,
  onTitleChange: (value: string) => void;
  onScheduleLevelChange: (value: number) => void;
  onDescriptionChange: (value: string) => void;
}

export default function ScheduleTypeGeneralInfoForm(props: Props) {
  const { title, scheduleLevel, description, isValidationEnabled, onTitleChange, onScheduleLevelChange, onDescriptionChange } = props;

  const dispatch = useDispatch<AppDispatch>();

  const planningLevels = useSelector((rootStore: RootState) => rootStore.dropdownData.planningLevel);

  useEffect(() => {
    if (planningLevels.length === 0) {
      displayLoadingPanel();
      dispatch(getPlanningLevelValuesAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, planningLevels.length]);

  const isTitleMissing = useMemo(() => {
    return isValidationEnabled && !title.trimStart();
  }, [isValidationEnabled, title]);

  const isScheduleLevelMissing = useMemo(() => {
    return isValidationEnabled && !scheduleLevel;
  }, [isValidationEnabled, scheduleLevel]);

  return (
    <div className="h-full flex flex-col justify-between overflow-auto">
      <div className="font-inter text-14px text-gray-9">
        <div className="h-12 leading-[150%]">Fill out the schedule type information and move on to the next step.</div>
        <hr className="mt-4 mb-4 border-light-gray" />
        <div className="grid grid-cols-1 space-y-2 w-[328px]">
          <LabelWithContent
            label={<div className="font-inter">Title</div>}
            content={
              <IlapTextBox
                placeholder="Type..."
                value={title}
                showValidationMessage={isTitleMissing}
                onChange={({ event }: any) => onTitleChange(event.currentTarget.value)}
              />
            }
          />
          <LabelWithContent
            label={<div className="font-inter">Schedule level</div>}
            content={
              <IlapSelectBox
                placeholder="Select"
                items={planningLevels}
                value={scheduleLevel}
                displayExpr={"name"}
                valueExpr={"value"}
                showValidationMessage={isScheduleLevelMissing}
                onValueChange={(id: number) => onScheduleLevelChange(id)}
              />
            }
          />
          <LabelWithContent
            label={<div className="font-inter">Description <span className="font-normal">(optional)</span></div>}
            content={
              <IlapMultilineTextEdit
                placeholder="Type..."
                value={description ?? undefined}
                className="h-[31vh]"
                height={"31vh"}
                maxHeight={"280px"}
                onChange={({ event }: any) => onDescriptionChange(event.currentTarget.value)} />
            }
          />
        </div>
      </div>
      <hr className="mt-4 mb-4 border-light-gray" />
    </div>
  );
}
import { ReactElement } from "react";

interface Props {
  widgets: ReactElement[];
}

export default function WidgetsWithSeparator(props: Props) {
  const dividerSvg = <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="2.18558e-08" x2="0.499999" y2="16" stroke="#ABABAB" />
  </svg>;

  const getElementWithKey = (element: ReactElement, key: string): ReactElement => {
    return { ...element, key: key };
  }

  const getElementsWithSeparator = (elements: ReactElement[]): React.ReactNode[] => {

    const separator = <div className="mx-4">{dividerSvg}</div>
    const elementsWithSeparator: React.ReactNode[] = [];

    elements.forEach((element, index) => {
      if (index > 0) elementsWithSeparator.push(getElementWithKey(separator, `sep-${index}`));
      elementsWithSeparator.push(getElementWithKey(element, `comp-${index}`));
    });

    return elementsWithSeparator;
  }

  return (
    <div className="flex items-center">
      {props.widgets && props.widgets.length ? getElementsWithSeparator(props.widgets) : (<></>)}
    </div>
  );
}
import { IButtonOptions } from "devextreme-react/button";
import { IlapButtonType, IlapButtonWithIcon } from "ilap.common.webcomponents.test";

export default function RemoveFromListButton(props: IButtonOptions) {
  const svg = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4896_15718)">
      <path d="M5.5293 8.30334L9.5293 12.3033L10.4693 11.3633L7.41596 8.30334L10.4693 5.24334L9.5293 4.30334L5.5293 8.30334Z" fill="#3459DB" />
    </g>
    <defs>
      <clipPath id="clip0_4896_15718">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
    ;


  return <IlapButtonWithIcon text="Remove" Icon={svg} variant={IlapButtonType.Secondary} {...props} />
}
import axios from "axios";
import {
  WithInterceptors,
  WithAuthenticationInterceptors,
  WithAuthenticationInterceptorsForGraphQL,
} from "./ApiConnectorInterceptor";
import { getAnalyticsApiUrl, getAnalyticsGraphQLApiUrl } from "../utilities/ConfigUtility";

export const ProtectedApiConnector = WithAuthenticationInterceptors(
  axios.create({
    baseURL: getAnalyticsApiUrl(),
  })
);

export const PublicApiConnector = WithInterceptors(
  axios.create({ baseURL: getAnalyticsApiUrl() })
);

export const ProtectedGraphQLApiConnector = WithAuthenticationInterceptorsForGraphQL(
  axios.create({
    baseURL: getAnalyticsGraphQLApiUrl()
  })
);
interface Props {
  items: any[],
  selectedIndex: number,
  onSelectionChange: (filterIndex: number) => void;
}

export default function IlapFilterGroup(props: Props) {
  const handleSelectionChange = (index: number) => {
    props.onSelectionChange(index);
  }

  const css = "mr-2 px-2 h-8 rounded-lg ";

  return <div className="Ilap-filter-group flex text-gray-7">
    {props.items.map((item, index) => (
      <button
        key={index}
        className={`${css} ${index == props.selectedIndex ? "bg-white text-dark-blue-1" : ""}`}
        onClick={() => handleSelectionChange(index)}
      >
        {item.name}
      </button>
      // <IlapButton key={index} className="mr-2 w-52 h-8 bg-slate-400" text={item.name} variant={IlapButtonType.Borderless} />
    ))}
  </div>
}
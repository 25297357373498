import FieldSelectorPanel, { FieldSelectorPanelType } from "features/common/FieldSelectorPanel";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import { forwardRef, useEffect, useState } from "react";
import { FieldTypeEnum } from "shared/enums/FieldTypeEnum";
import { ReceivedFrom } from "shared/enums/ReceivedFromEnum";

interface Props {
  fieldsName: FieldTypeEnum,
  description: string,
  availableFields: MetadataFieldInfo[],
  selectedFields: MetadataFieldInfo[],
  parentRef: React.RefObject<any>,
  isParentReady: boolean, // some operations (ex: height) can not be calculated from 'parentRef' properly before parent is ready
  onAddFields: (fields: MetadataFieldInfo[]) => void;
  onRemoveFields: (fields: MetadataFieldInfo[]) => void;
  onContentControlChange: (field: MetadataFieldInfo, value: number) => void;
  onReceivedFromIlapScheduleChange?: (field: MetadataFieldInfo, value: ReceivedFrom) => void;
}

const ScheduleTypeFieldsEditorForm = forwardRef<FieldSelectorPanelType, Props>((props, ref) => {
  const [gridHeight, setGridHeight] = useState<string>("400px"); // The grids used in this Modal will have this height
  const occupiedHeight = 193; // 193px will be subtracted from modal height to get Grid height

  // To get Modal's Current Height
  useEffect(() => {
    if (!props.isParentReady) { // if parent is not ready, parent's height would be zero
      return;
    }

    const setFieldsGridHeight = () => {
      const modalHeight = props.parentRef?.current?.instance.content().clientHeight;
      if (modalHeight && modalHeight > occupiedHeight) {
        const availableSpaceForGrid = modalHeight - occupiedHeight;
        setGridHeight(`${availableSpaceForGrid}px`);
      }
    }

    // call once initially
    setFieldsGridHeight(); 

    // then add to listener
    window.addEventListener("resize", setFieldsGridHeight);

    // clean up
    return () => {
      window.removeEventListener('resize', setFieldsGridHeight);
    };
  }, [props.isParentReady]);

  return (
    <div className="h-full font-inter text-14px text-gray-9">
      <div className="h-12 leading-[150%]">{props.description}</div>
      <hr className="mt-4 mb-4 border-light-gray" />
      {props.isParentReady && 
        <FieldSelectorPanel
          ref={ref}
          fieldsName={props.fieldsName}
          availableFields={props.availableFields}
          selectedFields={props.selectedFields}
          gridHeight={gridHeight}
          onAddFields={props.onAddFields}
          onRemoveFields={props.onRemoveFields}
          onContentControlChange={props.onContentControlChange}
          onReceivedFromIlapScheduleChange={props.onReceivedFromIlapScheduleChange}
        />
      }
    </div>
  );
});

export default ScheduleTypeFieldsEditorForm;
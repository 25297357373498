import DataGrid, { IDataGridOptions, Selection } from "devextreme-react/data-grid";
import dxCheckBox, { InitializedEvent, ValueChangedEvent } from "devextreme/ui/check_box";
import { IlapViewGrid } from "ilap.common.webcomponents.test";
import { DataGridItem } from "interfaces/common/DataGridItem";
import { RowPreparedEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import "./styles/IlapSelectableViewGrid.css";

import { forwardRef, useCallback, useRef } from "react";

interface Props {
  isSelectable: (item: DataGridItem) => boolean;
}

const IlapSelectableGrid = forwardRef<DataGrid, any & IDataGridOptions & Props>((props, ref) => {
  const { isSelectable, children, ...rest} = props;
  const selectionRef = useRef<{
    checkBoxUpdating: boolean,
    selectAllCheckBox: dxCheckBox | null
  }>({
    checkBoxUpdating: false,
    selectAllCheckBox: null
  });

  const isSelectAll = (dataGrid: any) => {
    let items: any[] = [];
    dataGrid.getDataSource().store().load().then((data: any) => {
      items = data as any[];
    });
    let selectableItems = items.filter(isSelectable);
    let selectedRowKeys = dataGrid.option("selectedRowKeys");
    if (!selectedRowKeys || !selectedRowKeys.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  }

  const onRowPrepared = useCallback((e : RowPreparedEvent<any, any>) => {
    if (e.rowType === "data" && !isSelectable(e.data)) {
      e.rowElement.classList.add("disabled-row");
    }
  }, [isSelectable]);

  const onEditorPreparing = useCallback((e: any) => {
    if (!isSelectable){ // if the callback is not provided, just return
      return;
    }

    let dataGrid = e.component;
    if (e.type !== 'selection') return;
    if (e.parentType === 'dataRow' && e.row && !isSelectable(e.row.data))
      e.editorOptions.disabled = true;
    if (e.parentType === "headerRow") {
      e.editorOptions.onInitialized = (e: InitializedEvent) => {
        if (e.component)
          selectionRef.current.selectAllCheckBox = e.component;
      };
      e.editorOptions.value = isSelectAll(dataGrid);
      e.editorOptions.onValueChanged = (e: ValueChangedEvent) => {
        if (!e.event) {
          if (e.previousValue && selectionRef.current.checkBoxUpdating)
            e.component.option("value", e.previousValue);
          return;
        }
        if (isSelectAll(dataGrid) === e.value)
          return;
        e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
        e.event.preventDefault();
      }
    }

  }, [isSelectable, isSelectAll]);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    if (!isSelectable){ // if the callback is not provided, just return
      return;
    }

    const deselectRowKeys: number[] = [];
    e.selectedRowsData.forEach((item) => {
      if (!isSelectable(item))
        deselectRowKeys.push(e.component.keyOf(item));
    });
    if (deselectRowKeys.length) {
      e.component.deselectRows(deselectRowKeys);
    }

    selectionRef.current.checkBoxUpdating = true;
    const selectAllCheckBox = selectionRef.current.selectAllCheckBox;
    selectAllCheckBox?.option("value", isSelectAll(e.component));
    selectionRef.current.checkBoxUpdating = false;
  }, [isSelectable, isSelectAll]);

  return (
    <IlapViewGrid
      {...rest}
      ref={ref}
      onRowPrepared={onRowPrepared}
      onEditorPreparing={onEditorPreparing}
      onSelectionChanged={onSelectionChanged}
    >
      <Selection mode="multiple" showCheckBoxesMode="always" />
      {children}
    </IlapViewGrid>
  )
});

export default IlapSelectableGrid;
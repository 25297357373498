import "./styles/ScheduleTypeCreateModal.css";
import { Popup } from "devextreme-react";
import { useEffect, useMemo, useRef, useState } from "react";
import StepperProgressItem from "components/common/stepper/StepperProgressItem";
import StepperProgressBar from "components/common/stepper/StepperProgressBar";
import { IlapButton, IlapButtonType, IlapPopup } from "ilap.common.webcomponents.test";
import ScheduleTypeGeneralInfoForm from "./forms/ScheduleTypeGeneralInfoForm";
import { useDispatch, useSelector } from "react-redux";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { loadMetadataFieldsAsync } from "store/action/MetadataActions";
import { AppDispatch, RootState } from "store/store";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import { FieldSelectorPanelType } from "features/common/FieldSelectorPanel";
import ReportScheduleTypeRequest from "interfaces/request/ReportScheduleTypeRequest";
import ReportScheduleTypeMetadataFieldRequest from "interfaces/request/ReportScheduleTypeMetadataFieldRequest";
import { createReportScheduleType } from "store/action/ReportScheduleTypeActions";
import { unwrapResult } from "@reduxjs/toolkit";
import ScheduleTypeFieldsEditorForm from "../common/ScheduleTypeFieldsEditorForm";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { useNavigate } from "react-router";
import { SCHEDULE_TYPES } from "shared/constants/RoutePathConstants";
import { ReceivedFrom } from "shared/enums/ReceivedFromEnum";
import { FieldTypeEnum } from "shared/enums/FieldTypeEnum";

interface Props {
  onHideDialog: () => void;
}

const totalSteps = 3; // [1, 2, 3] -> total number of steps in the Schedule Type Creation Process

enum Steps {
  GENERAL_INFO = 1,
  SCHEDULE_FIELDS,
  ACTIVITY_FIELDS
}

export default function ScheduleTypeCreateModal(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(Steps.GENERAL_INFO);

  const [title, setTitle] = useState<string>("");
  const [scheduleLevel, setScheduleLevel] = useState<number>(0);
  const [description, setDescription] = useState<string | null>(null);
  const [infoValidationEnabled, setInfoValidationEnabled] = useState<boolean>(false);

  const [scheduleFields, setScheduleFields] = useState<MetadataFieldInfo[]>([]);
  const [selectedScheduleFields, setSelectedScheduleFields] = useState<MetadataFieldInfo[]>([]);

  const [activityFields, setActivityFields] = useState<MetadataFieldInfo[]>([]);
  const [selectedActivityFields, setSelectedActivityFields] = useState<MetadataFieldInfo[]>([]);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();

  const modalRef = useRef<Popup>(null);

  const scheduleFieldsRef = useRef<FieldSelectorPanelType>(null);
  const activityFieldsRef = useRef<FieldSelectorPanelType>(null);

  // Load all metadata fields
  const fields = useSelector(
    (rootStore: RootState) => rootStore.metadataFieldData.metadataFields
  );

  // If store does not contain metadata fields, call API to get all metadata fields
  useEffect(() => {
    if (fields.length === 0) {
      displayLoadingPanel();
      dispatch(loadMetadataFieldsAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, fields.length]);

  useEffect(() => {
    const scheduleFields: MetadataFieldInfo[] = [];
    const activityFields: MetadataFieldInfo[] = [];

    // Put fields into 2 separate buckets based on type
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      if (field.planningObjectTypes === PlanningObjectTypes.Schedule) {
        scheduleFields.push(FieldUtility.convertToMetadataFieldInfo(field));
      }
      else if (field.planningObjectTypes === PlanningObjectTypes.Activity) {
        activityFields.push(FieldUtility.convertToMetadataFieldInfo(field))
      }
    }

    setScheduleFields(scheduleFields);
    setActivityFields(activityFields);

  }, [fields.length]);

  //#region General Info Operations

  const handleTitleChange = (value: string) => setTitle(value);
  const handleScheduleLevelChange = (value: number) => setScheduleLevel(value);
  const handleDescriptionChange = (value: string | null) => setDescription(value);
  const checkIfAnyGeneralInfoIsProvided = () => (title.trimStart() || scheduleLevel || description);

  //#endregion

  //#region Schedule Fields Operations

  const handleAddScheduleFields = (fields: MetadataFieldInfo[]) => {
    // Remove fields from "Available Fields" list
    // Complexity: O(n^2)
    setScheduleFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));

    // Put the fields into "Selected Fields" list
    setSelectedScheduleFields(prev => [...fields, ...prev]);
  }

  const handleRemoveScheduleFields = (fields: MetadataFieldInfo[]) => {
    // reset field's save and content control status
    // before putting it back to "Available Fields" list
    const resetFields = fields.map(field => FieldUtility.markAsUnsaved(field));

    setScheduleFields(prev => [...resetFields, ...prev]);

    // Complexity: O(n^2)
    setSelectedScheduleFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));
  }

  const handleScheduleFieldContentControlChange = (modifiedField: MetadataFieldInfo, value: number) => {
    setSelectedScheduleFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index].validationRequirement = value;
      return modifiedFields;
    });
  }
  const handleReceivedFromIlapScheduleChange = (modifiedField: MetadataFieldInfo, value: ReceivedFrom) => {
    setSelectedScheduleFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index].receivedFromSchedule = value === ReceivedFrom.IlapSchedule ? true : false;
      return modifiedFields;
    });
  }
  //#endregion

  //#region Activity Fields Operations

  const handleAddActivityFields = (fields: MetadataFieldInfo[]) => {
    // Remove fields from "Available Fields" list
    // Complexity: O(n^2)
    setActivityFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));

    // Set receivedFromSchedule to true    
    fields.forEach(f => { f.receivedFromSchedule = true });

    // Put the fields into "Selected Fields" list
    setSelectedActivityFields(prev => [...fields, ...prev]);
  }

  const handleRemoveActivityFields = (fields: MetadataFieldInfo[]) => {
    // reset field's save and content control status
    // before putting it back to "Available Fields" list
    const resettedFields = fields.map(field => FieldUtility.markAsUnsaved(field));

    setActivityFields(prev => [...resettedFields, ...prev]);

    // Complexity: O(n^2)
    setSelectedActivityFields(prev => prev.filter(field => !fields.some(f => f.id === field.id)));
  }

  const handleActivityFieldContentControlChange = (modifiedField: MetadataFieldInfo, value: number) => {
    setSelectedActivityFields(prev => {
      const index = prev.findIndex(field => field.id == modifiedField.id);
      const modifiedFields = [...prev];
      modifiedFields[index].validationRequirement = value;
      return modifiedFields;
    });
  }

  //#endregion

  const convertFieldsToRequestModels = (fields: MetadataFieldInfo[]): ReportScheduleTypeMetadataFieldRequest[] => {
    return fields.map(field => FieldUtility.convertToScheduleTypeMetadataFieldRequest(field));
  }

  const getScheduleTypeRequestModel = (): ReportScheduleTypeRequest => {
    return {
      id: 0, // id is not set during creation
      title: title,
      description: description,
      planningLevel: scheduleLevel,
      reportScheduleTypeMetadataFields: [...convertFieldsToRequestModels(selectedScheduleFields), ...convertFieldsToRequestModels(selectedActivityFields)],
    };
  };

  const createScheduleType = () => {
    const reportScheduleTypeRequest = getScheduleTypeRequestModel();

    displayLoadingPanel();
    dispatch(createReportScheduleType(reportScheduleTypeRequest))
      .then(unwrapResult)
      .then(newScheduleType => {
        props.onHideDialog();
        navigate(`${SCHEDULE_TYPES}/${newScheduleType.id}`);
      })
      .finally(hideLoadingPanel);
  };

  const previousButtonText = useMemo(() => {
    return currentStep == Steps.GENERAL_INFO ? "Cancel" : "Back";
  }, [currentStep]);

  const nextButtonText = useMemo(() => {
    return currentStep == Steps.ACTIVITY_FIELDS ? "Create" : "Next step";
  }, [currentStep]);

  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const goToNextPage = () => {
    hideConfirmationDialog();

    setCurrentStep(prev => Math.max(prev + 1, totalSteps));
  }

  const goToPreviousPage = () => setCurrentStep(prev => prev - 1);

  const handlePreviousClick = () => {
    if (currentStep == Steps.GENERAL_INFO) {
      if (checkIfAnyGeneralInfoIsProvided()) {
        // show confirmation only when some changes are made
        setConfirmationDialogInfo({
          content: "Cancel new schedule type",
          subContent: "The values you have entered for the new schedule type will not be saved.",
          confirmButtonText: "Confirm",
          cancelButtonText: "Continue editing",
          onConfirm: props.onHideDialog,
          onCancel: hideConfirmationDialog
        });

        setIsConfirmationDialogVisible(true);
      }
      else {
        props.onHideDialog();
      }

    }
    else {
      // No validation takes place when going back
      goToPreviousPage();
    }
  };

  const handleNextClick = () => {
    // step 0: General Information
    if (currentStep == Steps.GENERAL_INFO) {
      // validation check
      if (title.trimStart() && scheduleLevel) {
        setInfoValidationEnabled(false);
        setCurrentStep(Steps.SCHEDULE_FIELDS);
      }
      else {
        setInfoValidationEnabled(true);
      }
    }

    // step 1: Set Schedule Fields
    else if (currentStep == Steps.SCHEDULE_FIELDS) {
      // if schedule fields are selected
      if (selectedScheduleFields.length) {
        // validation check
        const validationStatus = scheduleFieldsRef.current?.validateContentControl();

        if (validationStatus) {
          setCurrentStep(Steps.ACTIVITY_FIELDS);
        }
      }

      // if no schedule fields are selected
      else {
        setConfirmationDialogInfo({
          content: "No schedule fields",
          subContent: "You have not added any schedule fields to the schedule type. If this is intentional, click proceed to next step.",
          confirmButtonText: "Proceed to next step",
          cancelButtonText: "Stay in step 2",
          onConfirm: goToNextPage,
          onCancel: hideConfirmationDialog
        });

        setIsConfirmationDialogVisible(true);
      }
    }

    // step 3: Set Activity Fields
    else {
      // if activity fields are selected
      if (selectedActivityFields.length) {
        // validation check
        const validationStatus = activityFieldsRef.current?.validateContentControl();

        if (validationStatus) {
          // API Call
          createScheduleType();
        }
      }
      else {
        setConfirmationDialogInfo({
          content: "No activity fields",
          subContent: "You have not added any activity fields to the schedule type. If this is intentional, click create schedule type.",
          confirmButtonText: "Create schedule type",
          cancelButtonText: "Continue editing",
          onConfirm: createScheduleType,
          onCancel: hideConfirmationDialog
        });

        setIsConfirmationDialogVisible(true);
      }
    }
  };

  const progressItems: StepperProgressItem[] = [
    {
      text: "General information",
      icon: "1"
    },
    {
      text: "Add schedule fields",
      icon: "2"
    },
    {
      text: "Add activity fields",
      icon: "3"
    },
  ];

  const modalWrapperAttr = useMemo(() => {
    return {
      class: "Ilap-schedule-type-create",
    };
  }, []);

  return <IlapPopup
    ref={modalRef}
    wrapperAttr={modalWrapperAttr}
    height={"92vh"}
    width={"92vw"}
    maxHeight={"1000px"}
    maxWidth={"1760px"}
    minHeight={"720px"}
    minWidth={"1280px"}>
    <div className="flex flex-col justify-between h-full">
      <header>
        <div className="mb-5 flex items-center justify-between">
          <div className="flex items-center">
            <div className="font-poppins text-gray-9 text-[18px] font-semibold leading-normal">New schedule type</div>
            <div className="ml-2 font-inter text-gray-9 text-14px font-normal opacity-50">Step {currentStep} of {totalSteps}</div>
          </div>
          <div className="flex items-center">
            <StepperProgressBar items={progressItems} current={currentStep} />
          </div>
        </div>
        <hr className="mt-4 mb-4 border-light-gray" />
      </header>

      {currentStep == Steps.GENERAL_INFO &&
        <ScheduleTypeGeneralInfoForm
          isValidationEnabled={infoValidationEnabled}

          title={title}
          onTitleChange={handleTitleChange}

          scheduleLevel={scheduleLevel}
          onScheduleLevelChange={handleScheduleLevelChange}

          description={description}
          onDescriptionChange={handleDescriptionChange}
        />}

      {currentStep == Steps.SCHEDULE_FIELDS &&
        <ScheduleTypeFieldsEditorForm
          ref={scheduleFieldsRef}

          fieldsName={FieldTypeEnum.schedule}
          description="Add schedule fields to your schedule type by selecting them in the left table and pressing the Add button. You can remove fields by selecting them in the right table and pressing to the Remove button. For your added field, select the desired content control and define whether values for this field are received from an ILAP schedule or entered manually. Click Next step to move on to the activity fields."

          availableFields={scheduleFields}
          selectedFields={selectedScheduleFields}
          parentRef={modalRef}
          isParentReady={true} // Because this form is accessed after General Info form, the Parent Modal is ready at that point

          onAddFields={handleAddScheduleFields}
          onRemoveFields={handleRemoveScheduleFields}
          onContentControlChange={handleScheduleFieldContentControlChange}
          onReceivedFromIlapScheduleChange={handleReceivedFromIlapScheduleChange} />
      }

      {currentStep == Steps.ACTIVITY_FIELDS &&
        <ScheduleTypeFieldsEditorForm
          ref={activityFieldsRef}

          fieldsName={FieldTypeEnum.activity}
          description="Add activity fields to your schedule type by selecting them in the left table and pressing the Add button. You can remove fields by selecting them in the right table and pressing to the Remove button. Select the desired content control for your added fields and click Create to complete your schedule type. All activity field values are populated from ILAP schedule."

          availableFields={activityFields}
          selectedFields={selectedActivityFields}
          parentRef={modalRef}
          isParentReady={true} // Because this form is accessed after General Info form, the Parent Modal is ready at that point

          onAddFields={handleAddActivityFields}
          onRemoveFields={handleRemoveActivityFields}
          onContentControlChange={handleActivityFieldContentControlChange}
        />
      }

      <footer className="flex flex-col justify-end">
        <div className="flex justify-end gap-4">
          <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={handlePreviousClick}>{previousButtonText}</IlapButton>
          <IlapButton className="px-4 font-medium" onClick={handleNextClick}>{nextButtonText}</IlapButton>
        </div>
      </footer>
      {isConfirmationDialogVisible &&
        <IlapConfirmationDialog
          height="181px"
          width="456px"

          content={confirmationDialogInfo?.content}
          subContent={confirmationDialogInfo?.subContent}
          cancelButtonText={confirmationDialogInfo?.cancelButtonText}
          confirmButtonText={confirmationDialogInfo?.confirmButtonText}

          onConfirm={confirmationDialogInfo?.onConfirm}
          onCancel={confirmationDialogInfo?.onCancel}
        />
      }
    </div>
  </IlapPopup>
}

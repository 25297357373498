import "./index.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { 
  AuthenticationResult, 
  EventMessage, 
  EventType, 
  PublicClientApplication 
} from "@azure/msal-browser";
import { msalConfig } from "authentication/msalConfig";
import { injectMsalInstance } from "authentication/AuthenticationManager";

const msalInstance = new PublicClientApplication(msalConfig);

injectMsalInstance(msalInstance);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload){
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById("root");

if (!container) {
  throw new Error("Root container missing in index.html");
}

const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App msalInstance={msalInstance} />
    </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

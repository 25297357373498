import { AddNewButton, IlapTabPanel } from "ilap.common.webcomponents.test";
import { Item } from "devextreme-react/tab-panel";
import TextWithCount from "components/common/TextWithCount";
import { useCallback } from "react";
import {
  displayGridLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import MetadataField from "interfaces/response/MetadataField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { loadMetadataFieldsAsync } from "store/action/MetadataActions";
import { AppDispatch, RootState } from "store/store";
import "./styles/FieldsGrid.css";
import FieldCreateModal from "./create/FieldCreateModal";
import FieldsGrid from "./FieldsGrid";
import DownloadButton from "components/common/buttons/DownloadButton";
import FieldsImportModal from "./import/FieldsImportModal";

export default function FieldsTabPanel() {

  const dispatch = useDispatch<AppDispatch>();
  const metadataFields = useSelector(
    (rootStore: RootState) => rootStore.metadataFieldData.metadataFields
  );
  useEffect(() => {
    displayGridLoadingPanel();            
    dispatch(loadMetadataFieldsAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  const [scheduleFields, setScheduleFields] = useState<MetadataField[]>([]);
  const [activityFields, setActivityFields] = useState<MetadataField[]>([]);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const [isImportFieldsModalVisible, setIsImportFieldsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const activityFields: MetadataField[] = [];
    const scheduleFields: MetadataField[] = [];

    for (let metadataFieldIndex = 0; metadataFieldIndex < metadataFields?.length; metadataFieldIndex++) {
      let metadataField = metadataFields[metadataFieldIndex];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Activity) {
        activityFields.push(metadataField);
      } else if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
        scheduleFields.push(metadataField);
      }
    }
    setActivityFields(activityFields);
    setScheduleFields(scheduleFields);
  }, [metadataFields])

  const scheduleFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Schedule fields" count={scheduleFields.length} />
  }, [scheduleFields.length]);

  const activityFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Activity fields" count={activityFields.length} />
  }, [activityFields.length]);

  const showFieldCreateModal = () => setIsCreateModalVisible(true);
  const hideFieldCreateModal = () => setIsCreateModalVisible(false);

  const showImportFieldsModal = () => setIsImportFieldsModalVisible(true);
  const hideImportFieldsModal = () => setIsImportFieldsModalVisible(false);

  return (
    <div className="mx-14">
      <div className="mb-[21px] font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        <div className="mr-2">Fields</div>
      </div>
      <div>
        <div className="float-right">
          <DownloadButton className="px-px py-4 mr-2" text="Import fields" onClick={showImportFieldsModal} />
          <AddNewButton className="px-px py-4 border-none" text="New" onClick={showFieldCreateModal} />
        </div>
        <IlapTabPanel className="Ilap-tab-panel FieldsGrid">
          <Item tabRender={scheduleFieldsTitleComponent}>
            <FieldsGrid data={scheduleFields} />
          </Item>
          <Item tabRender={activityFieldsTitleComponent}>
            <FieldsGrid data={activityFields} />
          </Item>
        </IlapTabPanel>
      </div>
      {isCreateModalVisible && <FieldCreateModal onHideDialog={hideFieldCreateModal} />}
      {isImportFieldsModalVisible && <FieldsImportModal onHideDialog={hideImportFieldsModal} /> }
    </div>
  );
}
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import useElementHeight from "../../common/hooks/ElementHeightHook";
import FieldValueEditor from "./FieldValueEditor";
import { IlapToggleButtonState } from "components/common/buttons/IlapToggleButton";
import { forwardRef } from "react";

interface Props {
  description: string,

  parentRef: React.RefObject<any>;
  dataSource: FieldValueInput[];
  toggleState: IlapToggleButtonState
  onToggleChange: (state: IlapToggleButtonState) => void;

  // Table Editor Props
  onAddNewItem: () => void;
  onRemoveItems: (items: FieldValueInput[]) => void;

  // Bulk Editor Props
  bulkInput: string;
  onBulkInputChange: (bulkInput: string) => void;
  onValidateBulkInput: () => boolean;
  highlightBulkInputEditor: boolean;
}

export type FieldValueEditorFormType = {
  saveTableData: () => void;
}

const FieldValueEditorForm = forwardRef<FieldValueEditorFormType, Props>((props, ref) => {
  const parentHeight = useElementHeight({ ref: props.parentRef });
  const occupiedHeight = 277; // 277px will be subtracted from modal height to get Grid height

  const gridHeight = parentHeight - occupiedHeight;
  const gridHeightPx = `${gridHeight}px`;

  return (
    <div className="h-full flex flex-col justify-between overflow-auto">
      <div className="font-inter text-14px text-gray-9">
        <div className="h-20">{props.description}</div>
        <hr className="mt-4 mb-4 border-light-gray" />
      </div>
      <div className="mb-6">
        {gridHeight > 0 &&
          <FieldValueEditor
            ref={ref}

            editorHeight={gridHeightPx}
            dataSource={props.dataSource}
            toggleState={props.toggleState}
            onToggleChange={props.onToggleChange}

            // Table Editor Props
            onAddNewItem={props.onAddNewItem}
            onRemoveItems={props.onRemoveItems}

            // Bulk Editor Props
            bulkInput={props.bulkInput}
            onBulkInputChange={props.onBulkInputChange}
            onValidateBulkInput={props.onValidateBulkInput}
            highlightBulkInputEditor={props.highlightBulkInputEditor}
          />
        }
      </div>
    </div>
  );
});

export default FieldValueEditorForm;